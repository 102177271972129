@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap);
/*font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;*/

* {
	box-sizing: border-box;
}

html {
	font-size: 100.01%;
	scroll-behavior: smooth;
}

body {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 300;
	min-height: 100vh;
	line-height: 1.35;
	font-size: 18px;
	color: #4A55BE;
}

.container {
	max-width: 1199px;
	width: 100%;
}

.container-md {
	max-width: 920px;
}

.container-xxl {
	max-width: 1280px;
}

.container-xxxl {
	max-width: 1640px;
}

a {
	color: #4A55BE;
	text-decoration: none!important;
}

a:hover {
	color: inherit;
}

a.text-primary:active,a.text-primary:focus,a.text-primary:link,a.text-primary:visited {
	color: #4A55BE;
}

h5 a.text-primary {
	color: #4A55BE!important;
}

h5 a.text-primary:hover {
	text-decoration: underline!important;
}

h5 a.text-primary:active,h5 a.text-primary:focus,h5 a.text-primary:hover,h5 a.text-primary:link,h5 a.text-primary:visited {
	color: #4A55BE!important;
}

h4{
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 600;
}

.btn,a,button {
	outline: 0!important;
}

p {
	font-size: 18px;
	margin: 0 0 1.3em;
	font-weight: 300;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	color: #000;
}

p .text-primary, p span{
	color: #000 !important;
	font-weight: 700;
}

li{
	color: #000;
}

p a{
	font-weight: 400;
	text-decoration: underline !important;
}

h1,h2,h3,h4,h5,h6 {
	color: inherit;
}

h1 {
	font-size: 50px;
	line-height: 1.2;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

h2 {
	font-size: 30px;
	line-height: 1.2;
	font-weight: 600;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.h3,h3 {
	font-size: 26px;
	line-height: 1.2;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

h5 {
	font-size: 18px;
	line-height: 1.2;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

strong {
	font-weight: 700;
}

.dropdown-noarrow:after {
	display: none;
}

.overflow-visible {
	overflow: visible!important;
}

.nowrap {
	white-space: nowrap;
}

.text-underline {
	text-decoration: underline;
}

.btn,.btn img,.btn span,a,button,input {
	transition: all .35s linear;
}

button:focus,input:focus {
	box-shadow: none!important;
}

.btn {
	line-height: 1;
	font-size: 18px;
	padding: 10px 15px;
	border-radius: 4px;
	font-weight: 300;
	font-family: "Red Hat Display", sans-serif;
	/* letter-spacing: -0.01em; */
}

.btn:not(.btn-lg){
	letter-spacing: 0.05em;
}

.btn-link {
	color: #4A55BE!important;
	outline: 0!important;
	box-shadow: none!important;
}

.btn-icon-right {
	margin-left: 5px;
}

.btn-icon-left {
	margin-right: 5px;
}

.btn .icon-right {
	margin-left: 5px;
}

.btn-lg {
	height: 52px;
	line-height: 52px;
}

.btn-sm {
	padding-top: 8px;
	padding-bottom: 8px;
}

.btn-filter {
	font-size: 20px;
}

.btn-shadow {
	box-shadow: 0 20px 70px rgba(62,62,62,.2);
}

.dropdown-no-arrow:after {
	display: none;
}

.dropdown-item {
	font-weight: 600;
}

.btn:disabled {
	pointer-events: none;
}

.btn-primary,.btn-primary:focus {
	background-color: #4A55BE;
	border-color: #4A55BE;
	color: #fff;
}

.btn-primary:active,.btn-primary:hover {
	background-color: #4A55BE !important;
	border-color: #4A55BE!important;
	color: #fff !important;
}

.btn-secondary,.btn-secondary:focus {
	background-color: #4A55BE;
	border-color: #4A55BE;
	color: #fff;
}

.btn-secondary:active,.btn-secondary:hover {
	background-color: #fff !important;
	border-color: #4A55BE!important;
	color: #4A55BE !important;
}

.btn-outline-primary,.btn-outline-primary:focus {
	color: #4A55BE!important;
	border-color: #4A55BE!important;
}

.btn-outline-primary:active,.btn-outline-primary:hover {
	background-color: #4A55BE!important;
	color: #fff!important;
}

.btn-gary,.btn-primary:disabled {
	background-color: rgba(143,143,143,.6)!important;
	border-color: rgba(143,143,143,.6)!important;
	color: #fff!important;
}

.dropdown-item.active,.dropdown-item:active {
	background-color: #4A55BE!important;
}

.btn-primary.disabled, .btn-primary:disabled{
	background-color: #4A55BE;
    border-color: #4A55BE;
}

.btn-link {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
}

.text-primary {
	color: #4A55BE!important;
}

.btn-link-orange{
	font-weight: 600;
	color: #4A55BE !important;
}

.btn-white {
	color: #4A55BE;
	box-shadow: 0 4px 20px rgba(64,70,104,.1);
}

.btn-white:hover {
	background-color: #4A55BE;
	color: #fff;
}

.btn strong {
	font-weight: 700;
	color: #4A55BE;
}

.btn:hover strong {
	color: inherit;
}

.btn-text-space {
	letter-spacing: 2px;
}

.btn-lg {
	height: 65px;
	padding: 0 32px;
	font-size: 20px;
	line-height: 65px;
	border-radius: 10px;
}

.btn-md {
	height: 50px;
	padding: 0 20px;
	font-size: 15px;
	line-height: 50px;
	border-radius: 10px;
}

.btn-md-2x {
	font-size: 18px;
}

.rounded-8 {
	border-radius: 8px;
}

.rounded-4 {
	border-radius: 4px;
}

.mb-100 {
	margin-bottom: 100px;
}

.shadow {
	box-shadow: 0 0 4px rgba(0,0,0,.16);
}

.opacity-25 {
	opacity: .25;
}

.text-secondary {
	color: #4A55BE!important;
}

.bg-blue {
	color: #fff;
	background-color: #073b74;
}

.header {
	z-index: 88;
}

.navbar-nav li {
	margin-right: 28px;
}

.navbar-nav a {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.navbar-nav a:hover {
	color: #4A55BE!important;
}

.navbar {
	top: 20px;
}

.home-banner,.inner-banner {
	z-index: 1;
	height: 600px;
	position: relative;
	background-size: cover;
	border-radius: 0 0 150px 0;
	-webkit-background-size: cover;
	background-position: center center;
}

.home-banner:before,.inner-banner::before {
	border-radius: inherit;
	position: absolute;
	background: #000;
	z-index: -1;
	opacity: .4;
	height: 100%;
	content: "";
	width: 100%;
}

.inner-banner h1 {
	line-height: 1;
	margin-bottom: 25px;
}

.home-banner h1 span,.inner-banner h1 span {
	/* color: #4A55BE; */
	font-weight: 700;
}

.inner-banner {
	height: 465px;
}

.filter-inner {
	box-shadow: 0 25px 50px rgba(64,70,104,.1);
	padding: 8px 25px 8px 16px;
	border-radius: 4px;
	background: #fff;
}

.filter-area {
	margin-bottom: 90px;
	position: relative;
	margin-top: -60px;
	z-index: 8;
}

.filter-inner .btn,.filter-inner .form-control {
	height: 48px;
}

.form-control:disabled {
	cursor: not-allowed;
}

.form-control {
	box-shadow: none!important;
	-webkit-box-shadow: none!important;
}

.filter-inner .form-control {
	border: none;
	outline: 0;
	color: #4A55BE;
	font-size: 18px;
	padding-right: 0;
	font-weight: 500;
	padding-left: 5px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: #fff!important;
}

.filter-inner select.form-control {
	-webkit-appearance: none;
	appearance: none;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAf9JREFUOE9j/P///38GMgEjPs0/fv1h4GBjwWk0Vs0gt5TMOsKwZN9NhvX1XgxWWpJYDcCquXzOMYZZ266ANZ25/Yphc6MPg5mGOIYBGJprFpxgmLLxEsOSCjcGDxM5hqTevQy7zz1m2Nrsy2CkKopiAIrmpiWnGHrXnmdYWOrKEGClBFb4++8/hriu3QyHLj9j2Nbiy6CvJAI3AK65fcVZhvYVZxjmFjkzhNqpoNjw689fhuj2XQwnbrxk2NHqy6CtIAyWB2s+fv0Fg1vFRoZZBY4MkY5qWAPn5++/DOGtOxgevfrCcG5aOELzr99/Ge48/8igJSeEN8ZBBtxFUoc3ngmlnaGs+embLwwrD95myA/QZ2BmZsLp1dtPPzDsPPuIIcdPDxHad559ZLApXMPgba7AMKvAiYGZiRHDgLvPPjK4V21kMFAWZVhT64nQDGIdv/aCwb9hC0OQtTLDtDwHBiZGhAEPXnxicKvayKAozgfOKFwcrKiaQTxQEgxq2sYQ4aDKMDnLjoGRkZHh0avPDO5VmxikhLkZNjZ4M/BwQjTCUxiyG/ddeMIQ2rKdId5Fg6E4xAjsVGE+DobNTT4MfFxsuDMGTGbX2UcM4a07GdhZmRiUpfjBOUqAh51wloSp2HHmEcOiPTcYpmTbMQjxchBfGBBKljj9TKxGkDoA2eP1v7fDYJcAAAAASUVORK5CYII=) no-repeat center right 5px/15px 22px;
}

.filter-inner .input-group{
	flex-wrap: nowrap !important;
}

.filter-inner .input-group-text {
	background-color: transparent;
	font-size: 18px;
	color: #4A55BE;
	border: none;
}

.form-control::placeholder {
	color: #4A55BE;
}

.section-title {
	margin-bottom: 40px;
}

.section-title h2 span {
	color: #4A55BE;
}

.section-title-md h2 {
	font-size: 42px;
	font-weight: 700;
}

.section-title p {
	margin-top: 25px;
}

.section-hscroll {
	transition: all .35s linear;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.section-hscroll::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.event-card {
	padding: 30px 30px 20px;
	position: relative;
	min-height: 270px;
	position: relative;
	/* margin-right: 50px; */
	border-radius: 1em;
	margin-right: 25px;
	min-width: 22em;
	max-width: 22em;
}

.row .event-card{
	margin: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
}

.row .event-card .event-tags{
	height: auto;
}

.he_extra {
	/* padding-left: 20px; */
	pointer-events: none;
	margin-bottom: 3em;
	/* min-width: 102em!important; */
}

.event-card+.he_extra h3 {
	display: none;
}

.dashboard-hscroll .event-card {
	margin-top: 20px;
	margin-left: 20px;
	border-radius: 5px;
	margin-right: 30px;
	margin-bottom: 20px;
	box-shadow: 0 0 30px rgba(0,0,0,.16);
}

.event-gray-card {
	background: #fff;
	color: #4A55BE;
}

.event-blue-card {
	background: #4A55BE;
	color: #fff;
}

.event-card h3 {
	max-width: 75%;
	min-height: 56px;
	font-weight: 700;
	text-transform: capitalize;
}

.event-card p {
	color: inherit;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 8px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.event-card .pc-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 15px;
}

.event-card .pc-info i {
	margin-right: 5px;
	line-height: 18px;
	width: 18px;
}

.event-card .pc-info i.fa-dollar-sign,.event-card .pc-info i.fa-map-pin {
	height: 18px;
	font-size: 10px;
	line-height: 18px;
}

.event-tags {
	height: 78px;
	display: block;
	overflow: hidden;
	padding-top: 12px;
}

.ev-tag {
	font-size: 11px;
	font-weight: 600;
	min-width: 85px;
	padding: 5px 10px;
	margin-right: 5px;
	text-align: center;
	margin-bottom: 10px;
	border-radius: 50px;
	display: inline-block;
	border: 1px solid #fff;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-transform: lowercase;
}

.event-gray-card .ev-tag {
	border-color: #4A55BE;
}

.event-dots {
	position: absolute;
}

.event-dots-bottom {
	max-width: 80%;
	bottom: 5px;
	right: 0;
}

.event-dots-top {
	max-height: 80%;
	right: 10px;
	top: 0;
}

.get-started-spacer {
	position: relative;
	z-index: 2;
	margin: 90px 0 150px;
}

.btn-get-started {
	height: 60px;
	font-size: 22px;
	min-width: 300px;
	font-weight: 700;
	padding: 0 30px;
	line-height: 60px;
	border-radius: 50px;
}

.get-started h3 {
	margin-bottom: 0;
	max-width: 190px;
}

.get-started p{
	color: #000;
}

.event-blue-card i.fa-dollar-sign,.event-blue-card i.fa-map-pin {
	top: -1px;
	width: 15px;
	height: 15px;
	color: #4A55BE;
	font-size: 10px;
	background: #fff;
	line-height: 15px;
	text-align: center;
	position: relative;
	border-radius: 20px;
}

.section-tabs {
	margin-bottom: 120px;
}

.count-info {
	background: #fff;
	border-radius: 10px;
	padding: 35px 16px 25px;
}

.count-info strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
	font-size: 64px;
	line-height: 1;
}

.count-info h3 {
	max-width: 90%;
    display: block;
    min-height: 48px;
    margin-left: auto;
    margin-right: auto;
	margin-bottom: 18px;
}

.count-info p {
	color: #000;
	font-size: 18px;
	margin-bottom: 0;
	min-height: 145px;
}

.section-tabs .nav-pills {
	margin-bottom: 25px;
	flex-wrap: nowrap;
}

.section-tabs .nav-pills .nav-link {
	z-index: 5;
	font-size: 20px;
	border-radius: 0;
	position: relative;
	color: #4A55BE!important;
	border-bottom: 3px solid transparent;
	background-color: transparent!important;
}

.section-tabs .nav-pills .nav-link:after {
	background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAARCAYAAAA2cze9AAABDElEQVR4AbWUQU7DMBBF/0yknoPegCNg2AEbJGCfI3CD5gi9QZdILVIkUMWq5QbkBvgIrJHiwRPkCionDk36Vo488xM/TUyX95sPCE4wPhVnNT3gKNCcn59MCZISo0KL9dIsWJeTL85BsBgH6zIUumjCy9J8sqMco0DF66Oxu3DlZWXe4GSOQfzoCE/8e2tS82yAnp2OaPgQPSyUBx3RcOUgPb6+6dt/Yaz2n3psUx+B2jqub7dnjmSLBC6j6b6OALc19dIjUrQFd4Yrelx/tKpl265XF7Ou/s5wnR5BfHq8DoMEnCrwP0Xl754/85vSESD05Opu8y7AKVTH8nzapyf55YE6oxsdzz46At9E8GrS1hPddAAAAABJRU5ErkJggg==) no-repeat top center/contain;
	position: absolute;
	bottom: -20px;
	margin: auto;
	height: 20px;
	z-index: -2;
	content: "";
	width: 20px;
	opacity: 0;
	right: 0;
	left: 0;
}

.section-tabs .nav-pills .nav-link.active {
	border-bottom-color: #4A55BE;
}

.section-tabs .nav-pills .nav-link.active:after {
	opacity: 1;
}

.circle-pink {
	height: 0;
	width: 40%;
	display: none;
	border-radius: 50%;
	padding-bottom: 40%;
	background-color: #fce3e2;
}

.circle-pink-tr {
	z-index: -1;
	right: -25%;
	top: 15%;
}

.circle-pink-ml {
	margin: auto;
	left: -25%;
	bottom: 0;
	top: 0;
}

.footer {
	padding: 52px 0 35px;
}

.footer-logo {
	margin-bottom: 20px;
	max-width: 103px;
	display: block;
}

.footer h5 {
	margin-bottom: 15px;
	line-height: 1.4;
}

.footer h5 small {
	font-weight: inherit;
	font-size: 16px;
}

.footer p {
	margin-bottom: 10px;
}

.footer ul li {
	margin-bottom: 5px;
}

.footer ul a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.footer ul a:hover {
	color: #4A55BE;
}

.section-testimonial {
	margin-bottom: 100px;
}

.section-testimonial h2 {
	font-size: 62px;
	line-height: 1;
	font-weight: 700;
}

.testimonial-review-text {
	max-width: 80%;
	/* word-break: break-all; */
}

.testimonial-review-text h3 {
	font-size: 34px;
	margin-bottom: 12px;
}

.signature {
	font-family: 'Westbury Signature Demo Version';
	transform: rotate(-10deg);
	margin-top: -25px;
	font-size: 44px;
}

.testimonial_outer {
	z-index: 1;
	padding: 50px 0;
	margin-left: 10px;
	position: relative;
}

.testimonial-review {
	padding: 45px 15px;
	min-height: 500px;
}

.testimonial-review-pic {
	height: 320px;
	margin-right: 50px;
	border-radius: 50%;
	width: 320px!important;
	min-width: 320px;
	box-shadow: none;
	transition: all .8s linear;
	object-fit: cover;
	object-position: center;
}

/* .testimonial-review .embed-responsive{
	min-width: 320px;
	margin-right: 50px;
	width: 320px!important;
} */

.testimonial-review .embed-responsive{
	width: 100%;
}


.owl-item.active .testimonial-review-pic {
	box-shadow: 0 20px 40px rgba(61,61,61,.3);
}

.testimonial_outer:before {
	border-radius: 54px 0 0 54px;
	background-color: #f9f9fa;
	position: absolute;
	height: 100%;
	z-index: -1;
	left: 175px;
	content: "";
	top: 0;
	right: 0;
}

.section-testimonial {
	position: relative;
	z-index: 1;
}

.section-testimonial:before {
	background: #f9f9fa url(/static/media/rings.5ddc0be4.png) no-repeat center bottom -120px/220px;
	position: absolute;
	height: 100%;
	content: "";
	z-index: -1;
	width: 50%;
	left: 100%;
	top: 0;
}

.section-testimonial .owl-testimonial [role=button] {
	transition: all .35s linear;
	text-align: right;
	position: absolute;
	width: 100%;
	top: -72px;
	opacity: 1;
	width: 45px;
	height: 45px;
	color: #fff!important;
	border-radius: 5px!important;
	background-color: #4A55BE!important;
}

.section-testimonial .owl-testimonial [role=button].carousel-control-prev {
	right: 55px;
	left: auto;
}

.section-testimonial .owl-testimonial [role=button]:hover {
	background-color: #4A55BE!important;
}

.section-testimonial .owl-nav button i {
	top: 1px;
	font-size: 18px;
	position: relative;
}

.owl-theme .owl-dots .owl-dot span {
	background-color: #c9c9c9;
	width: 12px;
	height: 12px;
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
	background-color: #4A55BE;
}

.modal-header .close {
	top: -10px;
	right: -6px;
	opacity: 1;
	padding: 0;
	color: #fff;
	width: 25px;
	height: 25px;
	font-size: 14px;
	text-shadow: none;
	border-radius: 20px;
	background: #4A55BE;
	position: relative;
}

.modal-content {
	border-radius: 10px;
	border-color: #707070!important;
}

.modal .filter-inner {
	box-shadow: none;
}

.modal .filter-inner .form-group {
	padding: 3px 10px;
	border-radius: 10px;
	border: 1px solid #dcdcdc;
	box-shadow: 0 25px 50px rgba(64,70,104,.1);
}

.modal .filter-inner .form-group * {
	border-radius: inherit;
}

.modal-title {
	font-size: 18px;
}

.page-access {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
}

.page-access .container-xxl {
	max-width: 100%;
}

.page-access h1 {
	font-size: 50px;
	max-width: 320px;
	line-height: 1.05;
	font-weight: 700;
	margin-bottom: 15px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.page-access h1 span {
	color: #4A55BE;
}

.page-content-inner {
	max-width: 600px;
	margin: auto;
	width: 100%;
}

.page-image {
	box-shadow: 0 20px 40px rgba(164,164,164,.4);
	background-position: center center;
	-webkit-background-size: cover;
	background-color: #d8d8d8;
	background-size: cover;
	position: absolute;
	height: 100%;
	width: 50%;
	right: 0;
	top: 0;
}

.page-image:before {
	position: absolute;
	background: #000;
	opacity: .3;
	height: 100%;
	content: "";
	width: 100%;
	z-index: 1;
	left: 0;
	top: 0;
}

.header-access {
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
	background-color: rgba(255,255,255,.8);	
	padding: 10px 25px;
}
.header-access .navbar {
	top: 0;
}
.navbar-brand img{
	max-width: 100px;
	width: 100%;
}
.header-access .navbar-brand img {
	max-width: 52px;
	width: 100%;
}

.header-access .navbar-nav a {
	color: #4A55BE;
}

.page-access {
	padding-bottom: 80px;
	padding-top: 210px;
}

.form-field {
	border: 1px solid #E2E3FB;
	padding: 10px 15px;
	border-radius: 8px;
}

.form-group {
	margin-bottom: 15px;
}

.form-field label {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 1;
}

.form-field .form-control[readonly] {
	background-color: #fff!important;
}

.form-field .form-control {
	border: 0;
	padding: 0;
	height: auto;
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
	border-radius: 0;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: transparent!important;
}

.form-field input.form-control {
	height: 22px;
	line-height: 1;
}

.form-field textarea.form-control {
	height: auto;
	font-size: 14px;
}

.form-field textarea.form-control.form-control-size {
	font-size: 18px;
}

.custom-control label {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 18px;
}

.custom-control label a{
	text-decoration: underline !important; 
}

.form-field select.form-control {
	-webkit-appearance: none;
	appearance: none;
	min-width: 160px;
	padding-right: 25px!important;
	/* border-bottom: 1px solid #4A55BE; */
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAABHNCSVQICAgIfAhkiAAAAQJJREFUGFdjlAqf02ikJp7Gw8H6n4EAuPrg3f3LV164MDI4zOcw1eE6e2pymBY+PR0rzj7vXXch9c3qlK2MIIU8vtOdikIN5zXGmstj03jqxss/qRP2zrk0MzoTJA/WBAIaSUt6Zhc5ZttoS3GgazTKXnHj/LSb2gwMDf9QNIE4OmlLL12YHqnLzAQ3i6Fy/rFHM7Zcjv2wLuMQzDCELAMDA6fPdLNUL+1lEzNtlUEK9p1/8iNv+qGJV2dHVyDbjqIJJKGasLB2YqZ9mYO+NI9V4eoLF6ZHGaI7F0MTSIFe+rJjJmoikov33g78vS37AlGa2HxnaogLsBk8Xpy4AltoAgDy8lfatkmbYAAAAABJRU5ErkJggg==) no-repeat center right 8px/13px;
}

.custom-checkbox .custom-control-label::after,.custom-checkbox .custom-control-label::before {
	top: 1px;
	left: -30px;
	width: 20px;
	height: 20px;
	box-shadow: none!important;
	border-radius: 0!important;
}

.custom-checkbox {
	padding-left: 30px;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #4A55BE;
	background-color: #4A55BE;
}

.page-access p {
	font-size: 18px;
	margin-bottom: 15px;
}

.iti {
	display: block;
}

.iti__selected-flag {
	padding: 0;
}

.iti--allow-dropdown input,.iti--allow-dropdown input[type=tel] {
	padding-left: 42px;
}

.ur_short_name {
	width: 50px;
	height: 50px;
	border: none !important;
	min-width: 50px !important;
	font-size: 20px;
	font-weight: 700;
	line-height: 50px;
	text-align: center;
	border-radius: 35px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: #4A55BE;
	text-transform: uppercase;
}

.sidebar {
	top: 0;
	left: 0;
	z-index: 99;
	height: 100%;
	width: 240px;
	position: fixed;
	background-color: #f2f2f2;
	box-shadow: 5px 0 50px rgba(62,62,62,.2);
}

.sidebar_logo {
	padding: 25px 25px 15px;
	margin-bottom: 20px;
}

.sidebar_logo .btn-close{
	position: relative;
	right: -10px;
	top: -12px;
}

.sidebar_logo .btn-close .fas{
	font-size: 23px;
}

.sidebar_logo img {
	max-width: 275px;
	width: 100%;
}

.sidebar_links {
	overflow: auto;
	padding: 15px 25px 15px;
}

.sidebar_links ul {
	margin-bottom: 35px;
}

.sidebar_links ul li {
	margin-bottom: 5px;
}

.sidebar_links ul a {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 600;
	font-size: 20px;
}

.sidebar_links ul a.active,.sidebar_links ul a:hover {
	font-weight: 700;
}

.sidebar_links h6 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 14px;
}

.sidebar_user {
	padding: 20px 25px;
	border-top: 1px solid #4A55BE;
}

.sidebar_user strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 3px;
	font-weight: 600;
	font-size: 20px;
	display: block;
}

.rating_stars {
	font-size: 14px;
}

.rating_stars_2x {
	font-size: 18px;
}

.header-dashboard {
	display: block;
	margin-left: 240px;
	background-color: #f6f8fa;
	padding: 25px 30px 30px 60px;
}

.input-group-address {
	flex-grow: 1;
	height: 48px;
	display: flex;
	align-items: center;
}

.input-group-address>div {
	flex-grow: 1;
}

.css-2b097c-container .css-1uccc91-singleValue,.input-group-address .css-2b097c-container .css-1wa3eu0-placeholder {
	font-weight: 500;
}

.input-group-search {
	overflow: hidden;
	border-radius: 25px;
	background-color: #f2f2f2;
	box-shadow: 0 20px 40px rgba(0,0,0,.1);
}

.input-group-search .form-control {
	height: 50px;
	font-size: 18px;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.header-controls {
	margin-left: 60px;
}

.header-controls a {
	color: #4A55BE;
	position: relative;
	display: inline-block;
}

.header-controls a.active,.header-controls a:hover {
	color: #000;
}

.header-controls a.active .badge,.header-controls a:hover .badge {
	background-color: #000!important;
}

.header-controls a i {
	font-size: 32px;
}

.header-controls a .badge {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border-radius: 20px;
	background: #4A55BE;
	position: absolute;
	line-height: 16px;
	font-weight: 600;
	min-width: 15px;
	font-size: 10px;
	padding: 0 5px;
	height: 16px;
	color: #fff;
	border: 0;
	right: -8px;
	top: -6px;
}

.page-dashboard {
	display: block;
	margin-left: 240px;
	background-color: #f6f8fa;
	padding: 35px 15px 20px 45px;
	min-height: calc(100vh - 66px);
}

.page-info h1 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
	font-size: 50px;
}

.page-info h1 span {
	color: #4A55BE;
}

.page-info p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 300;
	font-size: 18px;
}

.member-card {
	box-shadow: 5px 10px 40px rgba(99,99,99,.1);
	padding: 40px 20px 25px;
	background-color: #fff;
	border-radius: 25px;
	position: relative;
	max-width: 360px;
	width: 100%;
	/* min-width: 360px; */
	overflow: hidden;
	display: block;
}

.card-dots {
	position: absolute;
	max-width: 100%;
	right: -50px;
	top: 18px;
}

.member-plan {
	height: 29px;
	margin-bottom: 20px;
}

.member-plan img {
	mix-blend-mode: darken;
	max-width: 100%;
}

.member-card h2 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 10px;
	font-weight: 700;
	font-size: 30px;
}

.member-card p {
	font-size: 16px;
	min-height: 150px;
}

.member-price {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin-bottom: 15px;
	font-size: 14px;
}

.member-price strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 3px;
	font-weight: 700;
	font-size: 30px;
	display: block;
	line-height: 1;
}

.btn-close {
	line-height: 1;
	font-size: 30px;
}

.notification .btn-link {
	text-decoration: none!important;
}

.notification {
	padding: 20px;
	background: #fff;
	border-radius: 7px;
	border-left: 7px solid #4A55BE;
	box-shadow: 0 20px 40px rgba(202,202,201,.1);
}

.notification.active {
	border-left-color: #4A55BE;
}

.notification p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 18px;
	margin: 0;
}

.notification p strong {
	font-weight: 600;
}

.notification-date {
	color: #595959;
	font-size: 12px;
	font-weight: 600;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.admin-subheading p {
	font-size: 16px;
}

.admin-subheading p span {
	color: #4A55BE;
}

.scroll-horizontal {
	height: 280px!important;
}

.dashboard-hscroll {
	margin-left: -20px;
	margin-right: -30px;
	width: auto!important;
	height: 330px!important;
}

.dashboard-hscroll .event-card {
	min-height: 290px;
}

.event-card-chat {
	position: absolute;
	font-size: 18px;
	color: #4A55BE;
	font-size: 25px;
	right: 15px;
	top: 15px;
}

.post-card {
	background: #fff url(/static/media/rings.5ddc0be4.png) no-repeat right -50px bottom -50px/180px;
	box-shadow: 0 20px 40px rgba(97,97,97,.2);
	border-radius: 22px;
	padding: 40px 50px;
	position: relative;
}

.pc-status div, .pc-status a{
	padding: 0 15px;
	padding-top: 2px;
	margin-bottom: 20px;
	border-radius: 25px !important;
}
.pc-status {
	right: 15px;
	top: 60px;
	z-index: 5;
	height: 32px;
	font-size: 14px;
	min-width: 150px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
	position: absolute;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

.status-active {
	box-shadow: -5px 5px 20px rgba(10,77,151,.4);
	background: #4A55BE;
	color: #fff;
}

.status-closed {
	box-shadow: -5px 5px 20px rgba(180,180,180,.4);
	background: #b4b4b4;
	color: #fff;
}

.status-select {
	box-shadow: -5px 10px 20px rgba(74, 86, 190, 0.4);
	color: #fff !important;
	background: #4A55BE;
}
.status-select:not(.pc-status) {
	min-width: 100%;
}

.post-card p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin: 0 0 20px;
	font-size: 15px;
	max-width: 75%;
	word-break: break-word;
}

.post-card h2 {
	padding-right: 120px;
	margin: -18px 0 8px;
	font-weight: 700;
}

.pc-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin-bottom: 10px;
	font-size: 18px;
}

.pc-info i {
	width: 20px;
	margin-right: 10px;
	text-align: center;
}

.d-flex>.pc-info i {
	margin-right: 3px;
}

.d-flex>.pc-info {
	margin-right: 15px;
}

.pc-tags {
	margin-top: 15px;
}

.pc-tag {
	text-transform: lowercase;
	border: 2px solid #4A55BE;
	display: inline-block;
	border-radius: 20px;
	vertical-align: top;
	text-align: center;
	margin-right: 20px;
	padding: 7px 10px;
	background: #fff;
	margin-top: 15px;
	min-width: 160px;
	font-size: 16px;
}

.chat-list {
	background-color: #f4f4f4;
}

.input-chat-search {
	width: auto;
	background: #fff;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 30px;
	margin: 30px 15px 15px;
}

.input-chat-search .form-control,.input-chat-search i {
	color: #717171;
}

.chat-type .form-control::placeholder,.input-chat-search .form-control::placeholder {
	color: #717171;
}

.chat-contact {
	width: 100%;
	color: #595959;
	font-size: 10px;
	cursor: pointer;
	border-radius: 8px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	background-color: #fff;
	padding: 8px 25px 8px 10px;
}

.chat-contact.active {
	border-color: #4A55BE;
}

.contact-auth {
	text-transform: uppercase;
	background-color: #e9e9e9;
	border-radius: 30px;
	text-align: center;
	margin-right: 12px;
	line-height: 32px;
	font-weight: 500;
	min-width: 32px;
	font-size: 12px;
	color: #4A55BE;
	height: 32px;
}

.contact-name {
	width: calc(100% - 44px);
}

.contact-name strong {
	font-weight: 500;
	font-size: 15px;
	color: #4A55BE;
	display: block;
	width: 100%;
}

.contact-active {
	top: 0;
	bottom: 0;
	right: 8px;
	width: 12px;
	margin: auto;
	height: 12px;
	position: absolute;
	border-radius: 12px;
	background-color: #4A55BE;
}

.chat-list {
	max-width: 300px;
	margin-right: 50px;
	-moz-flex: 0 0 300px;
	-o-flex: 0 0 300px;
	flex: 0 0 300px;
}

.chat-contact-list {
	max-height: 700px;
	padding: 10px 15px 15px;
}

.btn-hired {
	text-transform: uppercase;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	letter-spacing: 2px;
	white-space: nowrap;
	font-weight: 700;
	line-height: 36px;
	border-radius: 0;
	font-size: 14px;
	height: 36px;
}

.chat-header {
	background-color: #f4f4f4;
	border-radius: 5px;
	padding: 10px 20px;
	margin-bottom: 15px;
}

.chat-header .contact-auth {
	line-height: 60px;
	min-width: 60px;
	font-size: 18px;
	height: 60px;
}

.chat-header .contact-name {
	color: #595959;
}

.chat-header .contact-name strong {
	font-size: 20px;
	font-weight: 700;
}

.chat-messages {
	background-color: #f4f4f4;
	border-radius: 5px;
}

.chat-messages-inner {
	padding: 40px 40px 20px;
	height: 580px;
	overflow: auto;
}

.chat-message {
	margin-bottom: 12px;
}

.chat-text {
	max-width: 80%;
}

.chat-received {
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.chat-sent {
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.chat-sent .chat-text {
	background-color: #4A55BE;
	color: #fff;
}

.chat-text p {
	word-break: break-all;
	font-size: inherit;
	margin-bottom: 0;
	color: inherit;
}

.chat-text {
	border-radius: 15px;
	margin-bottom: 6px;
	background: #fff;
	font-size: 16px;
	color: #595959;
	padding: 20px;
}

.chat-time {
	/* padding: 0 20px; */
	font-weight: 500;
	font-size: 10px;
	line-height: 0.8;
	opacity: 0.8;
	color: #595959;
}

.chat-type {
	width: 100%;
	padding: 10px 40px 40px;
}

.chat-type form {
	overflow: hidden;
	padding: 10px 12px;
	border-radius: 50px;
	background-color: #fff;
	box-shadow: 0 10px 24px rgba(0,0,0,.1);
}

.chat-type .btn,.chat-type .form-control {
	height: 40px;
	padding: 0;
	border: 0;
}

.chat-type .btn {
	min-width: 40px;
	color: #fff;
	font-size: 18px;
	border-radius: 40px;
	background-color: #4A55BE;
}

.chat-type .form-control {
	padding-left: 15px;
	font-weight: 500;
	color: #4A55BE;
}

.contact-date {
	white-space: nowrap;
	font-size: 12px;
	color: #b9b9b9;
	margin-left: auto;
}

.contact-date .badge {
	border: 0;
	padding: 0;
	width: 20px;
	color: #fff;
	height: 20px;
	display: block;
	font-size: 13px;
	font-weight: 600;
	line-height: 21px;
	margin: 5px auto 0;
	border-radius: 20px;
	background-color: #4A55BE;
}

.contact-online .contact-auth {
	position: relative;
}

.contact-online .contact-auth:after {
	background-color: #01e74c;
	position: absolute;
	border-radius: 8px;
	content: "";
	height: 8px;
	width: 8px;
	bottom: 3px;
	right: 3px;
}

.rating-box {
	border-radius: 7;
	padding: 25px 15px 25px 25px;
	border-left: 7px solid #4A55BE;
	box-shadow: 0 20px 40px rgba(202,202,201,.1);
}

.rating-box.chat-contact {
	cursor: auto;
}

.rating-box .contact-auth {
	height: 50px;
	min-width: 50px;
	font-size: 20px;
	font-weight: 600;
	line-height: 50px;
	margin-right: 15px;
	border-radius: 7px;
	background-color: #f4f4f4;
}

.rating-box .contact-name {
	width: 58%;
	font-size: 16px;
	font-weight: 400;
}

.rating-box .contact-name strong {
	text-transform: capitalize;
	font-weight: 600;
	font-size: 25px;
}

.contact-review strong {
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 35px;
	display: block;
	line-height: 1;
	color: #4A55BE;
}

.contact-review p {
	margin-bottom: 0;
	font-size: 14px;
	color: #6d6d6d;
	white-space: nowrap;
	margin-top: 8px;
}

.profile-card {
	color: #fff;
	padding: 25px 15px;
	border-radius: 23px;
	margin-bottom: 25px;
	background-color: #4A55BE;
}

.profile-card .contact-auth {
	height: 72px;
	font-size: 20px;
	min-width: 72px;
	font-weight: 600;
	line-height: 72px;
	border-radius: 50px;
	margin-bottom: 12px;
	background-color: #e9e9e9;
}

.pic_upload {
	top: 5px;
	right: 0;
	width: 30px;
	height: 30px;
	font-size: 12px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	border-radius: 30px;
}

.pic_upload:hover {
	border-color: #000!important;
}

.profile-card strong {
	margin-bottom: 2px;
	font-weight: 700;
	font-size: 22px;
	display: block;
}

.profile-card span {
	font-weight: 500;
	font-size: 14px;
}

.list-group {
	border-radius: 23px;
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
}

.list-group a {
	color: #4A55BE;
	font-size: 16px;
	font-weight: 500;
	padding-top: 15px;
	padding-bottom: 15px;
	border-width: 0 0 1px 0;
}

.list-group a.active {
	border-color: rgba(0,0,0,.125);
	background-color: #4A55BE!important;
	color: #fff!important;
}

.list-group a:hover {
	background-color: #4A55BE;
	color: #fff;
}

.list-group a:last-child {
	border-bottom-width: 0;
}

.list-group a i {
	margin-right: 10px;
	font-size: 16px;
}

.profile-box {
	background-color: #fff;
	border-radius: 23px;
	padding: 30px;
}

.profile-box p {
	font-size: 15px;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.profile-box h3 {
	font-weight: 600;
	margin-bottom: 5px;
}

.profile-box .form-field{
	min-height: 58px;
}

.btn-edit {
	text-decoration: none;
	font-size: 14px;
}

.btn-edit i {
	width: 22px;
	height: 22px;
	color: #fff;
	font-size: 10px;
	line-height: 22px;
	margin-right: 3px;
	border-radius: 22px;
	background-color: #4A55BE;
}

.job-post-box h2 {
	font-weight: 700;
}

.job-post-box {
	padding: 80px 15px 30px;
}

.job-post-box p {
	font-size: 14px;
}

.nav-tab-list {
	width: 100%;
	max-width: 300px;
	min-height: 500px;
	padding: 80px 40px 30px;
	background-color: #f4f4f4;
}

.nav-tab-list a {
	font-size: 18px;
	font-weight: 400;
	position: relative;
	padding: 0 0 0 35px;
	pointer-events: none;
	color: #4A55BE!important;
	background: 0 0!important;
}

.nav-tab-list a.active,.nav-tab-list a.done {
	pointer-events: all;
}

.nav-tab-list a {
	padding-bottom: 50px;
}

.nav-tab-list a:before {
	left: 0;
	top: 3px;
	content: "";
	width: 18px;
	height: 18px;
	position: absolute;
	border-radius: 16px;
	border: 1px solid #4A55BE;
	box-shadow: none;
	background-color: #f4f4f4;
	transition: all .35s linear;
	background-position: center center;
	background-repeat: no-repeat;
}

.nav-tab-list a.active:before {
	background: #4A55BE;
	box-shadow: 2px 6px 10px rgba(62,62,62,.2);
}

.nav-tab-list a.done:before {
	background: #4A55BE url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAABHNCSVQICAgIfAhkiAAAAJVJREFUGFeF0D9KgwEYg/FfVl17Ay8hnXoMx26iWOji6A0EUahLOwluLi4FN69VEVO+oiLinyyB8PCSvPGP2s4xyl/cO3SFi1/Btie4xXmSy7Tdw0uS14/rbWe4wTzJ9ZAP4CM2OEry1vYUC5wlGXynAZxgjQc8Y4XjJMuv/Xcd247xhH1Mk9x9H/k5pu0hDpLc//SJLZKMMgOmTHdtAAAAAElFTkSuQmCC) no-repeat center/10px;
}

.nav-tab-list a:after {
	top: 28px;
	left: 9px;
	width: 1px;
	content: "";
	bottom: 2px;
	margin-top: -2px;
	position: absolute;
	background-color: #4A55BE;
}

.nav-tab-list a:last-child:after {
	display: none;
}

.need-help {
	width: 100%;
	height: 100%;
	font-size: 20px;
	font-weight: 300;
	min-height: 65px;
	color: #4A55BE!important;
	border: 1px solid #4A55BE!important;
}

.input-group-counter {
	width: 80px;
}

.input-group-pay {
	max-width: 280px;
}

.input-group-pay .form-control {
	line-height: 1.3;
	margin-left: 4px;
	display: flex;
	align-items: center;
}

.form-area h4 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 8px;
}

.form-group>label {
	margin-bottom: 6px;
	font-weight: 400;
	font-size: 14px;
}

.form-switch label {
	width: 130px;
	height: 24px;
	font-size: 14px;
	padding: 0 26px;
	font-weight: 300;
	background: #fff;
	line-height: 23px;
	border-radius: 12px;
	border: 1px solid #4A55BE;
	transition: all .35s linear;
	position: relative;
}

.form-switch label:before {
	top: 0;
	left: 6px;
	bottom: 0;
	content: "";
	width: 16px;
	height: 16px;
	margin: auto;
	position: absolute;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4A55BE;
	transition: all .4s ease;
}

.fs-yes {
	color: #fff;
}

.form-switch input:checked+label {
	background-color: #4A55BE;
}

.form-switch input:checked+label:before {
	left: 107px;
}

.custom-control-outer .custom-control label {
	font-size: 16px;
}

.custom-control-outer .custom-checkbox .custom-control-label::after,.custom-control-outer .custom-checkbox .custom-control-label::before {
	top: 0px;
}
.custom-control-outer > div{
	margin-top: -1px;
	padding-bottom: 2px;
}

.position-sticky {
	top: 0;
}

.skill-pill {
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	align-items: center;
}

.skill-pill label {
	color: #4A55BE;
	font-size: 11px;
	margin-top: 5px;
	font-weight: 700;
	margin-right: 5px;
	border-radius: 15px;
	padding: 5px 6px 4px;
	cursor: pointer;
	grid-gap: 3px;
	gap: 3px;
	border: 1px solid #4A55BE;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	align-items: center;
}

.skill-pill input:checked+label,.skill-pill.selected label {
	background-color: #4A55BE;
	color: #fff;
}

/* .skill-pill.selected img {
	filter: grayscale(5) invert(5) brightness(1.5);
} */

.skill-pill button {
	margin-left: auto;
	padding-left: 5px!important;
}

.skill-pill img {
	display: inline-block;
}

.upload_cv {
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
}

.blank-area.text-center {
	padding: 0 0 20px;
	text-align: left !important;
}	

.react-tel-input {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	-moz-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.react-tel-input input[type=tel],.react-tel-input input[type=text] {
	padding: 0 0 0 40px!important;
	font-size: 18px!important;
	height: 24px!important;
	width: 100%!important;
}

.react-tel-input .selected-flag {
	padding: 0!important;
}

.react-tel-input .form-control {
	width: 100%;
	height: 65px;
	font-size: 18px;
	padding: 6px 0;
	letter-spacing: -.6px;
	border-radius: 0!important;
}

.react-tel-input .selected-flag {
	font-size: 18px;
	padding: 6px 0;
	letter-spacing: -.6px;
	border-radius: 16px!important;
}

.react-tel-input .country-list {
	font-size: 16px;
}

.react-tel-input .flag-dropdown {
	border-width: 0;
	margin-right: 12px;
	position: relative;
	border: 0!important;
	border-radius: 0!important;
	background-color: transparent!important;
}

.react-tel-input .selected-flag {
	padding: 0!important;
}

.react-tel-input .selected-flag .arrow {
	left: 25px;
	border-top-width: 5px;
	border-left-width: 5px;
	border-right-width: 5px;
}

.react-tel-input .selected-flag .arrow.up {
	border-top-width: 0;
	border-bottom-width: 8px;
}

.fa-calendar {
	font-size: 19px;
}

.css-2b097c-container {
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
}

.css-2b097c-container .css-1pahdxg-control,.css-2b097c-container .css-yk16xz-control {
	border: none;
	min-height: auto;
	box-shadow: none;
}

.css-2b097c-container .css-g1d714-ValueContainer {
	padding: 0;
	color: inherit!important;
	background-color: transparent;
}

.css-2b097c-container .css-1hb7zxy-IndicatorsContainer {
	display: none;
}

.css-2b097c-container .css-1uccc91-singleValue,.css-2b097c-container .css-1wa3eu0-placeholder {
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
}

.payment-card {
	width: 100%;
	padding: 3em;
	margin: auto;
	margin-top: 3.5em;
	max-width: 1000px;
	border-radius: 15px;
}

.pay-button:disabled {
	opacity: .6;
	box-shadow: none;
	cursor: default;
}

.pay-button:disabled:hover {
	box-shadow: none;
}

.pay-button:hover {
	box-shadow: 2px 5px 15px 2px rgba(0,0,0,.2);
}

.CardField-input-wrapper {
	font-size: 16px;
}

.StripeElement {
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid #4A55BE;
}

.StripeElement .ElementsApp>* {
	font-size: 16px;
	color: #4A55BE;
}

.calendar {
	width: 100%;
	display: block;
	color: #000;
	text-align: center;
}

.month-label {
	line-height: 1;
	font-size: 28px;
	min-width: 240px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.month-display {
	margin-bottom: 40px;
}

.month-display .arrow {
	background-color: rgba(66,88,118,.33);
	border-radius: 1px;
	line-height: 30px;
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	height: 30px;
	width: 30px;
}

.month-display .arrow:hover {
	background-color: rgba(66,88,118,1);
}

.calendar .day {
	width: 65px;
	height: 65px;
	position: relative;
	cursor: pointer;
	font-size: 20px;
	line-height: 65px;
	border-radius: 5px;
}

.active-events {
	position: absolute;
	padding: 0 5px;
	bottom: 8px;
	z-index: 5;
	right: 0;
	left: 0;
}

.active-events .active {
	width: 4px;
	height: 4px;
	margin: 1px;
	opacity: .62;
	border-radius: 5px;
	background-color: #4A55BE;
}

.selected .active-events .active {
	background-color: #fff;
	opacity: 1;
}

.calendar .today {
	background-color: #eaeaea;
}

.calendar .selected {
	color: #000!important;
	background: #425876;
	text-decoration: none!important;
	background: radial-gradient(circle,#E2E3FB 0,#4A55BE 100%);
	background: -webkit-radial-gradient(circle,#E2E3FB 0,#4A55BE 100%);
}

.different-month {
	opacity: 0;
	visibility: hidden;
	-webkit-user-select: none;
	        user-select: none;
}

.day-names .day {
	font-weight: 700;
}

.calendar .event-card {
	width: 100%;
	min-width: auto;
}

.calendar .btn {
	/* height: 32px; */
	font-size: 14px;
	line-height: 32px;
	/* padding: 2px 10px 0; */
	letter-spacing: .15em;
}

.calender-events {
	overflow-y: auto;
	max-height: 500px;
    overflow-x: hidden;
}

.event_blank {
	background-color: #ffff;
	min-height: 200px;
	margin-top: 8em;
}

.event-card+.event_blank {
	display: none!important;
}

.thank-you h1 {
	/* text-transform: uppercase; */
	font-weight: 700;
	font-size: 48px;
}

.thank-you img {
	max-width: 90%;
	margin: 0 auto 25px;
}

.post-card-header-budget {
	position: relative;
	padding-left: 20px;
	margin-left: 20px;
	font-weight: 700;
	line-height: 1.1;
	padding: 8px 0;
	padding-left: 8px;
	font-size: 2em;
}

.post-card-header-budget:before {
	background-color: #f8f8f8;
	position: absolute;
	bottom: 0;
	margin: auto;
	content: "";
	width: 1px;
	top: 0;
	left: 0;
}

.post-card-header-budget span {
	margin-top: 0;
	font-size: .5em;
}

.post-card-header-btn .btn {
	padding-right: 30px;
	padding-left: 30px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: #000;
	height: 48px;
	line-height: 1.6;
}

.btn-gray {
	background-color: #d3d3d3;
}

.btn-gray:hover {
	background-color: #4A55BE;
	color: #fff;
}

.post-card-body {
	margin-top: 20px;
	padding-top: 35px;
	position: relative;
}

.post-card-body+.post-card-body {
	margin-top: 35px;
}

.post-card-body:before {
	background-color: #eee;
	height: 1px;
	position: absolute;
	left: -50px;
	right: -50px;
	top: 0;
	content: "";
}

.info-card-list {
	box-shadow: 0 0 35px rgba(0,0,0,.2);
	background-color: #fff;
	padding-bottom: 30px;
	border-radius: 8px;
	min-height: auto;
	min-width: auto;
	width: 100%;
}

.info-card-list .event-tags {
	height: auto;
}

.info-card-name {
	min-width: 200px;
}

.info-card-name p i {
	vertical-align: -1px;
}

.info-card-list .ev-tag {
	font-size: 13px;
}

.info-card-list .event-tags {
	/* max-width: 50%; */
	flex-grow: 1;
	padding: 0 10px;
}

.info-card-controls .btn-link {
	text-decoration: none!important;
}

.info-card-controls {
	min-width: 220px;
	max-width: 220px;
}

.info-card-list+.info-card-list {
	margin-top: 25px;
}

.dropdown-item.active,.dropdown-item:active {
	color: #fff!important;
}

.post-ajob .tab-content {
	padding: 0 25px;
	border-radius: 15px;
	background-color: #fff;
}

.need-help-modal .modal-title {
	font-size: 22px;
}

.need-help-modal .modal-body p {
	font-size: 15px;
}

.modal{
	z-index: 999;
}
.modal-backdrop{
	z-index: 998;
}

.modal-body .input-group.flex-nowrap [data-standalone-searchbox]{
	width: 100%;
}

.modal-header .close {
	opacity: 1!important;
}

.selected-circle {
	width: 100px;
	height: 100px;
	padding: 10px;
	font-size: 16px;
	color: #fff;
	font-weight: 700;
	margin-right: 20px;
	text-align: center;
	margin-bottom: 15px;
	background-color: #4A55BE;
}

.selected-circle-me {
	background-color: #b4b4b4;
}

li[data-country-code=ar]~li[data-country-code=ar],li[data-country-code=au]~li[data-country-code=au],li[data-country-code=ca]~li[data-country-code=ca],li[data-country-code=do]~li[data-country-code=do],li[data-country-code=fr]~li[data-country-code=fr],li[data-country-code=kz]~li[data-country-code=kz],li[data-country-code=mx]~li[data-country-code=mx],li[data-country-code=pr]~li[data-country-code=pr],li[data-country-code=us]~li[data-country-code=us] {
	display: none!important;
}

.inner-banner h1 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
}

.inner-banner p {
	margin-bottom: 0;
	color: #fff;
}

.content-section h2 {
	font-size: 38px;
	font-weight: 700;
	margin-bottom: .5em;
}

.content-section h2 span {
	color: #4A55BE;
}

.content-section p {
	font-size: 17px;
	line-height: 1.5;
	margin-bottom: .9em;
}

.page-inner-content {
	padding-top: 40px;
	/* padding-top: 80px; */
	padding-bottom: 80px;
}

.contact-info i {
	background-color: #4A55BE;
	width: 65px;
	height: 65px;
	color: #fff;
	font-size: 25px;
	border-radius: 65px;
	text-align: center;
	line-height: 65px;
	margin-bottom: 25px;
}

.contact-form {
	padding: 4%;
	width: 100%;
	margin: auto;
	max-width: 960px;
	border-radius: 25px;
	box-shadow: 5px 10px 40px rgba(99,99,99,.01);
}

.accord-control {
	width: 25px;
	min-width: 25px;
	height: 25px;
	position: relative;
}

.accord-control i {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 15px;
	height: 18px;
	margin: auto;
}

.accordion>.card>.card-header {
	transition: all .35s linear;
	line-height: 1.2;
	padding: 20px 8px;
	font-weight: 700;
	font-size: 18px;
	cursor: pointer;
	border: none;
}

.accordion>.card>.card-header.down {
	color: #4A55BE;
}

.accordion>.card>.card-header .fa-minus,.accordion>.card>.card-header .fa-plus {
	opacity: 0;
}

.accordion>.card>.card-header.down .fa-minus,.accordion>.card>.card-header.up .fa-plus {
	opacity: 1;
}

.accordion>.card {
	border-width: 0 0 1px 0!important;
	border-color: #4A55BE!important;
	border-style: dashed!important;
	border-radius: 0;
}

.accordion>.card>.card-header i.fa-question-circle {
	margin-right: 12px;
	position: relative;
	top: -1px;
}

.accordion .card-body {
	font-size: 18px;
	padding-top: 15px;
	padding-left: 38px;
	font-weight: 400;
	padding-bottom: 30px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.post-box {
	box-shadow: 0 10px 80px rgba(62,62,62,.15);
	background-color: #fff;
	margin-bottom: 20px;
	border-radius: 9px;
}

.post-image {
	background-position: center center;
	-webkit-background-size: cover;
	border-radius: 9px 9px 0 0;
	background-size: cover;
	position: relative;
	height: 250px;
}

.post-meta {
	background-color: #4A55BE;
	border-radius: 0 9px 0 0;
	padding: 10px 15px;
	text-align: center;
	position: absolute;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	color: #fff;
	width: 68px;
	right: 0;
	top: 0;
}

.post-meta strong {
	display: block;
	font-weight: 500;
	text-indent: -5px;
}

.post-meta strong::first-line {
	font-size: 30px;
	font-weight: 700;
}

.post-info {
	padding: 25px 20px 30px;
}

.post-info h3 {
	min-height: 80px;
	margin-bottom: 15px;
}

.post-tag {
	font-size: 14px;
	color: #4A55BE;
	margin-bottom: 10px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.hideChat-icon .event-card-chat {
	display: none;
}

.post-info p {
	min-height: 150px;
	overflow: hidden;
	font-size: 17px;
}

.post-info .btn-link {
	font-size: 18px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	/* letter-spacing: -0.01em; */
}

.post-info .btn-link i {
	position: relative;
	top: 2px;
}

.post-info .btn-link:hover {
	color: #000!important;
}

.single-post img {
	max-width: 100%;
	display: block;
	margin: 40px 0;
}

blockquote {
	padding: 3%;
	margin: 30px 0;
	font-size: 18px;
	border-radius: 5px;
	background-color: #f9f9fa;
	border-left: 5px solid #4A55BE;
}

.search-item {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	padding: 30px;
}

.search-heading .ur_short_name {
	background-color: #4A55BE;
	color: #fff;
}

.search-heading p {
	font-size: 13px!important;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
}

.search-item p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 10px;
}

.search-results {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	color: #707070;
	font-size: 18px;
	font-weight: 700;
}

.header-controls .dropdown-menu {
	border: 0;
	padding: 0;
	min-width: 240px;
	border-radius: 8px;
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
}

.dropdown-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 12px;
	padding: 15px;
}

.dropdown-info .ur_short_name {
	width: 32px;
	height: 32px;
	font-size: 14px;
	color: #fff!important;
	min-width: 32px !important;
	line-height: 34px;
}

.dropdown-info h3 {
	margin-bottom: 1px;
	font-weight: 600;
	font-size: 16px;
}

.header-controls .dropdown-menu .dropdown-item {
	border-top: 1px solid #e2e4e6;
	padding: 10px 15px;
	font-weight: 500;
	font-size: 14px;
}

.post-card-chat {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
}

.post-card-chat a {
	display: block;
}

.post-card-chat strong {
	display: block;
	margin-top: 4px;
	color: #4A55BE;
}

.freelancer-info p {
	font-size: 16px;
	max-width: 100%;
}

.post-card.no-bg {
	background-image: none;
}

.post-card .list-group {
	border: none;
	padding: 5px 0;
	border-radius: inherit;
}

.post-card .list-group li {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border-color: #eee;
	padding: 15px 22px;
	font-weight: 600;
	font-size: 16px;
	border-width: 0;
}

.post-card .list-group li+li {
	border-top-width: 1px;
}

.post-card .list-group li span {
	text-align: right;
}

.react-stars {
	outline: 0!important;
}

.selected-flag:focus {
	outline: 1px solid #000;
}

.confirm-modal h2 {
	font-size: 22px;
}

.confirm-modal p {
	font-size: 14px;
}

.jobs-dropdown .dropdown-menu {
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
	border-radius: 8px;
	border: none;
}

.jobs-dropdown .dropdown-item:hover {
	background-color: #eee;
}

.filter-inner .invalid-feedback {
	top: 7px;
	left: 15px;
	right: auto;
	width: auto;
	font-size: 11px;
	position: absolute;
}

.modal-body .input-group .css-2b097c-container {
	flex-grow: 1;
	padding: 6px 0!important;
}

.modal-body .input-group .css-1wa3eu0-placeholder {
	font-size: 15px;
	font-weight: 500;
}

.social-icons a:hover {
	color: #4A55BE!important;
}

.social-icons {
	font-size: 18px;
}

.social-icons a {
	color: #fff;
}

.social-icons a+a {
	margin-left: 12px;
}

.page_loader {
	/* top: 50%; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	/* transform: translateY(-50%) scale(1); */
	/* -webkit-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease; */
	background: #425876;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	background: radial-gradient(circle,rgba(66,88,118,.3) 0,rgba(31,44,66,.3) 100%);
	box-shadow: 0 0 50px rgba(255,255,255,.8);
}

.page_loader.loader-off {
	height: 0;
	opacity: 0;
	z-index: -55;
	visibility: hidden;
	/* transform: translateY(-50%); */
}

.page_loader img {
	/* max-width: 120px; */
	max-width: 275px;
	width: 75%;
}

.page_loader.loader-off img {
	opacity: 0;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

.hero-mask img {
	object-fit: cover;
	object-position: center;
}

.custom-control-outer {
	column-count: 2;
	-webkit-column-gap: 3em;
	-moz-column-gap: 3em;
	grid-column-gap: 3em;
	column-gap: 3em;
	/* display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between; */
}
/* .custom-control-outer  > div{
	max-width: 48%;
	width: 100%;
} */

.css-1hwfws3 {
	padding: 0!important;
}

.css-1wa3eu0-placeholder {
	margin-left: 0!important;
}

.dropdown-menu-right {
	top: 100%!important;
	right: 0!important;
	left: auto!important;
	transform: none!important;
}

.drp-selected {
	display: none!important;
}

.single-post iframe {
	width: 100%!important;
	height: 550px!important;
	margin: 20px 0;
}

.banner-image {
	object-position: center;
	border-radius: inherit;
	position: absolute;
	object-fit: cover;
	height: 100%;
	z-index: -2;
	width: 100%;
	left: 0;
	top: 0;
}

.sitemap {
	min-height: 100vh;
	padding: 50px 0 45px;
	background-color: #f9f9f9;
}

.sitemap h1 {
	font-weight: 700;
	font-size: 4em;
	margin-bottom: .5em;
}

.sitemap li {
	margin-bottom: 12px;
}

.sitemap li a {
	font-size: 20px;
	font-weight: 600;
}

.sitemap li ul {
	margin: 15px 0px 20px 15px;
}

.member-card-gray{
	background-color: #e6e6e6;
	display: none;
}

.filter-area-supplier{
	margin: 1px 0 50px;
}

.supplier-card img, .gallery-list img{
	border: 1px solid #979797;
	background-color: #fff;
	object-position: center;
	margin-bottom: 18px;
	object-fit: cover;
	border-radius: 8px;
	height: 260px;
}
.supplier-card h4{
	margin-top: 4px;
	font-weight: 700;
	margin-bottom: 12px;
}
.supplier-card p{
	font-size: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}

.supplier-card p span{
	font-weight: 300 !important;
}

.supplier-card div > *{
	display: none;
}
.supplier-card div > p:first-child{
	display: -webkit-box;
}

.share-icons{
	margin-bottom: 1.3em;
	font-size: 18px;
}
.share-icons > span{
	margin-left: 5px !important;
}
.share-icons > *{
	margin-right: 8px;
}
.share-icons a img{
	height: 42px;
}

.share-icons + .btn{
	margin-bottom: 1.3em;
}
.btn-add-fav{
	color: #4A55BE;
}
.btn-add-fav i{
	font-size: 1.4em;
	color: #d8d8d8;
	margin-right: 10px;
}
.btn-add-fav i.active{
	color: #4A55BE;
}
.supplier-content p{
	font-size: 15px;
}
.supplier-content p a{
	color: #4A55BE;
}
.supplier-form{
	padding: 25px;
	border-radius: 5px;
	background-color: #fff;
	border: 1px solid #979797;
}
.supplier-form p{
	font-size: 15px;
	margin-bottom: 10px;
}

.react-share__ShareButton circle{
	fill: #4A55BE !important;
}
.react-share__ShareButton:hover circle{
	fill: #4A55BE !important;
}
.disabled{
	pointer-events: none;
}


/* fav-box */
.fav-box{
	padding: 12px;
}
.fav-box i{
	font-size: 22px;
}
.fav-box .fav-name{
	padding: 0px 10px;
}
.fav-heart i{
	color: #d8d8d8;
}
.fav-heart i.active{
	color: #4A55BE;
}
.fav-heart{
	cursor: pointer;
}
.event-card-heart{
	display: block;
	color: #4A55BE;
	margin-bottom: 12px;
}
.card-btn{
	cursor: pointer !important;
}

.sp-gal{
	width: 100%;
	padding-bottom: 52%;
	position: relative;
}
.sp-gal img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	object-position: center;
}

/* availability-dot */
.availability-dot{
	top: -1px;
	width: 15px;
	height: 15px;
	font-size: 16px;
	cursor: pointer;
	margin-right: 10px;
	position: relative;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border: 1px solid #4A55BE;
}
.availability-dot.active{
	background-color: #4A55BE;
}
.availability-day{
	color: #4A55BE;
	font-weight: 600;
	min-width: 50px;
}
.availability-middle span{
	font-weight: 600;
	color: #848484;
}
.availability-middle{
	min-width: 265px;
}
.availability-btn{
	color: #707070;
}
.availability-middle input{
	width: 75px;
	font-size: 15px;
	color: #4A55BE;
	text-align: center;
	font-weight: normal;
	border: 2px solid #4A55BE;
	background-color: transparent;
}

.gallery-list .owl-dots{
	text-align: center;
}
.gallery-list .owl-dots span{
	width: 12px;
	height: 12px;
	opacity: 0.5;
	margin: 0 5px;
	display: block;
	border-radius: 12px;
	background-color: #4A55BE;
}
.gallery-list .owl-dots .active span{
	opacity: 1;
}

.page-data .page-dashboard{
	margin: 0;
	padding: 15vh 15px 0px;
}
.page-data .header-access .navbar-nav a{
	color: #4A55BE;
}

/* header + div + main, .body-search + div + main, .header-dashboard + div + main{
	display: none !important;
} */

.post-card-header-info .flex-wrap{
	max-width: 580px;
	width: 100%;
}

.post-card-header-info p:last-child{
	margin-bottom: 0;
}

.post-card-header-info .ur_short_name{
	color: #fff;
}

.info-content-text h2, .info-content-text h3, .info-content-text h4, .info-content-text h5, .info-content-text h6{
	padding-top: 0.6526em;
	margin-bottom: 0.5em;
}

.spin {
	height: 0;
	width: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 12px;
	z-index: 1;
	position: absolute;
	padding: 8px;
	border: 3px solid #ccc;
	border-right-color: #4A55BE;
	border-radius: 22px;
	animation: rotate 1s infinite linear;
	position: absolute;
  }
  
@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}


.fa-spinner{
	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.form-control-select-disabled{
	background: none !important;
	cursor: auto !important;
}

.not-found{
	display: none;
	padding: 10% 0;
}

.not-found .blank-area.text-center{
	text-align: center !important;
}

.header + .not-found,
.body-search + .not-found{
	display: block;
}
.body-search + .not-found{
	padding-left: 240px;
}

.view-org .not-found.landing, .view-free .not-found.landing, .view-supplier .not-found.landing, .view-landing .not-found.dashboard{
	display: none !important;
}


.post-box.post-box-webinar{
	border-radius: 80px 16px 16px;
	box-shadow: 0 0 30px rgba(0,0,0,.16);
}
.post-box-webinar .post-image{
	border-radius: 80px 16px 16px;
    padding-bottom: 100.105%;
	min-height: 250px;
    height: auto;
}
.post-box.post-box-webinar{
	padding: 15px 15px 10px;
}
.post-box.post-box-webinar .post-info{
	padding-top: 20px;
}
.webinar-status{
	margin: 20px 0;
	padding: 15px 0;
	font-weight: 500;
	border-top: 1px solid rgba(0,0,0,.1);
	border-bottom: 1px solid rgba(0,0,0,.1);
}
.post-box-webinar .post-info h3 span{
	font-size: 1em;
}
a.post-box-webinar:hover, a.post-box-webinar:hover .btn{
	text-decoration: none;
}
a.post-box-webinar .btn{
	font-weight: 600;
	padding: 11px 16px;
	border-radius: 25px;
}
.play-btn{
	width: 27px;
	height: 27px;
	/* background: #fff; */
	border-radius: 50%;
	position: relative;
	display: block;
	margin-right: 15px;
	transition: all .3s ease 0s;
}
.play-btn:before{
	content: "";
	position: absolute;
	width: calc(100% + 16px);
	height: calc(100% + 16px);
	animation: 1.5s linear 0s normal none infinite focuse;
	opacity: 1;
	border-radius: 50%;
	border: 1px solid #fff;
	top: -8px;
	left: -8px;
}
/* .play-btn:after{
	content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    z-index: 100;
    right: 0;
    bottom: 0;
    text-align: center;
    transition: border .3s ease 0s;
    border-left: 10px solid #4A55BE;
    border-bottom: 6px solid transparent;
    border-width: 10px 8px 7px 8px;
    border-top: 5px solid transparent;
    margin: 8px auto auto 11px;
} */
@keyframes focuse {
    0% {
        transform: scale(.6);
        opacity:1
    }

    75% {
        transform: scale(1.1);
        opacity:0
    }

    100% {
        transform: scale(1.1);
        opacity:0
    }
}

.gallery-list .owl-carousel .owl-stage{
	display: flex;
}

.contact-form .form-field select.form-control{
	border: none !important;
}

.btn-block{
	min-width: auto !important;
}





.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
	background-color: #4A55BE !important;
	border-color: #4A55BE !important;
}

.dashboard-hscroll-single .event-card{
	max-width: 396px;
	width: 100%;
}

.text-success + .invalid-feedback{
	display: none !important;
}

a.text-primary:focus, a.text-primary:hover {
    color: #4A55BE !important;
}
@media screen and (max-width:1680px) {
	.section-testimonial:before {
		left: 86%;
	}
}

@media screen and (max-width:1440px) {
	.mb-100 {
		margin-bottom: 25px;
	}

	.nav-tab-list {
		padding-left: 20px;
		padding-right: 20px;
	}

	.post-card .list-group li {
		padding: 14px 12px;
		font-size: 13px;
	}

	.post-card {
		padding: 25px 30px;
	}

	.post-card-body:before{
		left: -30px;
		right: -30px;
	}

	.h3,h3 {
		font-size: 20px;
	}

	.post-card-chat {
		font-size: 14px;
	}

	.page-access {
		padding-top: 125px;
	}

	.form-field label {
		font-size: 14px;
		margin-bottom: 3px;
	}

	.custom-control label {
		font-size: 16px;
	}

	.form-field {
		padding: 6px 12px;
	}

	.page-access p {
		font-size: 15px;
	}

	.footer ul a,h5 {
		font-size: 16px;
	}

	.footer {
		padding: 40px 0 15px;
	}

	.page-access h1 {
		font-size: 40px;
	}

	.btn-lg.px-4 {
		padding: 0 15px!important;
	}

	.btn-lg {
		height: 56px;
		padding: 0 15px;
		font-size: 18px;
		line-height: 56px;
	}
}

@media screen and (max-width:1199px) {
	body {
		font-size: 14px;
	}

	h1 {
		font-size: 46px;
	}

	h2 {
		font-size: 28px;
	}

	p {
		font-size: 20px;
	}

	.btn {
		font-size: 17px;
	}

	.section-testimonial h2 {
		font-size: 48px;
	}

	.btn-get-started {
		height: 56px;
		font-size: 20px;
		min-width: 260px;
		line-height: 56px;
	}

	.navbar-brand img {
		max-width: 80px;
	}

	.navbar-nav li {
		margin-right: 20px;
	}

	.home-banner {
		height: 460px;
	}

	.event-card {
		margin-right: 30px;
	}

	.section-title-md h2 {
		font-size: 36px;
	}

	.testimonial-review{
		min-height: 360px;
	}

	.testimonial-review-pic {
		width: 240px!important;
		min-width: 240px;
		height: 240px;
	}
	/* .testimonial-review .embed-responsive{
		width: 240px!important;
		min-width: 240px;
	} */

	.testimonial_outer:before {
		left: 125px;
	}

	.section-title {
		margin-bottom: 25px;
	}

	.page-image {
		width: 50%;
	}

	.notification {
		padding: 15px;
	}

	.post-card {
		padding: 24px 30px;
		background-size: 150px;
	}

	.post-card p {
		font-size: 14px;
	}

	.pc-info {
		font-size: 16px;
		margin-bottom: 8px;
	}

	.pc-info i {
		width: 18px;
		margin-right: 6px;
	}

	.pc-info i.fa-dollar-sign,.pc-info i.fa-map-pin {
		line-height: 18px;
		font-size: 10px;
		height: 18px;
	}

	.pc-tags {
		margin-top: 10px;
	}

	.pc-tag {
		margin-right: 12px;
		margin-top: 12px;
		padding: 6px 8px;
		min-width: 140px;
		font-size: 14px;
	}

	.pc-status {
		top: 40px;
		height: 30px;
		font-size: 12px;
		min-width: 110px;
		line-height: 31px;
	}

	.chat-list {
		margin-right: 15px;
		max-width: 230px;
		-moz-flex: 0 0 230px;
		-o-flex: 0 0 230px;
		flex: 0 0 230px;
	}

	.chat-header .contact-auth {
		line-height: 50px;
		min-width: 50px;
		font-size: 16px;
		height: 50px;
	}

	.chat-header .contact-name strong {
		font-size: 18px;
	}

	.btn-hired {
		height: 30px;
		padding: 0 8px;
		font-size: 14px;
		line-height: 30px;
		letter-spacing: 0;
	}

	.chat-header {
		padding: 8px 15px;
	}

	.chat-messages-inner {
		padding: 20px 15px 10px;
	}

	.chat-text {
		padding: 15px;
		font-size: 14px;
	}

	.chat-time {
		font-size: 12px;
		padding: 0 15px;
	}

	.chat-type {
		padding: 10px 15px;
	}

	.chat-contact {
		margin-bottom: 10px;
	}

	.input-chat-search {
		margin: 20px 10px 10px;
	}

	.chat-contact-list {
		max-height: 670px;
	}

	.rating-box {
		padding: 10px;
	}

	.rating-box .contact-auth {
		margin-right: 10px!important;
		line-height: 38px!important;
		font-size: 14px!important;
		min-width: 38px!important;
		height: 38px!important;
	}

	.rating-box .contact-name {
		font-size: 14px;
	}

	.rating-box .contact-name strong {
		font-size: 16px;
		line-height: .9;
	}

	.contact-review strong {
		font-size: 30px;
	}

	.rating_stars {
		font-size: 12px;
	}

	.contact-review p {
		margin-top: 2px;
		font-size: 10px;
		font-weight: 500;
	}

	.nav-tab-list {
		padding: 0;
		max-width: 100%;
		min-height: auto;
		position: relative;
		background-color: transparent;
		-moz-flex-direction: row!important;
		-o-flex-direction: row!important;
		flex-direction: row!important;
		-o-flex-wrap: nowrap;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	.nav-tab-list a:before {
		width: 20px;
		height: 20px;
	}

	.nav-tab-list a.active:before {
		background-size: 10px;
	}

	.nav-tab-list a {
		text-align: center;
		padding-bottom: 0;
		max-width: 120px;
		position: static;
		padding-left: 0;
		font-size: 14px;
	}

	.nav-tab-list a:before {
		margin: 0 auto 15px;
		position: relative;
		z-index: 5;
		display: block;
	}

	.job-post-box {
		padding: 20px 0;
	}

	.nav-tab-list a:after {
		width: calc(50% - 100px);
		height: 1px;
		left: 80px;
		top: 14px;
	}

	.nav-tab-list a:nth-child(2):after {
		left: auto;
		right: 80px;
	}

	.inner-banner {
		height: 355px;
	}

	.page-inner-content {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.content-section h2 {
		font-size: 32px;
		margin-bottom: .4em;
	}

	.content-section p {
		font-size: 17px;
	}

	.post-card .list-group li {
		padding: 14px 18px;
		font-size: 15px;
	}

	.post-card-header-budget {
		width: 100%;
		padding-left: 0;
		margin-left: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.post-card-header-budget div {
		margin-right: 20px;
	}

	.post-card-header-budget:before {
		display: none;
	}

	.testimonial_outer:before {
		background: #f9f9fa url(/static/media/rings.5ddc0be4.png) no-repeat right -50px bottom -100px/180px;
	}
}

@media screen and (max-width:991px) {
	h1 {
		font-size: 38px;
	}

	h2 {
		font-size: 25px;
	}

	.home-banner {
		height: 440px;
	}

	.filter-inner {
		font-size: 10px;
	}

	.filter-inner .form-control {
		font-size: 15px;
	}

	.filter-area {
		margin-bottom: 50px;
	}

	.filter-area + .filter-area{
		margin-bottom: 40px;
	}

	.event-card {
		margin-right: 20px;
		padding: 18px 20px 15px;
	}

	.circle-pink-tr {
		right: -35%;
		top: 25%;
	}

	.circle-pink-ml {
		left: -38%;
	}

	.circle-pink {
		width: 60%;
		padding-bottom: 60%;
	}

	.section-testimonial h2 {
		font-size: 36px;
	}

	.testimonial_outer {
		margin-left: 0;
	}

	.testimonial_outer:before {
		left: -15px;
		right: -15px;
		border-radius: 0;
		background: #f9f9fa url(/static/media/rings.5ddc0be4.png) no-repeat right -50px bottom -100px/180px;
	}

	.testimonial_outer {
		padding: 10px 0;
	}

	.section-testimonial .owl-nav {
		top: -110px;
	}

	.section-testimonial:before {
		display: none;
	}

	.page-image {
		background-position: center center;
		box-shadow: none;
		position: relative;
		margin-bottom: 40px;
		padding-bottom: 75%;
		width: 100%;
	}

	.page-access {
		padding-top: 0;
		min-height: auto;
		padding-bottom: 40px;
	}

	.sidebar {
		transition: all .35s linear;
		left: -300px;
	}

	.sidebar:before {
		transition: all .35s linear;
		background-color: #010101;
		visibility: hidden;
		position: fixed;
		height: 100%;
		opacity: 0;
		width: 100%;
		content: "";
		z-index: 0;
		left: 0;
		top: 0;
	}

	.sidebar.active {
		left: 0;
	}

	.sidebar.active:before {
		visibility: visible;
		opacity: .5;
		left: 220px;
	}

	.header-dashboard,.page-dashboard {
		margin-left: 0;
	}

	.page-dashboard {
		padding-top: 25px;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 30px;
	}

	.header-dashboard {
		background-color: #fff;
		padding: 12px 15px;
		border-bottom: 1px rgba(112,112,112,.15) solid;
	}

	.input-group-search {
		background-color: transparent;
		border: 1px solid #dcdcdc;
		box-shadow: none;
	}

	.header-controls {
		margin-left: 30px;
	}

	.sidebar {
		width: 220px;
	}

	.sidebar_logo {
		padding: 15px;
		margin-bottom: 10px;
		border-bottom: 1px rgba(112,112,112,.2) solid;
	}

	.sidebar_links {
		padding: 15px;
	}

	.sidebar_user {
		padding: 15px;
		border-top-color: rgba(112,112,112,.2);
	}

	.sidebar_user img {
		width: 30px;
		height: 30px;
		min-width: 30px;
		margin-right: 10px;
	}

	.sidebar_user strong {
		font-size: 16px;
		margin-bottom: 2px;
	}

	.rating_stars {
		font-size: 11px;
	}

	.dashboard-hscroll {
		margin-right: -15px;
	}

	.rating_stars_2x {
		font-size: 14px!important;
	}

	.profile-box {
		padding: 10px;
	}

	.form-field .form-control {
		font-size: 20px;
	}

	.info-card-controls {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		align-items: center;
	}
	.info-card-controls .btn {
		margin: 5px !important;
	}

	.section-testimonial .owl-testimonial [role=button] {
		top: -95px;
	}
	.info-card-name h3{
		max-width: 100% !important;
	}
	.info-card-list .event-tags{
		padding: 0;
	}
	.info-card-name, .info-card-list .event-tags, .info-card-controls{
		min-width: 100%;
		width: 100%;
	}
	.body-search + .not-found {
		padding-left: 0px;
	}

	.header-access .navbar-nav li{
		margin-right: 0;
	}
}

@media screen and (min-width:768px) {
	.pc-info i.fa-dollar-sign,.pc-info i.fa-map-pin, .pc-info i {
		line-height: 20px !important;
		background-color: #4A55BE;
		width: 20px !important;
		border-radius: 20px;
		position: relative;
		min-height: 20px;
		min-width: 20px;
		font-size: 11px;
		height: 20px;
		color: #fff;
		top: -2px;
	}

	.chat-header .contact-auth {
		background-color: #fff;
	}

	.contact-online .contact-auth:after {
		display: none;
	}

	.get-started h3 {
		width: 190px;
	}
	.post-card-header-budget{
		margin-left: auto;
	}
}

@media screen and (max-width:767px) {
	.py-80 {
		padding-top: 60px;
		padding-bottom: 40px;
	}

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 20px;
	}

	p {
		font-size: 17px;
	}

	h5 {
		font-size: 16px;
	}

	h3 {
		font-size: 20px;
	}

	.header {
		top: 0;
		padding: 5px 0;
		background-color: #F2F2F2;
		position: fixed !important;
		box-shadow: 0 0 40px rgba(62,62,62,.16);
	}

	.navbar {
		top: 0;
	}

	.navbar-toggler-icon {
		height: auto;
	}

	.home-banner {
		margin-bottom: 25px;
		/* margin-top: 70px; */
		border-radius: 0;
		height: 280px;
	}

	.home-banner h1 {
		margin: 0;
	}

	.event-card {
		box-shadow: 0 0 40px rgba(62,62,62,.16);
		margin-bottom: 15px;
		min-height: 170px;
		margin-top: 10px;
	}

	.event-dots-bottom {
		opacity: .45;
	}

	.get-started-spacer {
		margin: 25px 0 35px;
	}

	.get-started h3 {
		max-width: 100%;
		font-size: 20px;
		margin-bottom: 10px;
	}

	.btn-get-started {
		height: 44px;
		padding: 0 20px;
		font-size: 14px;
		min-width: 160px;
		line-height: 44px;
	}

	.section-title-md h2 {
		font-size: 28px;
	}

	.section-title p {
		margin-top: 10px;
	}

	.circle-pink {
		display: none;
	}

	.count-info {
		box-shadow: 0 0 40px rgba(62,62,62,.16);
		margin: 15px auto 35px;
		max-width: 80%;
		display: block;
	}

	.owl-emwork .owl-dots {
		margin-top: -15px!important;
	}

	.section-tabs .nav-pills li {
		flex-grow: 1;
		text-align: center;
	}

	.section-tabs .nav-pills .nav-link {
		border-bottom-color: rgba(108,117,137,.4);
	}

	.testimonial-review{
		min-height: 140px;
	}

	.testimonial-review-pic {
		width: 140px!important;
		min-width: 140px;
		margin: 0 auto 24px;
		display: block;
		height: 140px;
	}

	/* .testimonial-review .embed-responsive{
		width: 100% !important;
		margin: 0 auto 24px;
		display: block;
		height: auto;
	} */

	.testimonial-review {
		text-align: center;
	}

	.testimonial-review-text h3 {
		font-size: 26px;
	}

	.testimonial-review-text p {
		max-width: 80%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.signature {
		margin-top: 5px;
	}

	.footer {
		padding: 25px 0 10px;
	}

	.footer-about {
		border-bottom: 1px solid #275b94;
	}

	.footer-logo {
		max-width: 70px;
		margin-bottom: 15px;
	}

	.footer ul {
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer ul li {
		margin-bottom: 10px;
		margin-right: 20px;
	}

	.footer ul a {
		font-size: 14px;
	}

	.footer h5 {
		margin-bottom: 8px;
	}

	.copyright {
		padding-top: 15px;
		border-top: 1px solid #275b94;
	}

	.section-tabs {
		margin-bottom: 40px;
	}

	.section-testimonial {
		margin-bottom: 50px;
	}

	.filter-area {
		margin-top: -48px;
		margin-bottom: 80px;
	}

	.filter-area + .filter-area{
		margin-bottom: 40px;
	}

	.navbar-nav a {
		color: #4A55BE;
	}

	.navbar-nav {
		margin-bottom: 15px;
	}

	.navbar-nav li {
		margin-right: 0;
		font-weight: 600;
		text-transform: capitalize;
	}

	.page-access h1 {
		max-width: 260px;
		font-size: 38px;
	}

	.btn-lg {
		height: 56px;
		padding: 0 25px;
		font-size: 18px;
		line-height: 56px;
	}

	.page-access {
		padding-top: 65px;
	}

	.page-image {
		height: auto;
		padding-bottom: 0;
		background-image: none!important;
	}

	.ur_short_name {
		width: 42px;
		height: 42px;
		min-width: 42px;
		font-size: 18px;
		line-height: 42px;
	}

	.page-image {
		margin-bottom: 25px;
	}

	.header-dashboard {
		padding: 5px 15px;
	}

	.header-controls a i {
		font-size: 25px;
	}

	.header-controls a .badge {
		right: -10px;
		top: -9px;
	}

	.sidebar_logo {
		padding: 10px 15px;
	}

	.sidebar_links ul {
		margin-bottom: 30px;
	}

	.sidebar_links ul a {
		font-size: 18px;
	}

	.sidebar {
		width: 200px;
	}

	.sidebar.active:before {
		left: 200px;
	}

	.page-info h1 {
		font-size: 38px;
	}

	.page-info p {
		font-size: 14px;
	}

	.member-card h2 {
		font-size: 24px;
	}

	.member-card p {
		font-size: 15px;
	}

	.member-card {
		padding: 30px 15px 20px;
		border-radius: 15px;
	}

	.member-price strong {
		font-size: 26px;
	}

	.member-card p {
		min-height: 135px;
	}

	.btn-md {
		height: 46px;
		font-size: 15px;
		line-height: 46px;
	}

	.member-plan {
		margin-bottom: 15px;
	}

	.header-controls {
		margin-left: auto;
	}

	.body-search {
		padding: 15px;
	}

	.notification p {
		font-size: 15px;
	}

	.admin-subheading p {
		font-size: 14px;
	}

	.dashboard-hscroll .event-card {
		box-shadow: 0 0 20px rgba(62,62,62,.1);
	}

	.post-card {
		padding: 30px 20px 55px;
		background-size: 110px;
		background-position: right -30px bottom -30px;
	}

	.post-card-body:before{
		left: -20px;
		right: -20px;
	}

	.pc-status {
		top: 20px;
		height: 24px;
		font-size: 11px;
		min-width: 92px;
		line-height: 26px;
	}

	.status-active {
		box-shadow: 0 3px 6px rgba(10,85,165,.2);
	}

	.status-closed {
		box-shadow: 0 3px 6px rgba(180,180,180,.2);
	}

	.post-card p {
		max-width: 100%;
		font-size: 13px;
	}

	.post-card-body p {
		font-size: 15px;
	}

	.post-card h2 {
		font-size: 22px;
	}

	.pc-info {
		font-size: 14px;
	}

	.pc-info i.fa-dollar-sign,.pc-info i.fa-map-pin {
		font-size: 15px;
	}

	.pc-tag {
		margin-right: 10px;
		margin-top: 10px;
		padding: 6px 8px 5px;
		min-width: 122px;
		font-size: 14px;
	}

	.chat-section {
		transition: all .35s linear;
		position: fixed;
		right: -100%;
		z-index: 88;
		top: 0;
	}

	.chat-section.active {
		right: 0;
	}

	.chat-header .contact-name strong {
		font-size: 16px;
		font-weight: 600;
	}

	.chat-header {
		margin-bottom: 0;
		border-radius: 0;
		background-color: #fff;
		border-bottom: 1px solid rgba(64,70,104,.16);
		box-shadow: 0 12px 80px rgba(64,70,104,.16);
	}

	.chat-messages-inner {
		padding-top: 40px;
		height: auto;
		flex-grow: 1;
	}

	.chat-header .contact-auth {
		margin-right: 12px;
		line-height: 35px;
		font-size: 15px;
		min-width: 35px;
		height: 35px;
	}

	.chat-section {
		width: 100%;
		height: 100%;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-moz-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.chat-messages {
		flex-grow: 1;
		height: calc(100% - 52px);
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-moz-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.chat-type {
		padding: 15px;
	}

	.chat-type form {
		padding: 8px;
		box-shadow: 0 -12px 80px rgba(64,70,104,.16);
	}

	.chat-text {
		position: relative;
		margin-left: 45px;
		max-width: 95%;
	}

	.chat-text .contact-auth {
		position: absolute;
		left: -45px;
		top: 2px;
	}

	.chat-list {
		-moz-flex: 0 0 100%;
		-o-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
		background-color: transparent;
	}

	.input-chat-search {
		margin: 0 0 15px;
		border-radius: 20px;
		border: 1px solid #979797;
	}

	.chat-contact .contact-auth {
		margin-right: 15px;
		line-height: 50px;
		min-width: 50px;
		font-size: 16px;
		height: 50px;
	}

	.chat-contact {
		padding: 10px 15px;
		border-radius: 0;
	}

	.chat-contact:not(:last-child) {
		border-bottom: 1px rgba(220,220,220,.4) solid;
	}

	.chat-contact-list {
		padding: 0;
		margin: 0 -15px;
		max-height: none;
		width: auto!important;
	}

	.chat-contact .contact-name strong {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 2px;
	}

	.chat-contact .contact-name {
		color: #4A55BE;
		font-size: 12px;
	}

	.contact-name {
		width: calc(100% - 130px);
	}

	.contact-active {
		display: none;
	}

	.profile-box {
		padding: 20px;
		box-shadow: 0 0 40px rgba(62,62,62,.16);
	}

	.btn-edit {
		font-size: 0;
	}

	.btn-edit i {
		width: 40px;
		height: 40px;
		font-size: 18px;
		line-height: 40px;
		border-radius: 40px;
	}

	.job-post-box {
		padding-top: 0;
	}

	.job-post-box h2 br {
		display: none;
	}

	.custom-control-outer {
		overflow: auto;
		margin: 0 0 25px;
		max-height: 180px;
		padding: 15px 15px 0;
		border: 1px solid #4A55BE;
		column-count: auto;
		-webkit-column-gap: 0;
		-moz-column-gap: 0;
		grid-column-gap: 0;
		column-gap: 0;
	}

	.custom-control-outer>div {
		width: 100%;
	}

	.custom-control-outer.row>div {
		padding-left: 0;
	}

	.post-ajob .tab-content {
		padding: 0 5px;
	}

	.need-help-modal .modal-title {
		font-size: 18px;
	}

	.need-help-modal .modal-body p {
		font-size: 16px;
	}

	.inner-banner {
		height: 255px;
		/* margin-top: 55px; */
	}

	.content-image>img {
		width: 100%;
		margin: auto;
		display: block;
		max-width: 360px;
	}

	.accordion>.card>.card-header {
		font-size: 16px;
		line-height: 1.2;
		padding: 15px 5px;
	}

	.accordion .card-body {
		font-size: 16px;
		padding-left: 15px;
		line-height: 1.4;
		padding-bottom: 20px;
	}

	.post-meta {
		font-size: 16px;
	}

	.post-meta strong {
		font-size: 23px;
	}

	.post-image {
		height: 220px;
	}

	.post-info {
		padding: 20px 15px 25px;
	}

	.post-info h3 {
		margin-bottom: 10px;
	}

	.post-info .btn-link,.post-info p {
		font-size: 16px;
	}

	.home-banner,.inner-banner {
		border-radius: 0 0 60px 0;
	}

	.inner-banner h1 {
		margin-bottom: 15px;
	}

	blockquote {
		font-size: 16px;
	}

	.search-item {
		padding: 15px;
	}

	.search-item p {
		font-size: 14px;
	}

	.chat-contact.active {
		border-color: transparent;
		border-bottom-color: rgba(220,220,220,.4);
	}

	.form-field input.form-control {
		height: 32px;
	}

	.page-image img {
		width: 100%;
		height: 280px;
		object-fit: cover;
		object-position: center;
	}

	.single-post iframe {
		height: 320px!important;
	}
	.testimonial-review-text{
		max-width: 100%;
	}
	.pc-status div, .pc-status a{
		margin-bottom: 12px;
	}
	.filter-area-supplier {
		margin-top: -20px;
		margin-bottom: 25px;
	}
	.supplier-card img, .gallery-list img{
		height: 200px;
	}
	.sp-gal{
		padding-bottom: 82%;
	}
	.info-card-name h3{
		min-height: auto;
	}
	.info-card-controls{
		margin-top: 0;
	}
	.not-found h2{
		font-size: 28px;
	}
	.not-found p{
		font-size: 15px;
	}

	.post-box.post-box-webinar {
		padding: 12px 12px 8px;
	}

	.navbar-brand img {
		max-width: 160px;
	}

	/* .count-info h3 {
		min-height: auto;
	} */

	.event-card{
		box-shadow: none;
	}


	.dashboard-hscroll-single .event-card{
		max-width: 308px;
	}

	.payment-card{
		margin-top: 3em;
		padding: 2em;
	}
}

@media screen and (min-width:576px) {
	.dashboard-hscroll-single{
		display: flex;
		overflow: auto;
	}
}

@media screen and (max-width:575px) {
	.home-banner h1 {
		font-size: 24px;
	}

	.event-card {
		min-width: 280px;
		max-width: 280px;
		min-height: 220px;
	}

	.scroll-horizontal {
		min-height: 280px;
	}

	.section-title-md h2 {
		font-size: 24px;
	}

	.section-tabs .nav-pills .nav-link {
		text-align: center;
		font-size: 18px;
		flex-grow: 1;
	}

	.section-title p {
		margin-bottom: 15px;
	}

	.section-testimonial h2 {
		font-size: 20px;
	}

	.section-testimonial .owl-nav {
		top: -92px;
	}

	.section-testimonial .owl-nav button {
		width: 34px;
		height: 34px;
	}

	.section-testimonial .owl-nav button i {
		font-size: 16px;
	}

	.testimonial-review-text p {
		max-width: 100%;
	}

	.footer ul li {
		margin-bottom: 6px;
		margin-right: 12px;
	}

	.btn-lg {
		height: 50px;
		padding: 0 18px;
		font-size: 16px;
		line-height: 50px;
	}

	.form-field label {
		font-size: 14px;
		margin-bottom: 4px;
	}

	.form-field .form-control {
		font-size: 18px;
	}

	.form-field {
		padding: 7px 11px;
	}

	.custom-control label {
		font-size: 16px;
	}

	.page-access p {
		font-size: 15px;
	}

	.custom-checkbox .custom-control-label::after,.custom-checkbox .custom-control-label::before {
		top: 0;
		left: -25px;
		width: 18px;
		height: 18px;
	}

	.custom-checkbox {
		padding-left: 25px;
	}

	.member-card {
		max-width: 300px;
		min-width: 300px;
		display: block;
		margin: auto;
	}

	.member-card p {
		min-height: 115px !important;
	}

	.scroll-horizontal {
		overflow-x: auto!important;
	}

	.dashboard-hscroll {
		margin: 0;
		height: auto!important;
	}

	.dashboard-hscroll>div {
		position: relative!important;
		transform: none!important;
		display: block!important;
	}

	.dashboard-hscroll .event-card {
		width: 90%;
		display: block;
		padding: 15px 12px;
		padding-bottom: 30px;
		margin: 15px auto 35px;
	}

	.dashboard-hscroll .event-card {
		min-height: auto;
	}

	.event-dots-bottom {
		max-width: 45%;
	}

	.event-card p {
		font-size: 14px;
	}

	.pc-tag {
		min-width: 110px;
	}

	.rating-box {
		border-left-width: 5px;
		padding: 10px 6px;
	}

	.nav-tab-list a {
		max-width: 80px;
		font-size: 12px;
	}

	.nav-tab-list a:after {
		left: 58px;
	}

	.nav-tab-list a:nth-child(2):after {
		right: 58px;
	}

	.nav-tab-list a:after {
		width: calc(50% - 75px);
	}

	.custom-control-outer .custom-control label {
		font-size: 14px;
	}

	.form-group-switch {
		border-bottom: 1px solid rgba(220,220,220,.4);
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		padding: 8px 15px;
		-ms-align-items: center;
		align-items: center;
		justify-content: space-between;
	}

	.form-switch label {
		width: 62px;
		padding-right: 0;
		overflow: hidden;
	}

	.fs-yes {
		display: none;
	}

	.form-switch input:checked+label:before {
		left: 40px;
	}

	.form-switch input:checked+label {
		padding-left: 8px;
		padding-right: 0;
	}

	.form-switch input:checked+label .fs-no {
		display: none;
	}

	.form-switch input:checked+label .fs-yes {
		display: block;
		margin-right: auto;
	}

	.skill-pill {
		padding-left: 10px;
		margin-top: 8px;
		font-size: 12px;
		min-width: 48%;
	}

	.form-field-pills {
		border: none;
		padding: 0 0 10px;
	}

	.react-tel-input .form-control,.react-tel-input .selected-flag {
		font-size: 17px;
		padding: 6px 15px;
	}

	.content-section h2 {
		font-size: 28px;
	}

	.content-section p {
		font-size: 16px;
	}

	.page-inner-content {
		padding-top: 35px;
		padding-bottom: 40px;
	}

	blockquote {
		padding: 15px;
	}

	.post-card-chat {
		margin-top: 10px;
		padding-left: 60px;
		text-align: left;
		font-size: 16px;
	}

	.post-card-chat a {
		display: flex;
		align-items: center;
	}

	.post-card-chat strong {
		margin-left: 10px;
		display: inline-block;
	}

	.post-card-chat i {
		font-size: 25px!important;
	}

	.post-card-header-btn {
		margin-left: auto;
		margin-top: 15px;
		order: 3;
	}

	.post-card-header-btn .btn {
		padding-left: 22px;
		padding-right: 22px;
		height: 40px;
	}

	.section-testimonial .owl-testimonial [role=button] {
		top: -40px;
	}

	.post-info h3,.post-info p {
		min-height: auto;
	}

	.form-group-switch .invalid-feedback {
		position: absolute;
		right: 15px;
		width: auto;
		top: 28px;
	}

	.form-field-pills .skill-pill {
		min-width: auto;
		padding-left: 1px;
		margin-top: 0;
	}

	.form-field-pills label {
		font-size: 13px;
		margin-bottom: 2px;
	}
	.supplier-card h4{
		font-size: 20px;
	}
	.supplier-form{
		padding: 10px;
	}
	.supplier-content p{
		font-size: 14px;
	}
	.supplier-card img, .gallery-list img{
		height: 200px;
	}
	.availability-middle {
		min-width: 210px;
	}
	.btn{
		font-size: 15px;
	}

}

@media screen and (max-width:479px) {
	.info-card-controls {
		flex-wrap: wrap;
	}
	.availability-day{
		min-width: 30px;
	}
	.availability-dot{
		margin-right: 6px;
	}
}

@media screen and (max-width:380px) {
	.section-testimonial .owl-nav {
		top: -50px;
	}
	.supplier-card img, .gallery-list img{
		height: 220px;
	}
}

.pt-100{
    padding-top: 100px;
}

.pb-100{
    padding-bottom: 100px;
}

.mb-100{
    margin-bottom: 100px;
}

.container-xl{
    max-width: 1760px;
}

.section-home{
    color: #fff;
    padding-top: 100px;
    padding-bottom: 60px;
    background: #4A55BE url(/static/media/home-banner-bg.29e7a4c3.webp) no-repeat center center / cover;
}

.section-home .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-home-content{
    max-width: 450px;
    width: 100%;
}

.section-home-content h1{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
}

.section-home-content p {
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 30px;
    font-weight: 300;
    line-height: 1.5;
    font-size: 18px;
    color: #fff;
}

.section-home-content form{
    max-width: 430px;
    width: 100%;
}

.home-banner-form .input-group > div:not(.input-group-append){
    flex-grow: 1;
}

.home-banner-form .input-group{
    background-color: #F2F2F2;
    border-radius: 5px;
}

.home-banner-form .input-group .form-control{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 300;
    height: 50px;
    border: none;
    color: #000;
}
  
  .home-banner-form .input-group .form-control::placeholder {
    color: #777777;
  }

.home-banner-form .input-group .btn{
    border: none;
    background-color: #fff !important;
}

.btn-hm{
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 5px;
    line-height: 50px;
    font-weight: 300;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
}

.btn-hm-white-outline, .btn-hm-white{
    color: #fff;
    line-height: 46px;
    border: 2px solid #FFFFFF;
}

/* .btn-hm-white{
    border: 2px solid #fff;
    background-color: #fff;
    line-height: 46px;
    color: #4A55BE;
} */

.btn-dark{
    color: #4A55BE;
    background-color: #E2E3FB;
    border-color: #E2E3FB;
}

.btn-dark:hover, .btn-dark:active, .btn-dark:focus{
    color: #E2E3FB !important;
    background-color: #4A55BE !important;
    border-color: #4A55BE !important;
}

.btn-hm-white-outline:hover, .btn-hm-white:hover{
    border: 2px solid #fff;
    background-color: #fff;
    color: #4A55BE;
}

.btn-hm-primary-outline, .btn-hm-primary{
    border: 1px solid #4A55BE;
    background-color: #fff;
    min-width: 200px;
    line-height: 48px;
    color: #4A55BE;
}

.btn-hm-primary:hover, .btn-hm-primary-outline:hover{
    background-color: #4A55BE;
    color: #fff;
}

/* .btn-hm-primary:hover, .btn-primary:hover, .btn-primary:focus, .btn-primary:active{
    background-color: #fff !important;
    border: 1px solid #4A55BE;
    color: #4A55BE !important;
} */

.section-home-carousel{
    width: 65%;
    margin-right: -100px;
}

.section-home-carousel .owl-dots{
    display: none;
}

.section-home-carousel .owl-nav{
    text-align: left;
}

.section-home-carousel .owl-nav button{
    width: 50px;
    height: 50px;
    border-radius: 50px !important;
    background-color: rgba(0,0,0,0.5) !important;
}

.section-home-carousel .owl-nav button span{
    font-size: 42px !important;
    position: relative;
    line-height: 1;
    top: -5px;
}

.section-home-carousel .owl-nav button:hover {
    background-color: #fff !important;
    color: #4A55BE !important;
}

.section-home-card{
    padding-left: 0;
    padding-right: 0;
    position: relative;
    border-radius: 22px;
    padding-bottom: 82%;
    overflow: hidden;
    min-height: 100%;
}

.section-home-card-half{
    padding-bottom: 50.5%;
    min-height: auto;
    margin-bottom: 4%;
}

.section-home-card img{
    transition: all 0.3s ease;
}

.section-home-card > img{
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
}

.section-home-card-overlay{
    position: absolute;
    padding: 50px;
    width: 100%;
    z-index: 2;
    bottom: 0;
    left: 0;
}

.section-home-card-half .section-home-card-overlay{
    padding-left: 25px;
    padding-right: 25px;
}

.section-home-card-overlay h3{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 50px;
    color: #fff;
    flex-grow: 1;
    margin: 0;
}

.section-home-card-overlay img{
    max-width: 60px;
}

.section-home-card:hover > img{
    transform: scale(1.1);
}

.section-home-card:hover .section-home-card-overlay img{
    transform: rotate(45deg);
}

.col-12:last-child .section-home-card{
    margin-bottom: 0;
}

.section-home-title{
    margin-bottom: 40px;
}

.section-home-title h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    line-height: 1.1;
    font-weight: 700;
    font-size: 50px;
    color: #4A55BE;
}

.section-upcoming .nav-tabs{
    justify-content: center;
    margin-bottom: 30px;
    border: none;
}

.section-upcoming .nav-tabs a{
    padding: 0 12px;
    font-size: 18px;
    font-weight: 300;
    min-width: 200px;
    color: #4A55BE;
    margin: 10px 20px;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    letter-spacing: -0.01em;
    border: 1px solid #4A55BE;
    font-family: "Red Hat Display", sans-serif;
}

.section-upcoming .nav-tabs a.active{
    background-color: #4A55BE;
    color: #fff;
}

.section-upcoming-card{
    background-color: #E2E3FB;
    border-radius: 20px;
    overflow: hidden;
}

.section-upcoming-card-location{
    position: relative;
}

.section-upcoming-card-location > div, .section-upcoming-card-location iframe{
    width: 100%;
    /* pointer-events: none; */
    height: 265px !important;
    display: block;
}

.section-upcoming-card-info{
    padding: 10px 20px;
    background-color: #4A55BE;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    white-space: nowrap;
    font-weight: 300;
    font-size: 16px;
    overflow: auto;
    color: #fff;
}

.section-upcoming-card-info span{
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
}

.section-upcoming-card-info span + span{
    margin-left: 12px;
}

.section-upcoming-card-content{
    padding: 18px 15px 40px;
    position: relative;
}

.section-upcoming-card-content-title{
    margin-bottom: 20px;
}

.section-upcoming-card-content-title h3{
    color: #000;
    display: none;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 18px;
    font-weight: 300;
    /* display: flex; */
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
    margin-bottom: 14px;
}

.section-upcoming-card-content-title h3 img{
    width: 36px;
    height: 36px;
    border-radius: 5px;
}

.section-upcoming-card-content-title h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 30px;
    font-weight: 600;
    min-height: 85px;
    margin-right: 48px;
    color: #000;
}

.section-upcoming-card-control{
    top: 5px;
    grid-gap: 10px;
    gap: 10px;
    right: 15px;
    position: absolute;
}

.section-upcoming-card-control img{
    width: 50px;
}

.section-upcoming-card-content-pills{
    padding-left: 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 25px;
    padding-bottom: 1px;
    overflow-y: scroll; /* Add the ability to scroll the y axis */

    /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
}

.section-upcoming-card-content-pills::-webkit-scrollbar{
    display: none;
}

.section-upcoming-card-content-pills span{
    border: 1px solid #4A55BE;
    border-radius: 5px;
    margin-right: 12px;
    line-height: 36px;
    color: #4A55BE;
    /* min-width: 145px; */
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 16px;
}

.section-upcoming-card-content-footer{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: rgba(74, 85, 190, 0.8);
    font-family: "Red Hat Display", sans-serif;
}

.section-upcoming-card-content-footer strong{
    font-size: 22px;
    font-weight: 700;
    color: #4A55BE;   
    margin-right: 12px;
}

.section-home-signup{
    z-index: 1;
    position: relative;
    padding-top: 146px;
    padding-bottom: 146px;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
}

.section-home-signup:before{
    background: linear-gradient(85.42deg, rgba(0, 0, 0, 0) 1.69%, rgba(0, 0, 0, 0.5) 58.25%);
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.section-home-signup h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #fff;
}

.section-home-signup p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 30px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: #fff;
}

.btn-hm-work{
    color: #000;
    height: auto;
    line-height: 1;
    font-size: 24px;
    font-weight: 700;
    border-radius: 10px;
    padding: 2.6rem 3rem;
    letter-spacing: -0.01em;
    background-color: #F2F2F2;
    font-family: "Red Hat Display", sans-serif;
}

.btn-hm-work.active{
    box-shadow: 0px 4px 20px 0px rgba(74, 85, 190, 0.7);
    background-color: #4A55BE;
    color: #fff;
}

.embed-responsive-hm{
    border-radius: 22px;
}

.card-hm-counter{
    border: 1px solid #CCCCCC;
    border-radius: 18px;
    padding: 32px;
    height: 100%;
}

.card-hm-counter span{
    width: 96px;
    height: 96px;
    line-height: 96px;
    font-size: 50px;
    text-align: center;
    border-radius: 50%;
    color: #4A55BE;
    font-weight: 900;
    display: inline-block;
    border: 1px solid #CCCCCC;
    margin-bottom: 25px;
    font-family: "Red Hat Display", sans-serif;
}

.card-hm-counter h3{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #000;
}

.card-hm-counter p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
    color: #000;
}

.section-home-testimonials{
    padding-top: 60px;
    padding-bottom: 160px;
    background-color: #F2F2F2;
}

.testimonial-hm-image{
    border-radius: 22px;
    margin: 0 auto 25px;
    max-width: 560px;
    max-height: 420px;
    object-fit: cover;
    object-position: center;
    display: block;
    width: 100%;
}

.testimonial-hm-content{
    grid-gap: 20px;
    gap: 20px;
    min-height: 342px;
}

.testimonial-hm-avatar{
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.testimonial-hm-avatar img{
    width: 80px;
    height: 80px;
    border-radius: 80px;
    object-fit: cover;
    object-position: center;
}

.testimonial-hm-avatar  h6{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}

.testimonial-hm-avatar  h6 span{
    font-weight: 500;
    color: #607A8F;
    display: block;
    margin-top: 3px;
}

.testimonial-hm-content h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.testimonial-hm-content p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 400;
}

.testimonial-hm-content .signature{
    text-transform: capitalize;
    transform: rotate(-25deg);
    margin-top: -35px;
    color: #4A55BE;
}

.section-home-testimonials .carousel-indicators{
    display: none;
}

.section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next{
    left: 0;
    right: 0;
    top: auto;
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: auto;
    bottom: -80px;
    border-radius: 50px;
    transition: all 0.35s linear;
    background-color: rgba(0, 0, 0, 0.4);
}

.section-home-testimonials .carousel-control-prev:hover, .section-home-testimonials .carousel-control-next:hover{
    background-color: #4A55BE;
}

.section-home-testimonials .carousel-control-prev{
    left: -80px;
}

.section-home-testimonials .carousel-control-next{
    right: -80px;
}

.section-webinar-card{
    display: flex;
    overflow: hidden;
    height: 100%;
    border-radius: 20px;
    background-color: #F2F2F2;
    flex-direction: column;
}

.section-webinar-card:hover{
    box-shadow: 0px 4px 20px 0px rgba(74, 85, 190, 0.2);
}

.section-webinar-card-image{
    padding-bottom: 64.8%;
    position: relative;
}

.section-webinar-card-image img{
    object-position: center;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.section-webinar-card-content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 20px;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.section-webinar-card-content h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 600;
    /* min-height: 85px; */
    font-size: 24px;
    color: #000;
    flex-grow: 1;
}

.section-webinar-card-content .d-flex{
    border-top: 1px solid rgba(66, 66, 66, 0.4);
    padding-bottom: 15px;
    padding-top: 15px;
    grid-gap: 15px;
    gap: 15px;
}

.section-webinar-card-content p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.section-webinar-card-content .btn-hm-link{
    padding: 0;
}

.btn-hm-link{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    color: #4A55BE;
    font-size: 18px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.btn-hm-link:after{
    width: 8px;
    height: 8px;
    content: "";
    transform: rotate(45deg);
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
}

.section-instagram{
    padding-bottom: 60px;
}

.section-footer{
    background: radial-gradient(74.77% 91.74% at -0.76% 2.04%, #F2F2F2 0%, #E2E3FB 100%);
}

.section-footer .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-footer-inner{
    padding-top: 64px;
    padding-left: 25px;
    padding-bottom: 50px;
}

.section-footer-copyrights{
    border-top: 1px solid rgba(74, 85, 190, 0.4);
    padding-top: 22px;
    padding-bottom: 22px;
}

.section-footer-copyrights p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.section-footer-copyrights p a{
    color: inherit;
}

.section-footer-copyrights p:last-child{
    text-align: right;
    margin-top: -22px;
    margin-right: 4%;
}

.section-footer-inner .footer-about h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 30px;
    color: #000;
}

.section-footer-inner h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 700;
    color: #4A55BE;
    font-size: 20px;
    margin-top: 20px;
}

.section-footer-inner ul, .section-footer-inner p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
}

.section-footer-inner ul li{
    margin-bottom: 8px;
}

.section-footer-inner a{
    color: #000;
}

.section-header:not(.header-access){
    position: relative !important;
    background-color: #F2F2F2;
    padding: 20px 0;
}

.section-header .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-header .navbar{
    top: 0;
}

.section-header .navbar-brand img{
    max-width: 274px;
    margin-top: -10px;
}

.section-header .navbar-nav .nav-link{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 300;
    font-size: 18px;
    color: #000;
}

.section-header .btn-hm{
    text-align: center;
    line-height: 40px;
    min-width: 140px;
    height: 40px;
}

.section-header .btn-hm-primary-outline{
    margin-left: 30px;
}

.header-top{
    font-family: "Red Hat Display", sans-serif;
    background-color: #4A55BE;
    letter-spacing: -0.01em;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    padding: 5px;
}

.section-home-carousel .owl-item{
    transform: scale(0.9);
    transition: all 0.3s ease;
}

.section-home-carousel .owl-item.cloned:not(.active) + .active, .section-home-carousel .owl-item:not(.cloned, .active) + .active{
    transform: scale(1);
}

.section-home-carousel .owl-item.cloned:not(.active) + .active + div, .section-home-carousel .owl-item:not(.cloned, .active) + .active + div{
    right: -0.2%;
}

/* .section-home-carousel .owl-item.active:first-of-type, .section-home-carousel .owl-item:not(.cloned, .active) + .active{
    transform: scale(1);
}

.section-home-carousel .owl-item.active:first-of-type + div, .section-home-carousel .owl-item:not(.cloned, .active) + .active + div{
    right: -0.4%;
} */

.app-icons img{
    max-height: 50px;
    max-width: 144px !important;
}

.daterangepicker td.in-range{
    background-color: #E2E3FB;
}

.daterangepicker td.active, .daterangepicker td.active:hover{
	background-color: #4A55BE;
}


.btn-hm-auto {
    min-width: auto;
    line-height: normal;
}

.team-member-image{
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 115%;
    border-radius: 12px;
}

.team-member-image img{
    object-position: center;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.team-member p{
    margin-bottom: 15px;
}

.instagram-images{
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    display: grid;
    grid-gap: 12px;
    gap: 12px;
}

.instagram-images img{
    border-radius: 8px;
}
@media screen and (max-width: 1680px) {
    .pt-100 {
        padding-top: 75px;
    }
    .pb-100 {
        padding-bottom: 75px;
    }
    .mb-100 {
        margin-bottom: 75px;
    }
    .navbar-nav li {
        margin-right: 15px;
    }
    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 40px;
        padding-right: 40px;
    }

    .section-home-carousel{
        margin-right: -40px;
        margin-top: 25px;
    }

    .section-home{
        padding-top: 50px;
    }
    .section-home-card-overlay h3{
        font-size: 34px;
    }

    .section-home-card-overlay{
        padding: 22px;
    }

    .section-home-card-half .section-home-card-overlay{
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-home-title h2, .section-home-signup h2{
        font-size: 42px;
    }

    .section-home-title {
        margin-bottom: 30px;
    }

    .section-upcoming .nav-tabs a{
        font-size: 16px;
        min-width: 180px;
        margin: 8px 12px;
        line-height: 46px;
    }

    .section-upcoming-card-info{
        font-size: 14px;
        padding: 10px;
    }

    .section-upcoming-card-info span{
        grid-gap: 5px;
        gap: 5px;
    }

    .section-upcoming-card-content-title h3{
        font-size: 16px;
    }

    .section-upcoming-card-content-title h2{
        font-size: 24px;
    }

    .section-upcoming-card-content-pills span{
        /* min-width: 115px; */
        font-size: 14px;
        margin-right: 8px;
    }

    .section-upcoming-card-content-title {
        margin-bottom: 12px;
    }

    .section-upcoming-card-content-footer{
        font-size: 16px;
    }

    .section-upcoming-card-content-footer strong{
        font-size: 18px;
        margin-right: 8px;
    }

    .section-home-signup h2, .section-home-signup p{
        margin-bottom: 22px;
    }

    .btn-hm-work{
        font-size: 20px;
        padding: 1.6rem 1.5rem;
    }

    .card-hm-counter span{
        font-size: 36px;
        width: 66px;
        height: 66px;
        line-height: 66px;
        margin-bottom: 15px;
    }

    .card-hm-counter{
        padding: 18px;
    }

    .card-hm-counter h3{
        font-size: 20px;
    }

    .card-hm-counter p{
        font-size: 15px;
    }

    .testimonial-hm-content p{
        font-size: 18px;
    }

    .section-footer-inner{
        padding-left: 0;
    }

    .section-footer-inner .footer-about h5{
        font-size: 22px;
    }
}

@media screen and (max-width: 1280px) {
    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }

    .section-header .btn-hm{
        min-width: 120px;
    }

    .section-header .btn-hm-primary-outline {
        margin-left: 15px;
    }

    .section-home-content h1{
        font-size: 62px;
    }

    .section-home-content{
        max-width: 380px;
    }
}

@media screen and (max-width: 1160px) {
    .pt-100 {
        padding-top: 52px;
    }
    .pb-100 {
        padding-bottom: 52px;
    }
    .mb-100 {
        margin-bottom: 52px;
    }

    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-header .navbar-brand img{
        max-width: 190px;
    }

    .section-header .btn-hm {
        min-width: 90px;
    }

    .section-home-carousel{
        margin-right: -20px;
    }

    .section-home-carousel{
        width: 60%;
    }

    .section-home-content {
        max-width: 340px;
    }

    .section-home-card-overlay h3 {
        font-size: 28px;
    }

    .section-upcoming .nav-tabs a{
        margin: 5px 8px;
        min-width: 150px;
    }

    .section-upcoming-card-location > div, .section-upcoming-card-location iframe{
        height: 200px !important;
    }

    .section-upcoming-card-control img {
        width: 32px;
    }

    .section-upcoming-card-control{
        grid-gap: 5px;
        gap: 5px;
        top: 8px;
        right: 8px;
    }

    .section-upcoming-card-content{
        padding: 28px 10px 20px;
    }

    .section-upcoming-card-content-pills span {
        /* min-width: 105px; */
        font-size: 13px;
        margin-right: 5px;
        line-height: 32px;
    }

    .section-upcoming-card-content-footer{
        flex-wrap: wrap;
        grid-gap: 5px;
        gap: 5px;
    }

    .section-home-signup h2, .section-home-signup p {
        margin-bottom: 18px;
    }

    .section-home-signup{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .btn-hm-work{
        padding: 1.6rem 1rem;
    }

    .section-home-testimonials{
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next{
        bottom: -60px;
    }

    .section-webinar-card-content h2{
        font-size: 20px;
    }

    .section-webinar-card-content .d-flex{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .section-webinar-card-content .d-flex img{
        max-height: 32px;
    }

    .section-webinar-card-content{
        font-size: 15px;
    }

    .section-footer-inner{
        padding-top: 40px;
        padding-bottom: 12px;
    }

    .section-footer-inner ul, .section-footer-inner p{
        font-size: 16px;
    }

    .section-footer-copyrights p{
        font-size: 14px;
    }

    .section-footer-copyrights p:last-child{
        margin-top: -18px;
    }
    .navbar-nav li {
        margin-right: 10px;
    }
}

@media screen and (max-width: 991px) {
    .section-header{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .section-header .navbar-brand img{
        margin-top: -7px;
    }

    .section-header .navbar-toggler-icon{
        width: auto;
        height: auto;
    }

    .section-header .navbar-collapse{
        background-color: #F2F2F2;
        position: absolute;
        margin-top: 15px;
        padding: 15px;
        right: -15px;
        left: -15px;
        top: 100%;
    }

    .section-header .navbar-nav{
        margin-bottom: 20px;
    }

    .section-header .btn-hm-primary-outline{
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .section-home-content {
        max-width: 580px;
        text-align: center;
        margin: auto;
    }

    .section-home-content form{
        margin: 0 auto 20px;
        max-width: 480px;
    }

    .section-home-carousel{
        width: 100%;
    }

    .section-home {
        padding-top: 36px;
        padding-bottom: 30px;
    }

    .section-home-card, .section-home-card-half{
        margin-bottom: 25px;
        padding-bottom: 320px;
    }

    .section-home-card-overlay, .section-home-card-half .section-home-card-overlay{
        padding: 15px;
    }

    .testimonial-hm-content{
        max-width: 460px;
        margin: auto;
    }

    .testimonial-hm-avatar{
        margin-bottom: 0;
    }

    .testimonial-hm-content h5{
        font-size: 18px;
    }

    .testimonial-hm-content p {
        font-size: 17px;
    }

    .section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next {
        bottom: -50px;
    }

    .section-footer-inner{
        padding-top: 30px;
        padding-bottom: 0;
    }

    .section-home-card{
        min-height: auto;
    }
}

@media screen and (max-width: 767px) {
    .pt-100 {
        padding-top: 32px;
    }
    .pb-100 {
        padding-bottom: 32px;
    }
    .mb-100 {
        margin-bottom: 32px;
    }
    
    .card-hm-counter {
        padding: 12px;
    }
    
    .card-hm-counter span {
        font-size: 30px;
        width: 52px;
        height: 52px;
        line-height: 50px;
    }
    
    .card-hm-counter p {
        font-size: 14px;
    }
    
    .card-hm-counter h3 {
        font-size: 18px;
    }
    
    .section-home-title h2, .section-home-signup h2 {
        font-size: 32px;
    }
    
    .section-home-title, .section-upcoming .nav-tabs {
        margin-bottom: 20px;
    }
    
    .section-footer-logo{
        max-width: 260px;
    }
    
    .section-footer-inner .footer-about h5{
        margin-top: 0;
    }
    
    .section-footer-inner h5{
        margin-top: 0;
    }
    
    .section-footer-copyrights p:last-child{
        margin-right: 0;
        margin-top: 15px;
        text-align: center;
    }
    
    .section-home-signup{
        background-attachment: scroll;
    }

    .section-instagram{
        padding-top: 50px;
    }

    .section-upcoming-card-content-title h2{
        margin-right: 32px;
        min-height: 65px;
    }
}

@media screen and (max-width: 575px) {
    .instagram-images{
        grid-template-columns: repeat(3, 1fr);
    }
}
@font-face {
    font-family: 'Westbury Signature Demo Version';
    src: url(/static/media/WestburySignatureDemoVersion-.b293f710.eot);
    src: url(/static/media/WestburySignatureDemoVersion-.b293f710.eot?#iefix) format('embedded-opentype'),
        url(/static/media/WestburySignatureDemoVersion-.93559934.woff2) format('woff2'),
        url(/static/media/WestburySignatureDemoVersion-.3dac4614.woff) format('woff'),
        url(/static/media/WestburySignatureDemoVersion-.28b7721f.ttf) format('truetype'),
        url(/static/media/WestburySignatureDemoVersion-.ca9a0879.svg#WestburySignatureDemoVersion-) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
