@font-face {
    font-family: 'Westbury Signature Demo Version';
    src: url('fonts/WestburySignatureDemoVersion-.eot');
    src: url('fonts/WestburySignatureDemoVersion-.eot?#iefix') format('embedded-opentype'),
        url('fonts/WestburySignatureDemoVersion-.woff2') format('woff2'),
        url('fonts/WestburySignatureDemoVersion-.woff') format('woff'),
        url('fonts/WestburySignatureDemoVersion-.ttf') format('truetype'),
        url('fonts/WestburySignatureDemoVersion-.svg#WestburySignatureDemoVersion-') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}