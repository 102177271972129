@media screen and (max-width: 1680px) {
    .pt-100 {
        padding-top: 75px;
    }
    .pb-100 {
        padding-bottom: 75px;
    }
    .mb-100 {
        margin-bottom: 75px;
    }
    .navbar-nav li {
        margin-right: 15px;
    }
    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 40px;
        padding-right: 40px;
    }

    .section-home-carousel{
        margin-right: -40px;
        margin-top: 25px;
    }

    .section-home{
        padding-top: 50px;
    }
    .section-home-card-overlay h3{
        font-size: 34px;
    }

    .section-home-card-overlay{
        padding: 22px;
    }

    .section-home-card-half .section-home-card-overlay{
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-home-title h2, .section-home-signup h2{
        font-size: 42px;
    }

    .section-home-title {
        margin-bottom: 30px;
    }

    .section-upcoming .nav-tabs a{
        font-size: 16px;
        min-width: 180px;
        margin: 8px 12px;
        line-height: 46px;
    }

    .section-upcoming-card-info{
        font-size: 14px;
        padding: 10px;
    }

    .section-upcoming-card-info span{
        gap: 5px;
    }

    .section-upcoming-card-content-title h3{
        font-size: 16px;
    }

    .section-upcoming-card-content-title h2{
        font-size: 24px;
    }

    .section-upcoming-card-content-pills span{
        /* min-width: 115px; */
        font-size: 14px;
        margin-right: 8px;
    }

    .section-upcoming-card-content-title {
        margin-bottom: 12px;
    }

    .section-upcoming-card-content-footer{
        font-size: 16px;
    }

    .section-upcoming-card-content-footer strong{
        font-size: 18px;
        margin-right: 8px;
    }

    .section-home-signup h2, .section-home-signup p{
        margin-bottom: 22px;
    }

    .btn-hm-work{
        font-size: 20px;
        padding: 1.6rem 1.5rem;
    }

    .card-hm-counter span{
        font-size: 36px;
        width: 66px;
        height: 66px;
        line-height: 66px;
        margin-bottom: 15px;
    }

    .card-hm-counter{
        padding: 18px;
    }

    .card-hm-counter h3{
        font-size: 20px;
    }

    .card-hm-counter p{
        font-size: 15px;
    }

    .testimonial-hm-content p{
        font-size: 18px;
    }

    .section-footer-inner{
        padding-left: 0;
    }

    .section-footer-inner .footer-about h5{
        font-size: 22px;
    }
}

@media screen and (max-width: 1280px) {
    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }

    .section-header .btn-hm{
        min-width: 120px;
    }

    .section-header .btn-hm-primary-outline {
        margin-left: 15px;
    }

    .section-home-content h1{
        font-size: 62px;
    }

    .section-home-content{
        max-width: 380px;
    }
}

@media screen and (max-width: 1160px) {
    .pt-100 {
        padding-top: 52px;
    }
    .pb-100 {
        padding-bottom: 52px;
    }
    .mb-100 {
        margin-bottom: 52px;
    }

    .section-header .container-fluid, .section-home .container-fluid, .section-footer .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-header .navbar-brand img{
        max-width: 190px;
    }

    .section-header .btn-hm {
        min-width: 90px;
    }

    .section-home-carousel{
        margin-right: -20px;
    }

    .section-home-carousel{
        width: 60%;
    }

    .section-home-content {
        max-width: 340px;
    }

    .section-home-card-overlay h3 {
        font-size: 28px;
    }

    .section-upcoming .nav-tabs a{
        margin: 5px 8px;
        min-width: 150px;
    }

    .section-upcoming-card-location > div, .section-upcoming-card-location iframe{
        height: 200px !important;
    }

    .section-upcoming-card-control img {
        width: 32px;
    }

    .section-upcoming-card-control{
        gap: 5px;
        top: 8px;
        right: 8px;
    }

    .section-upcoming-card-content{
        padding: 28px 10px 20px;
    }

    .section-upcoming-card-content-pills span {
        /* min-width: 105px; */
        font-size: 13px;
        margin-right: 5px;
        line-height: 32px;
    }

    .section-upcoming-card-content-footer{
        flex-wrap: wrap;
        gap: 5px;
    }

    .section-home-signup h2, .section-home-signup p {
        margin-bottom: 18px;
    }

    .section-home-signup{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .btn-hm-work{
        padding: 1.6rem 1rem;
    }

    .section-home-testimonials{
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next{
        bottom: -60px;
    }

    .section-webinar-card-content h2{
        font-size: 20px;
    }

    .section-webinar-card-content .d-flex{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .section-webinar-card-content .d-flex img{
        max-height: 32px;
    }

    .section-webinar-card-content{
        font-size: 15px;
    }

    .section-footer-inner{
        padding-top: 40px;
        padding-bottom: 12px;
    }

    .section-footer-inner ul, .section-footer-inner p{
        font-size: 16px;
    }

    .section-footer-copyrights p{
        font-size: 14px;
    }

    .section-footer-copyrights p:last-child{
        margin-top: -18px;
    }
    .navbar-nav li {
        margin-right: 10px;
    }
}

@media screen and (max-width: 991px) {
    .section-header{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .section-header .navbar-brand img{
        margin-top: -7px;
    }

    .section-header .navbar-toggler-icon{
        width: auto;
        height: auto;
    }

    .section-header .navbar-collapse{
        background-color: #F2F2F2;
        position: absolute;
        margin-top: 15px;
        padding: 15px;
        right: -15px;
        left: -15px;
        top: 100%;
    }

    .section-header .navbar-nav{
        margin-bottom: 20px;
    }

    .section-header .btn-hm-primary-outline{
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .section-home-content {
        max-width: 580px;
        text-align: center;
        margin: auto;
    }

    .section-home-content form{
        margin: 0 auto 20px;
        max-width: 480px;
    }

    .section-home-carousel{
        width: 100%;
    }

    .section-home {
        padding-top: 36px;
        padding-bottom: 30px;
    }

    .section-home-card, .section-home-card-half{
        margin-bottom: 25px;
        padding-bottom: 320px;
    }

    .section-home-card-overlay, .section-home-card-half .section-home-card-overlay{
        padding: 15px;
    }

    .testimonial-hm-content{
        max-width: 460px;
        margin: auto;
    }

    .testimonial-hm-avatar{
        margin-bottom: 0;
    }

    .testimonial-hm-content h5{
        font-size: 18px;
    }

    .testimonial-hm-content p {
        font-size: 17px;
    }

    .section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next {
        bottom: -50px;
    }

    .section-footer-inner{
        padding-top: 30px;
        padding-bottom: 0;
    }

    .section-home-card{
        min-height: auto;
    }
}

@media screen and (max-width: 767px) {
    .pt-100 {
        padding-top: 32px;
    }
    .pb-100 {
        padding-bottom: 32px;
    }
    .mb-100 {
        margin-bottom: 32px;
    }
    
    .card-hm-counter {
        padding: 12px;
    }
    
    .card-hm-counter span {
        font-size: 30px;
        width: 52px;
        height: 52px;
        line-height: 50px;
    }
    
    .card-hm-counter p {
        font-size: 14px;
    }
    
    .card-hm-counter h3 {
        font-size: 18px;
    }
    
    .section-home-title h2, .section-home-signup h2 {
        font-size: 32px;
    }
    
    .section-home-title, .section-upcoming .nav-tabs {
        margin-bottom: 20px;
    }
    
    .section-footer-logo{
        max-width: 260px;
    }
    
    .section-footer-inner .footer-about h5{
        margin-top: 0;
    }
    
    .section-footer-inner h5{
        margin-top: 0;
    }
    
    .section-footer-copyrights p:last-child{
        margin-right: 0;
        margin-top: 15px;
        text-align: center;
    }
    
    .section-home-signup{
        background-attachment: scroll;
    }

    .section-instagram{
        padding-top: 50px;
    }

    .section-upcoming-card-content-title h2{
        margin-right: 32px;
        min-height: 65px;
    }
}

@media screen and (max-width: 575px) {
    .instagram-images{
        grid-template-columns: repeat(3, 1fr);
    }
}