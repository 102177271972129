
.pt-100{
    padding-top: 100px;
}

.pb-100{
    padding-bottom: 100px;
}

.mb-100{
    margin-bottom: 100px;
}

.container-xl{
    max-width: 1760px;
}

.section-home{
    color: #fff;
    padding-top: 100px;
    padding-bottom: 60px;
    background: #4A55BE url("../images/home-banner-bg.webp") no-repeat center center / cover;
}

.section-home .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-home-content{
    max-width: 450px;
    width: 100%;
}

.section-home-content h1{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
}

.section-home-content p {
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 30px;
    font-weight: 300;
    line-height: 1.5;
    font-size: 18px;
    color: #fff;
}

.section-home-content form{
    max-width: 430px;
    width: 100%;
}

.home-banner-form .input-group > div:not(.input-group-append){
    flex-grow: 1;
}

.home-banner-form .input-group{
    background-color: #F2F2F2;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.home-banner-form .input-group .form-control{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 300;
    height: 50px;
    border: none;
    color: #000;
}

.home-banner-form .input-group .form-control::-ms-input-placeholder { /* Edge 12-18 */
    color: #777777;
  }
  
  .home-banner-form .input-group .form-control::placeholder {
    color: #777777;
  }

.home-banner-form .input-group .btn{
    border: none;
    background-color: #fff !important;
}

.btn-hm{
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    line-height: 50px;
    font-weight: 300;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
}

.btn-hm-white-outline, .btn-hm-white{
    color: #fff;
    line-height: 46px;
    border: 2px solid #FFFFFF;
}

/* .btn-hm-white{
    border: 2px solid #fff;
    background-color: #fff;
    line-height: 46px;
    color: #4A55BE;
} */

.btn-dark{
    color: #4A55BE;
    background-color: #E2E3FB;
    border-color: #E2E3FB;
}

.btn-dark:hover, .btn-dark:active, .btn-dark:focus{
    color: #E2E3FB !important;
    background-color: #4A55BE !important;
    border-color: #4A55BE !important;
}

.btn-hm-white-outline:hover, .btn-hm-white:hover{
    border: 2px solid #fff;
    background-color: #fff;
    color: #4A55BE;
}

.btn-hm-primary-outline, .btn-hm-primary{
    border: 1px solid #4A55BE;
    background-color: #fff;
    min-width: 200px;
    line-height: 48px;
    color: #4A55BE;
}

.btn-hm-primary:hover, .btn-hm-primary-outline:hover{
    background-color: #4A55BE;
    color: #fff;
}

/* .btn-hm-primary:hover, .btn-primary:hover, .btn-primary:focus, .btn-primary:active{
    background-color: #fff !important;
    border: 1px solid #4A55BE;
    color: #4A55BE !important;
} */

.section-home-carousel{
    width: 65%;
    margin-right: -100px;
}

.section-home-carousel .owl-dots{
    display: none;
}

.section-home-carousel .owl-nav{
    text-align: left;
}

.section-home-carousel .owl-nav button{
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    border-radius: 50px !important;
    background-color: rgba(0,0,0,0.5) !important;
}

.section-home-carousel .owl-nav button span{
    font-size: 42px !important;
    position: relative;
    line-height: 1;
    top: -5px;
}

.section-home-carousel .owl-nav button:hover {
    background-color: #fff !important;
    color: #4A55BE !important;
}

.section-home-card{
    padding-left: 0;
    padding-right: 0;
    position: relative;
    border-radius: 22px;
    padding-bottom: 82%;
    overflow: hidden;
    min-height: 100%;
}

.section-home-card-half{
    padding-bottom: 50.5%;
    min-height: auto;
    margin-bottom: 4%;
}

.section-home-card img{
    transition: all 0.3s ease;
}

.section-home-card > img{
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
}

.section-home-card-overlay{
    position: absolute;
    padding: 50px;
    width: 100%;
    z-index: 2;
    bottom: 0;
    left: 0;
}

.section-home-card-half .section-home-card-overlay{
    padding-left: 25px;
    padding-right: 25px;
}

.section-home-card-overlay h3{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 50px;
    color: #fff;
    flex-grow: 1;
    margin: 0;
}

.section-home-card-overlay img{
    max-width: 60px;
}

.section-home-card:hover > img{
    transform: scale(1.1);
}

.section-home-card:hover .section-home-card-overlay img{
    transform: rotate(45deg);
}

.col-12:last-child .section-home-card{
    margin-bottom: 0;
}

.section-home-title{
    margin-bottom: 40px;
}

.section-home-title h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    line-height: 1.1;
    font-weight: 700;
    font-size: 50px;
    color: #4A55BE;
}

.section-upcoming .nav-tabs{
    justify-content: center;
    margin-bottom: 30px;
    border: none;
}

.section-upcoming .nav-tabs a{
    padding: 0 12px;
    font-size: 18px;
    font-weight: 300;
    min-width: 200px;
    color: #4A55BE;
    margin: 10px 20px;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    letter-spacing: -0.01em;
    border: 1px solid #4A55BE;
    font-family: "Red Hat Display", sans-serif;
}

.section-upcoming .nav-tabs a.active{
    background-color: #4A55BE;
    color: #fff;
}

.section-upcoming-card{
    background-color: #E2E3FB;
    border-radius: 20px;
    overflow: hidden;
}

.section-upcoming-card-location{
    position: relative;
}

.section-upcoming-card-location > div, .section-upcoming-card-location iframe{
    width: 100%;
    /* pointer-events: none; */
    height: 265px !important;
    display: block;
}

.section-upcoming-card-info{
    padding: 10px 20px;
    background-color: #4A55BE;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    white-space: nowrap;
    font-weight: 300;
    font-size: 16px;
    overflow: auto;
    color: #fff;
}

.section-upcoming-card-info span{
    gap: 10px;
    display: flex;
    align-items: center;
}

.section-upcoming-card-info span + span{
    margin-left: 12px;
}

.section-upcoming-card-content{
    padding: 18px 15px 40px;
    position: relative;
}

.section-upcoming-card-content-title{
    margin-bottom: 20px;
}

.section-upcoming-card-content-title h3{
    color: #000;
    display: none;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 18px;
    font-weight: 300;
    /* display: flex; */
    gap: 6px;
    align-items: center;
    margin-bottom: 14px;
}

.section-upcoming-card-content-title h3 img{
    width: 36px;
    height: 36px;
    border-radius: 5px;
}

.section-upcoming-card-content-title h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 30px;
    font-weight: 600;
    min-height: 85px;
    margin-right: 48px;
    color: #000;
}

.section-upcoming-card-control{
    top: 5px;
    gap: 10px;
    right: 15px;
    position: absolute;
}

.section-upcoming-card-control img{
    width: 50px;
}

.section-upcoming-card-content-pills{
    padding-left: 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 25px;
    padding-bottom: 1px;
    overflow-y: scroll; /* Add the ability to scroll the y axis */

    /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
}

.section-upcoming-card-content-pills::-webkit-scrollbar{
    display: none;
}

.section-upcoming-card-content-pills span{
    border: 1px solid #4A55BE;
    border-radius: 5px;
    margin-right: 12px;
    line-height: 36px;
    color: #4A55BE;
    /* min-width: 145px; */
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 16px;
}

.section-upcoming-card-content-footer{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: rgba(74, 85, 190, 0.8);
    font-family: "Red Hat Display", sans-serif;
}

.section-upcoming-card-content-footer strong{
    font-size: 22px;
    font-weight: 700;
    color: #4A55BE;   
    margin-right: 12px;
}

.section-home-signup{
    z-index: 1;
    position: relative;
    padding-top: 146px;
    padding-bottom: 146px;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
}

.section-home-signup:before{
    background: linear-gradient(85.42deg, rgba(0, 0, 0, 0) 1.69%, rgba(0, 0, 0, 0.5) 58.25%);
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.section-home-signup h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #fff;
}

.section-home-signup p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 30px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: #fff;
}

.btn-hm-work{
    color: #000;
    height: auto;
    line-height: 1;
    font-size: 24px;
    font-weight: 700;
    border-radius: 10px;
    padding: 2.6rem 3rem;
    letter-spacing: -0.01em;
    background-color: #F2F2F2;
    font-family: "Red Hat Display", sans-serif;
}

.btn-hm-work.active{
    box-shadow: 0px 4px 20px 0px rgba(74, 85, 190, 0.7);
    background-color: #4A55BE;
    color: #fff;
}

.embed-responsive-hm{
    border-radius: 22px;
}

.card-hm-counter{
    border: 1px solid #CCCCCC;
    border-radius: 18px;
    padding: 32px;
    height: 100%;
}

.card-hm-counter span{
    width: 96px;
    height: 96px;
    line-height: 96px;
    font-size: 50px;
    text-align: center;
    border-radius: 50%;
    color: #4A55BE;
    font-weight: 900;
    display: inline-block;
    border: 1px solid #CCCCCC;
    margin-bottom: 25px;
    font-family: "Red Hat Display", sans-serif;
}

.card-hm-counter h3{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #000;
}

.card-hm-counter p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
    color: #000;
}

.section-home-testimonials{
    padding-top: 60px;
    padding-bottom: 160px;
    background-color: #F2F2F2;
}

.testimonial-hm-image{
    border-radius: 22px;
    margin: 0 auto 25px;
    max-width: 560px;
    max-height: 420px;
    object-fit: cover;
    object-position: center;
    display: block;
    width: 100%;
}

.testimonial-hm-content{
    gap: 20px;
    min-height: 342px;
}

.testimonial-hm-avatar{
    gap: 20px;
    margin-bottom: 20px;
}

.testimonial-hm-avatar img{
    width: 80px;
    height: 80px;
    border-radius: 80px;
    object-fit: cover;
    object-position: center;
}

.testimonial-hm-avatar  h6{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}

.testimonial-hm-avatar  h6 span{
    font-weight: 500;
    color: #607A8F;
    display: block;
    margin-top: 3px;
}

.testimonial-hm-content h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.testimonial-hm-content p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-size: 20px;
    color: #000;
    font-weight: 400;
}

.testimonial-hm-content .signature{
    text-transform: capitalize;
    transform: rotate(-25deg);
    margin-top: -35px;
    color: #4A55BE;
}

.section-home-testimonials .carousel-indicators{
    display: none;
}

.section-home-testimonials .carousel-control-prev, .section-home-testimonials .carousel-control-next{
    left: 0;
    right: 0;
    top: auto;
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: auto;
    bottom: -80px;
    border-radius: 50px;
    transition: all 0.35s linear;
    background-color: rgba(0, 0, 0, 0.4);
}

.section-home-testimonials .carousel-control-prev:hover, .section-home-testimonials .carousel-control-next:hover{
    background-color: #4A55BE;
}

.section-home-testimonials .carousel-control-prev{
    left: -80px;
}

.section-home-testimonials .carousel-control-next{
    right: -80px;
}

.section-webinar-card{
    display: flex;
    overflow: hidden;
    height: 100%;
    border-radius: 20px;
    background-color: #F2F2F2;
    flex-direction: column;
}

.section-webinar-card:hover{
    box-shadow: 0px 4px 20px 0px rgba(74, 85, 190, 0.2);
}

.section-webinar-card-image{
    padding-bottom: 64.8%;
    position: relative;
}

.section-webinar-card-image img{
    object-position: center;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.section-webinar-card-content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 20px;
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.section-webinar-card-content h2{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 600;
    /* min-height: 85px; */
    font-size: 24px;
    color: #000;
    flex-grow: 1;
}

.section-webinar-card-content .d-flex{
    border-top: 1px solid rgba(66, 66, 66, 0.4);
    padding-bottom: 15px;
    padding-top: 15px;
    gap: 15px;
}

.section-webinar-card-content p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.section-webinar-card-content .btn-hm-link{
    padding: 0;
}

.btn-hm-link{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    color: #4A55BE;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn-hm-link:after{
    width: 8px;
    height: 8px;
    content: "";
    transform: rotate(45deg);
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
}

.section-instagram{
    padding-bottom: 60px;
}

.section-footer{
    background: radial-gradient(74.77% 91.74% at -0.76% 2.04%, #F2F2F2 0%, #E2E3FB 100%);
}

.section-footer .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-footer-inner{
    padding-top: 64px;
    padding-left: 25px;
    padding-bottom: 50px;
}

.section-footer-copyrights{
    border-top: 1px solid rgba(74, 85, 190, 0.4);
    padding-top: 22px;
    padding-bottom: 22px;
}

.section-footer-copyrights p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.section-footer-copyrights p a{
    color: inherit;
}

.section-footer-copyrights p:last-child{
    text-align: right;
    margin-top: -22px;
    margin-right: 4%;
}

.section-footer-inner .footer-about h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 30px;
    color: #000;
}

.section-footer-inner h5{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 700;
    color: #4A55BE;
    font-size: 20px;
    margin-top: 20px;
}

.section-footer-inner ul, .section-footer-inner p{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
}

.section-footer-inner ul li{
    margin-bottom: 8px;
}

.section-footer-inner a{
    color: #000;
}

.section-header:not(.header-access){
    position: relative !important;
    background-color: #F2F2F2;
    padding: 20px 0;
}

.section-header .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}

.section-header .navbar{
    top: 0;
}

.section-header .navbar-brand img{
    max-width: 274px;
    margin-top: -10px;
}

.section-header .navbar-nav .nav-link{
    font-family: "Red Hat Display", sans-serif;
    letter-spacing: -0.01em;
    font-weight: 300;
    font-size: 18px;
    color: #000;
}

.section-header .btn-hm{
    text-align: center;
    line-height: 40px;
    min-width: 140px;
    height: 40px;
}

.section-header .btn-hm-primary-outline{
    margin-left: 30px;
}

.header-top{
    font-family: "Red Hat Display", sans-serif;
    background-color: #4A55BE;
    letter-spacing: -0.01em;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    padding: 5px;
}

.section-home-carousel .owl-item{
    transform: scale(0.9);
    transition: all 0.3s ease;
}

.section-home-carousel .owl-item.cloned:not(.active) + .active, .section-home-carousel .owl-item:not(.cloned, .active) + .active{
    transform: scale(1);
}

.section-home-carousel .owl-item.cloned:not(.active) + .active + div, .section-home-carousel .owl-item:not(.cloned, .active) + .active + div{
    right: -0.2%;
}

/* .section-home-carousel .owl-item.active:first-of-type, .section-home-carousel .owl-item:not(.cloned, .active) + .active{
    transform: scale(1);
}

.section-home-carousel .owl-item.active:first-of-type + div, .section-home-carousel .owl-item:not(.cloned, .active) + .active + div{
    right: -0.4%;
} */

.app-icons img{
    max-height: 50px;
    max-width: 144px !important;
}

.daterangepicker td.in-range{
    background-color: #E2E3FB;
}

.daterangepicker td.active, .daterangepicker td.active:hover{
	background-color: #4A55BE;
}


.btn-hm-auto {
    min-width: auto;
    line-height: normal;
}

.team-member-image{
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 115%;
    border-radius: 12px;
}

.team-member-image img{
    object-position: center;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.team-member p{
    margin-bottom: 15px;
}

.instagram-images{
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    display: grid;
    gap: 12px;
}

.instagram-images img{
    border-radius: 8px;
}