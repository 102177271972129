@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
	/*font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;*/

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	font-size: 100.01%;
	scroll-behavior: smooth;
}

body {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 300;
	min-height: 100vh;
	line-height: 1.35;
	font-size: 18px;
	color: #4A55BE;
}

.container {
	max-width: 1199px;
	width: 100%;
}

.container-md {
	max-width: 920px;
}

.container-xxl {
	max-width: 1280px;
}

.container-xxxl {
	max-width: 1640px;
}

a {
	color: #4A55BE;
	text-decoration: none!important;
}

a:hover {
	color: inherit;
}

a.text-primary:active,a.text-primary:focus,a.text-primary:link,a.text-primary:visited {
	color: #4A55BE;
}

h5 a.text-primary {
	color: #4A55BE!important;
}

h5 a.text-primary:hover {
	text-decoration: underline!important;
}

h5 a.text-primary:active,h5 a.text-primary:focus,h5 a.text-primary:hover,h5 a.text-primary:link,h5 a.text-primary:visited {
	color: #4A55BE!important;
}

h4{
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 600;
}

.btn,a,button {
	outline: 0!important;
}

p {
	font-size: 18px;
	margin: 0 0 1.3em;
	font-weight: 300;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	color: #000;
}

p .text-primary, p span{
	color: #000 !important;
	font-weight: 700;
}

li{
	color: #000;
}

p a{
	font-weight: 400;
	text-decoration: underline !important;
}

h1,h2,h3,h4,h5,h6 {
	color: inherit;
}

h1 {
	font-size: 50px;
	line-height: 1.2;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

h2 {
	font-size: 30px;
	line-height: 1.2;
	font-weight: 600;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.h3,h3 {
	font-size: 26px;
	line-height: 1.2;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

h5 {
	font-size: 18px;
	line-height: 1.2;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

strong {
	font-weight: 700;
}

.dropdown-noarrow:after {
	display: none;
}

.overflow-visible {
	overflow: visible!important;
}

.nowrap {
	white-space: nowrap;
}

.text-underline {
	text-decoration: underline;
}

.btn,.btn img,.btn span,a,button,input {
	-moz-transition: all .35s linear;
	-o-transition: all .35s linear;
	-webkit-transition: all .35s linear;
	transition: all .35s linear;
}

button:focus,input:focus {
	box-shadow: none!important;
}

.btn {
	line-height: 1;
	font-size: 18px;
	padding: 10px 15px;
	border-radius: 4px;
	font-weight: 300;
	font-family: "Red Hat Display", sans-serif;
	/* letter-spacing: -0.01em; */
}

.btn:not(.btn-lg){
	letter-spacing: 0.05em;
}

.btn-link {
	color: #4A55BE!important;
	outline: 0!important;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
}

.btn-icon-right {
	margin-left: 5px;
}

.btn-icon-left {
	margin-right: 5px;
}

.btn .icon-right {
	margin-left: 5px;
}

.btn-lg {
	height: 52px;
	line-height: 52px;
}

.btn-sm {
	padding-top: 8px;
	padding-bottom: 8px;
}

.btn-filter {
	font-size: 20px;
}

.btn-shadow {
	-webkit-box-shadow: 0 20px 70px rgba(62,62,62,.2);
	box-shadow: 0 20px 70px rgba(62,62,62,.2);
}

.dropdown-no-arrow:after {
	display: none;
}

.dropdown-item {
	font-weight: 600;
}

.btn:disabled {
	pointer-events: none;
}

.btn-primary,.btn-primary:focus {
	background-color: #4A55BE;
	border-color: #4A55BE;
	color: #fff;
}

.btn-primary:active,.btn-primary:hover {
	background-color: #4A55BE !important;
	border-color: #4A55BE!important;
	color: #fff !important;
}

.btn-secondary,.btn-secondary:focus {
	background-color: #4A55BE;
	border-color: #4A55BE;
	color: #fff;
}

.btn-secondary:active,.btn-secondary:hover {
	background-color: #fff !important;
	border-color: #4A55BE!important;
	color: #4A55BE !important;
}

.btn-outline-primary,.btn-outline-primary:focus {
	color: #4A55BE!important;
	border-color: #4A55BE!important;
}

.btn-outline-primary:active,.btn-outline-primary:hover {
	background-color: #4A55BE!important;
	color: #fff!important;
}

.btn-gary,.btn-primary:disabled {
	background-color: rgba(143,143,143,.6)!important;
	border-color: rgba(143,143,143,.6)!important;
	color: #fff!important;
}

.dropdown-item.active,.dropdown-item:active {
	background-color: #4A55BE!important;
}

.btn-primary.disabled, .btn-primary:disabled{
	background-color: #4A55BE;
    border-color: #4A55BE;
}

.btn-link {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
}

.text-primary {
	color: #4A55BE!important;
}

.btn-link-orange{
	font-weight: 600;
	color: #4A55BE !important;
}

.btn-white {
	color: #4A55BE;
	-webkit-box-shadow: 0 4px 20px rgba(64,70,104,.1);
	box-shadow: 0 4px 20px rgba(64,70,104,.1);
}

.btn-white:hover {
	background-color: #4A55BE;
	color: #fff;
}

.btn strong {
	font-weight: 700;
	color: #4A55BE;
}

.btn:hover strong {
	color: inherit;
}

.btn-text-space {
	letter-spacing: 2px;
}

.btn-lg {
	height: 65px;
	padding: 0 32px;
	font-size: 20px;
	line-height: 65px;
	border-radius: 10px;
}

.btn-md {
	height: 50px;
	padding: 0 20px;
	font-size: 15px;
	line-height: 50px;
	border-radius: 10px;
}

.btn-md-2x {
	font-size: 18px;
}

.rounded-8 {
	border-radius: 8px;
}

.rounded-4 {
	border-radius: 4px;
}

.mb-100 {
	margin-bottom: 100px;
}

.shadow {
	box-shadow: 0 0 4px rgba(0,0,0,.16);
}

.opacity-25 {
	opacity: .25;
}

.text-secondary {
	color: #4A55BE!important;
}

.bg-blue {
	color: #fff;
	background-color: #073b74;
}

.header {
	z-index: 88;
}

.navbar-nav li {
	margin-right: 28px;
}

.navbar-nav a {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.navbar-nav a:hover {
	color: #4A55BE!important;
}

.navbar {
	top: 20px;
}

.home-banner,.inner-banner {
	z-index: 1;
	height: 600px;
	position: relative;
	background-size: cover;
	border-radius: 0 0 150px 0;
	-webkit-background-size: cover;
	background-position: center center;
}

.home-banner:before,.inner-banner::before {
	border-radius: inherit;
	position: absolute;
	background: #000;
	z-index: -1;
	opacity: .4;
	height: 100%;
	content: "";
	width: 100%;
}

.inner-banner h1 {
	line-height: 1;
	margin-bottom: 25px;
}

.home-banner h1 span,.inner-banner h1 span {
	/* color: #4A55BE; */
	font-weight: 700;
}

.inner-banner {
	height: 465px;
}

.filter-inner {
	-webkit-box-shadow: 0 25px 50px rgba(64,70,104,.1);
	box-shadow: 0 25px 50px rgba(64,70,104,.1);
	padding: 8px 25px 8px 16px;
	border-radius: 4px;
	background: #fff;
}

.filter-area {
	margin-bottom: 90px;
	position: relative;
	margin-top: -60px;
	z-index: 8;
}

.filter-inner .btn,.filter-inner .form-control {
	height: 48px;
}

.form-control:disabled {
	cursor: not-allowed;
}

.form-control {
	box-shadow: none!important;
	-webkit-box-shadow: none!important;
}

.filter-inner .form-control {
	border: none;
	outline: 0;
	color: #4A55BE;
	font-size: 18px;
	padding-right: 0;
	font-weight: 500;
	padding-left: 5px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: #fff!important;
}

.filter-inner select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff url(../images/double-arrows.png) no-repeat center right 5px/15px 22px;
}

.filter-inner .input-group{
	flex-wrap: nowrap !important;
}

.filter-inner .input-group-text {
	background-color: transparent;
	font-size: 18px;
	color: #4A55BE;
	border: none;
}

.form-control::-webkit-input-placeholder {
	color: #4A55BE;
}

.form-control:-ms-input-placeholder {
	color: #4A55BE;
}

.form-control::placeholder {
	color: #4A55BE;
}

.section-title {
	margin-bottom: 40px;
}

.section-title h2 span {
	color: #4A55BE;
}

.section-title-md h2 {
	font-size: 42px;
	font-weight: 700;
}

.section-title p {
	margin-top: 25px;
}

.section-hscroll {
	-webkit-transition: all .35s linear;
	-o-transition: all .35s linear;
	transition: all .35s linear;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.section-hscroll::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.event-card {
	padding: 30px 30px 20px;
	position: relative;
	min-height: 270px;
	position: relative;
	/* margin-right: 50px; */
	border-radius: 1em;
	margin-right: 25px;
	min-width: 22em;
	max-width: 22em;
}

.row .event-card{
	margin: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
}

.row .event-card .event-tags{
	height: auto;
}

.he_extra {
	/* padding-left: 20px; */
	pointer-events: none;
	margin-bottom: 3em;
	/* min-width: 102em!important; */
}

.event-card+.he_extra h3 {
	display: none;
}

.dashboard-hscroll .event-card {
	margin-top: 20px;
	margin-left: 20px;
	border-radius: 5px;
	margin-right: 30px;
	margin-bottom: 20px;
	-webkit-box-shadow: 0 0 30px rgba(0,0,0,.16);
	box-shadow: 0 0 30px rgba(0,0,0,.16);
}

.event-gray-card {
	background: #fff;
	color: #4A55BE;
}

.event-blue-card {
	background: #4A55BE;
	color: #fff;
}

.event-card h3 {
	max-width: 75%;
	min-height: 56px;
	font-weight: 700;
	text-transform: capitalize;
}

.event-card p {
	color: inherit;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 8px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.event-card .pc-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 15px;
}

.event-card .pc-info i {
	margin-right: 5px;
	line-height: 18px;
	width: 18px;
}

.event-card .pc-info i.fa-dollar-sign,.event-card .pc-info i.fa-map-pin {
	height: 18px;
	font-size: 10px;
	line-height: 18px;
}

.event-tags {
	height: 78px;
	display: block;
	overflow: hidden;
	padding-top: 12px;
}

.ev-tag {
	font-size: 11px;
	font-weight: 600;
	min-width: 85px;
	padding: 5px 10px;
	margin-right: 5px;
	text-align: center;
	margin-bottom: 10px;
	border-radius: 50px;
	display: inline-block;
	border: 1px solid #fff;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-transform: lowercase;
}

.event-gray-card .ev-tag {
	border-color: #4A55BE;
}

.event-dots {
	position: absolute;
}

.event-dots-bottom {
	max-width: 80%;
	bottom: 5px;
	right: 0;
}

.event-dots-top {
	max-height: 80%;
	right: 10px;
	top: 0;
}

.get-started-spacer {
	position: relative;
	z-index: 2;
	margin: 90px 0 150px;
}

.btn-get-started {
	height: 60px;
	font-size: 22px;
	min-width: 300px;
	font-weight: 700;
	padding: 0 30px;
	line-height: 60px;
	border-radius: 50px;
}

.get-started h3 {
	margin-bottom: 0;
	max-width: 190px;
}

.get-started p{
	color: #000;
}

.event-blue-card i.fa-dollar-sign,.event-blue-card i.fa-map-pin {
	top: -1px;
	width: 15px;
	height: 15px;
	color: #4A55BE;
	font-size: 10px;
	background: #fff;
	line-height: 15px;
	text-align: center;
	position: relative;
	border-radius: 20px;
}

.section-tabs {
	margin-bottom: 120px;
}

.count-info {
	background: #fff;
	border-radius: 10px;
	padding: 35px 16px 25px;
}

.count-info strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
	font-size: 64px;
	line-height: 1;
}

.count-info h3 {
	max-width: 90%;
    display: block;
    min-height: 48px;
    margin-left: auto;
    margin-right: auto;
	margin-bottom: 18px;
}

.count-info p {
	color: #000;
	font-size: 18px;
	margin-bottom: 0;
	min-height: 145px;
}

.section-tabs .nav-pills {
	margin-bottom: 25px;
	flex-wrap: nowrap;
}

.section-tabs .nav-pills .nav-link {
	z-index: 5;
	font-size: 20px;
	border-radius: 0;
	position: relative;
	color: #4A55BE!important;
	border-bottom: 3px solid transparent;
	background-color: transparent!important;
}

.section-tabs .nav-pills .nav-link:after {
	background: transparent url(../images/arrow.png) no-repeat top center/contain;
	position: absolute;
	bottom: -20px;
	margin: auto;
	height: 20px;
	z-index: -2;
	content: "";
	width: 20px;
	opacity: 0;
	right: 0;
	left: 0;
}

.section-tabs .nav-pills .nav-link.active {
	border-bottom-color: #4A55BE;
}

.section-tabs .nav-pills .nav-link.active:after {
	opacity: 1;
}

.circle-pink {
	height: 0;
	width: 40%;
	display: none;
	border-radius: 50%;
	padding-bottom: 40%;
	background-color: #fce3e2;
}

.circle-pink-tr {
	z-index: -1;
	right: -25%;
	top: 15%;
}

.circle-pink-ml {
	margin: auto;
	left: -25%;
	bottom: 0;
	top: 0;
}

.footer {
	padding: 52px 0 35px;
}

.footer-logo {
	margin-bottom: 20px;
	max-width: 103px;
	display: block;
}

.footer h5 {
	margin-bottom: 15px;
	line-height: 1.4;
}

.footer h5 small {
	font-weight: inherit;
	font-size: 16px;
}

.footer p {
	margin-bottom: 10px;
}

.footer ul li {
	margin-bottom: 5px;
}

.footer ul a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.footer ul a:hover {
	color: #4A55BE;
}

.section-testimonial {
	margin-bottom: 100px;
}

.section-testimonial h2 {
	font-size: 62px;
	line-height: 1;
	font-weight: 700;
}

.testimonial-review-text {
	max-width: 80%;
	/* word-break: break-all; */
}

.testimonial-review-text h3 {
	font-size: 34px;
	margin-bottom: 12px;
}

.signature {
	font-family: 'Westbury Signature Demo Version';
	-webkit-transform: rotate(-10deg);
	-ms-transform: rotate(-10deg);
	-o-transform: rotate(-10deg);
	transform: rotate(-10deg);
	margin-top: -25px;
	font-size: 44px;
}

.testimonial_outer {
	z-index: 1;
	padding: 50px 0;
	margin-left: 10px;
	position: relative;
}

.testimonial-review {
	padding: 45px 15px;
	min-height: 500px;
}

.testimonial-review-pic {
	height: 320px;
	margin-right: 50px;
	border-radius: 50%;
	width: 320px!important;
	min-width: 320px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: all .8s linear;
	-o-transition: all .8s linear;
	transition: all .8s linear;
	object-fit: cover;
	object-position: center;
}

/* .testimonial-review .embed-responsive{
	min-width: 320px;
	margin-right: 50px;
	width: 320px!important;
} */

.testimonial-review .embed-responsive{
	width: 100%;
}


.owl-item.active .testimonial-review-pic {
	-webkit-box-shadow: 0 20px 40px rgba(61,61,61,.3);
	box-shadow: 0 20px 40px rgba(61,61,61,.3);
}

.testimonial_outer:before {
	border-radius: 54px 0 0 54px;
	background-color: #f9f9fa;
	position: absolute;
	height: 100%;
	z-index: -1;
	left: 175px;
	content: "";
	top: 0;
	right: 0;
}

.section-testimonial {
	position: relative;
	z-index: 1;
}

.section-testimonial:before {
	background: #f9f9fa url(../images/rings.png) no-repeat center bottom -120px/220px;
	position: absolute;
	height: 100%;
	content: "";
	z-index: -1;
	width: 50%;
	left: 100%;
	top: 0;
}

.section-testimonial .owl-testimonial [role=button] {
	transition: all .35s linear;
	text-align: right;
	position: absolute;
	width: 100%;
	top: -72px;
	opacity: 1;
	width: 45px;
	height: 45px;
	color: #fff!important;
	border-radius: 5px!important;
	background-color: #4A55BE!important;
}

.section-testimonial .owl-testimonial [role=button].carousel-control-prev {
	right: 55px;
	left: auto;
}

.section-testimonial .owl-testimonial [role=button]:hover {
	background-color: #4A55BE!important;
}

.section-testimonial .owl-nav button i {
	top: 1px;
	font-size: 18px;
	position: relative;
}

.owl-theme .owl-dots .owl-dot span {
	background-color: #c9c9c9;
	width: 12px;
	height: 12px;
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
	background-color: #4A55BE;
}

.modal-header .close {
	top: -10px;
	right: -6px;
	opacity: 1;
	padding: 0;
	color: #fff;
	width: 25px;
	height: 25px;
	font-size: 14px;
	text-shadow: none;
	border-radius: 20px;
	background: #4A55BE;
	position: relative;
}

.modal-content {
	border-radius: 10px;
	border-color: #707070!important;
}

.modal .filter-inner {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modal .filter-inner .form-group {
	padding: 3px 10px;
	border-radius: 10px;
	border: 1px solid #dcdcdc;
	-webkit-box-shadow: 0 25px 50px rgba(64,70,104,.1);
	box-shadow: 0 25px 50px rgba(64,70,104,.1);
}

.modal .filter-inner .form-group * {
	border-radius: inherit;
}

.modal-title {
	font-size: 18px;
}

.page-access {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
}

.page-access .container-xxl {
	max-width: 100%;
}

.page-access h1 {
	font-size: 50px;
	max-width: 320px;
	line-height: 1.05;
	font-weight: 700;
	margin-bottom: 15px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.page-access h1 span {
	color: #4A55BE;
}

.page-content-inner {
	max-width: 600px;
	margin: auto;
	width: 100%;
}

.page-image {
	-webkit-box-shadow: 0 20px 40px rgba(164,164,164,.4);
	box-shadow: 0 20px 40px rgba(164,164,164,.4);
	background-position: center center;
	-webkit-background-size: cover;
	background-color: #d8d8d8;
	background-size: cover;
	position: absolute;
	height: 100%;
	width: 50%;
	right: 0;
	top: 0;
}

.page-image:before {
	position: absolute;
	background: #000;
	opacity: .3;
	height: 100%;
	content: "";
	width: 100%;
	z-index: 1;
	left: 0;
	top: 0;
}

.header-access {
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
	background-color: rgba(255,255,255,.8);	
	padding: 10px 25px;
}
.header-access .navbar {
	top: 0;
}
.navbar-brand img{
	max-width: 100px;
	width: 100%;
}
.header-access .navbar-brand img {
	max-width: 52px;
	width: 100%;
}

.header-access .navbar-nav a {
	color: #4A55BE;
}

.page-access {
	padding-bottom: 80px;
	padding-top: 210px;
}

.form-field {
	border: 1px solid #E2E3FB;
	padding: 10px 15px;
	border-radius: 8px;
}

.form-group {
	margin-bottom: 15px;
}

.form-field label {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 1;
}

.form-field .form-control[readonly] {
	background-color: #fff!important;
}

.form-field .form-control {
	border: 0;
	padding: 0;
	height: auto;
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
	border-radius: 0;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: transparent!important;
}

.form-field input.form-control {
	height: 22px;
	line-height: 1;
}

.form-field textarea.form-control {
	height: auto;
	font-size: 14px;
}

.form-field textarea.form-control.form-control-size {
	font-size: 18px;
}

.custom-control label {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 18px;
}

.custom-control label a{
	text-decoration: underline !important; 
}

.form-field select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	min-width: 160px;
	padding-right: 25px!important;
	/* border-bottom: 1px solid #4A55BE; */
	background: #fff url(../images/arrow-triangle.png) no-repeat center right 8px/13px;
}

.custom-checkbox .custom-control-label::after,.custom-checkbox .custom-control-label::before {
	top: 1px;
	left: -30px;
	width: 20px;
	height: 20px;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	border-radius: 0!important;
}

.custom-checkbox {
	padding-left: 30px;
}

.custom-control-input:checked~.custom-control-label::before {
	border-color: #4A55BE;
	background-color: #4A55BE;
}

.page-access p {
	font-size: 18px;
	margin-bottom: 15px;
}

.iti {
	display: block;
}

.iti__selected-flag {
	padding: 0;
}

.iti--allow-dropdown input,.iti--allow-dropdown input[type=tel] {
	padding-left: 42px;
}

.ur_short_name {
	width: 50px;
	height: 50px;
	border: none !important;
	min-width: 50px !important;
	font-size: 20px;
	font-weight: 700;
	line-height: 50px;
	text-align: center;
	border-radius: 35px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	background-color: #4A55BE;
	text-transform: uppercase;
}

.sidebar {
	top: 0;
	left: 0;
	z-index: 99;
	height: 100%;
	width: 240px;
	position: fixed;
	background-color: #f2f2f2;
	-webkit-box-shadow: 5px 0 50px rgba(62,62,62,.2);
	box-shadow: 5px 0 50px rgba(62,62,62,.2);
}

.sidebar_logo {
	padding: 25px 25px 15px;
	margin-bottom: 20px;
}

.sidebar_logo .btn-close{
	position: relative;
	right: -10px;
	top: -12px;
}

.sidebar_logo .btn-close .fas{
	font-size: 23px;
}

.sidebar_logo img {
	max-width: 275px;
	width: 100%;
}

.sidebar_links {
	overflow: auto;
	padding: 15px 25px 15px;
}

.sidebar_links ul {
	margin-bottom: 35px;
}

.sidebar_links ul li {
	margin-bottom: 5px;
}

.sidebar_links ul a {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 600;
	font-size: 20px;
}

.sidebar_links ul a.active,.sidebar_links ul a:hover {
	font-weight: 700;
}

.sidebar_links h6 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 14px;
}

.sidebar_user {
	padding: 20px 25px;
	border-top: 1px solid #4A55BE;
}

.sidebar_user strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 3px;
	font-weight: 600;
	font-size: 20px;
	display: block;
}

.rating_stars {
	font-size: 14px;
}

.rating_stars_2x {
	font-size: 18px;
}

.header-dashboard {
	display: block;
	margin-left: 240px;
	background-color: #f6f8fa;
	padding: 25px 30px 30px 60px;
}

.input-group-address {
	flex-grow: 1;
	height: 48px;
	display: flex;
	align-items: center;
}

.input-group-address>div {
	flex-grow: 1;
}

.css-2b097c-container .css-1uccc91-singleValue,.input-group-address .css-2b097c-container .css-1wa3eu0-placeholder {
	font-weight: 500;
}

.input-group-search {
	overflow: hidden;
	border-radius: 25px;
	background-color: #f2f2f2;
	-webkit-box-shadow: 0 20px 40px rgba(0,0,0,.1);
	box-shadow: 0 20px 40px rgba(0,0,0,.1);
}

.input-group-search .form-control {
	height: 50px;
	font-size: 18px;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.header-controls {
	margin-left: 60px;
}

.header-controls a {
	color: #4A55BE;
	position: relative;
	display: inline-block;
}

.header-controls a.active,.header-controls a:hover {
	color: #000;
}

.header-controls a.active .badge,.header-controls a:hover .badge {
	background-color: #000!important;
}

.header-controls a i {
	font-size: 32px;
}

.header-controls a .badge {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border-radius: 20px;
	background: #4A55BE;
	position: absolute;
	line-height: 16px;
	font-weight: 600;
	min-width: 15px;
	font-size: 10px;
	padding: 0 5px;
	height: 16px;
	color: #fff;
	border: 0;
	right: -8px;
	top: -6px;
}

.page-dashboard {
	display: block;
	margin-left: 240px;
	background-color: #f6f8fa;
	padding: 35px 15px 20px 45px;
	min-height: calc(100vh - 66px);
}

.page-info h1 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
	font-size: 50px;
}

.page-info h1 span {
	color: #4A55BE;
}

.page-info p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 300;
	font-size: 18px;
}

.member-card {
	-webkit-box-shadow: 5px 10px 40px rgba(99,99,99,.1);
	box-shadow: 5px 10px 40px rgba(99,99,99,.1);
	padding: 40px 20px 25px;
	background-color: #fff;
	border-radius: 25px;
	position: relative;
	max-width: 360px;
	width: 100%;
	/* min-width: 360px; */
	overflow: hidden;
	display: block;
}

.card-dots {
	position: absolute;
	max-width: 100%;
	right: -50px;
	top: 18px;
}

.member-plan {
	height: 29px;
	margin-bottom: 20px;
}

.member-plan img {
	mix-blend-mode: darken;
	max-width: 100%;
}

.member-card h2 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 10px;
	font-weight: 700;
	font-size: 30px;
}

.member-card p {
	font-size: 16px;
	min-height: 150px;
}

.member-price {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin-bottom: 15px;
	font-size: 14px;
}

.member-price strong {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	margin-bottom: 3px;
	font-weight: 700;
	font-size: 30px;
	display: block;
	line-height: 1;
}

.btn-close {
	line-height: 1;
	font-size: 30px;
}

.notification .btn-link {
	text-decoration: none!important;
}

.notification {
	padding: 20px;
	background: #fff;
	border-radius: 7px;
	border-left: 7px solid #4A55BE;
	-webkit-box-shadow: 0 20px 40px rgba(202,202,201,.1);
	box-shadow: 0 20px 40px rgba(202,202,201,.1);
}

.notification.active {
	border-left-color: #4A55BE;
}

.notification p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 18px;
	margin: 0;
}

.notification p strong {
	font-weight: 600;
}

.notification-date {
	color: #595959;
	font-size: 12px;
	font-weight: 600;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.admin-subheading p {
	font-size: 16px;
}

.admin-subheading p span {
	color: #4A55BE;
}

.scroll-horizontal {
	height: 280px!important;
}

.dashboard-hscroll {
	margin-left: -20px;
	margin-right: -30px;
	width: auto!important;
	height: 330px!important;
}

.dashboard-hscroll .event-card {
	min-height: 290px;
}

.event-card-chat {
	position: absolute;
	font-size: 18px;
	color: #4A55BE;
	font-size: 25px;
	right: 15px;
	top: 15px;
}

.post-card {
	background: #fff url(../images/rings.png) no-repeat right -50px bottom -50px/180px;
	-webkit-box-shadow: 0 20px 40px rgba(97,97,97,.2);
	box-shadow: 0 20px 40px rgba(97,97,97,.2);
	border-radius: 22px;
	padding: 40px 50px;
	position: relative;
}

.pc-status div, .pc-status a{
	padding: 0 15px;
	padding-top: 2px;
	margin-bottom: 20px;
	border-radius: 25px !important;
}
.pc-status {
	right: 15px;
	top: 60px;
	z-index: 5;
	height: 32px;
	font-size: 14px;
	min-width: 150px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
	position: absolute;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-transform: uppercase;
}

.status-active {
	-webkit-box-shadow: -5px 5px 20px rgba(10,77,151,.4);
	box-shadow: -5px 5px 20px rgba(10,77,151,.4);
	background: #4A55BE;
	color: #fff;
}

.status-closed {
	-webkit-box-shadow: -5px 5px 20px rgba(180,180,180,.4);
	box-shadow: -5px 5px 20px rgba(180,180,180,.4);
	background: #b4b4b4;
	color: #fff;
}

.status-select {
	-webkit-box-shadow: -5px 10px 20px rgba(74, 86, 190, 0.4);
	box-shadow: -5px 10px 20px rgba(74, 86, 190, 0.4);
	color: #fff !important;
	background: #4A55BE;
}
.status-select:not(.pc-status) {
	min-width: 100%;
}

.post-card p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin: 0 0 20px;
	font-size: 15px;
	max-width: 75%;
	word-break: break-word;
}

.post-card h2 {
	padding-right: 120px;
	margin: -18px 0 8px;
	font-weight: 700;
}

.pc-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	margin-bottom: 10px;
	font-size: 18px;
}

.pc-info i {
	width: 20px;
	margin-right: 10px;
	text-align: center;
}

.d-flex>.pc-info i {
	margin-right: 3px;
}

.d-flex>.pc-info {
	margin-right: 15px;
}

.pc-tags {
	margin-top: 15px;
}

.pc-tag {
	text-transform: lowercase;
	border: 2px solid #4A55BE;
	display: inline-block;
	border-radius: 20px;
	vertical-align: top;
	text-align: center;
	margin-right: 20px;
	padding: 7px 10px;
	background: #fff;
	margin-top: 15px;
	min-width: 160px;
	font-size: 16px;
}

.chat-list {
	background-color: #f4f4f4;
}

.input-chat-search {
	width: auto;
	background: #fff;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 30px;
	margin: 30px 15px 15px;
}

.input-chat-search .form-control,.input-chat-search i {
	color: #717171;
}

.chat-type .form-control::-webkit-input-placeholder,.input-chat-search .form-control::-webkit-input-placeholder {
	color: #717171;
}

.chat-type .form-control:-ms-input-placeholder,.input-chat-search .form-control:-ms-input-placeholder {
	color: #717171;
}

.chat-type .form-control::placeholder,.input-chat-search .form-control::placeholder {
	color: #717171;
}

.chat-contact {
	width: 100%;
	color: #595959;
	font-size: 10px;
	cursor: pointer;
	border-radius: 8px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	background-color: #fff;
	padding: 8px 25px 8px 10px;
}

.chat-contact.active {
	border-color: #4A55BE;
}

.contact-auth {
	text-transform: uppercase;
	background-color: #e9e9e9;
	border-radius: 30px;
	text-align: center;
	margin-right: 12px;
	line-height: 32px;
	font-weight: 500;
	min-width: 32px;
	font-size: 12px;
	color: #4A55BE;
	height: 32px;
}

.contact-name {
	width: calc(100% - 44px);
}

.contact-name strong {
	font-weight: 500;
	font-size: 15px;
	color: #4A55BE;
	display: block;
	width: 100%;
}

.contact-active {
	top: 0;
	bottom: 0;
	right: 8px;
	width: 12px;
	margin: auto;
	height: 12px;
	position: absolute;
	border-radius: 12px;
	background-color: #4A55BE;
}

.chat-list {
	max-width: 300px;
	margin-right: 50px;
	-webkit-flex: 0 0 300px;
	-moz-flex: 0 0 300px;
	-ms-flex: 0 0 300px;
	-o-flex: 0 0 300px;
	flex: 0 0 300px;
}

.chat-contact-list {
	max-height: 700px;
	padding: 10px 15px 15px;
}

.btn-hired {
	text-transform: uppercase;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	letter-spacing: 2px;
	white-space: nowrap;
	font-weight: 700;
	line-height: 36px;
	border-radius: 0;
	font-size: 14px;
	height: 36px;
}

.chat-header {
	background-color: #f4f4f4;
	border-radius: 5px;
	padding: 10px 20px;
	margin-bottom: 15px;
}

.chat-header .contact-auth {
	line-height: 60px;
	min-width: 60px;
	font-size: 18px;
	height: 60px;
}

.chat-header .contact-name {
	color: #595959;
}

.chat-header .contact-name strong {
	font-size: 20px;
	font-weight: 700;
}

.chat-messages {
	background-color: #f4f4f4;
	border-radius: 5px;
}

.chat-messages-inner {
	padding: 40px 40px 20px;
	height: 580px;
	overflow: auto;
}

.chat-message {
	margin-bottom: 12px;
}

.chat-text {
	max-width: 80%;
}

.chat-received {
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.chat-sent {
	-ms-align-items: flex-end;
	align-items: flex-end;
}

.chat-sent .chat-text {
	background-color: #4A55BE;
	color: #fff;
}

.chat-text p {
	word-break: break-all;
	font-size: inherit;
	margin-bottom: 0;
	color: inherit;
}

.chat-text {
	border-radius: 15px;
	margin-bottom: 6px;
	background: #fff;
	font-size: 16px;
	color: #595959;
	padding: 20px;
}

.chat-time {
	/* padding: 0 20px; */
	font-weight: 500;
	font-size: 10px;
	line-height: 0.8;
	opacity: 0.8;
	color: #595959;
}

.chat-type {
	width: 100%;
	padding: 10px 40px 40px;
}

.chat-type form {
	overflow: hidden;
	padding: 10px 12px;
	border-radius: 50px;
	background-color: #fff;
	-webkit-box-shadow: 0 10px 24px rgba(0,0,0,.1);
	box-shadow: 0 10px 24px rgba(0,0,0,.1);
}

.chat-type .btn,.chat-type .form-control {
	height: 40px;
	padding: 0;
	border: 0;
}

.chat-type .btn {
	min-width: 40px;
	color: #fff;
	font-size: 18px;
	border-radius: 40px;
	background-color: #4A55BE;
}

.chat-type .form-control {
	padding-left: 15px;
	font-weight: 500;
	color: #4A55BE;
}

.contact-date {
	white-space: nowrap;
	font-size: 12px;
	color: #b9b9b9;
	margin-left: auto;
}

.contact-date .badge {
	border: 0;
	padding: 0;
	width: 20px;
	color: #fff;
	height: 20px;
	display: block;
	font-size: 13px;
	font-weight: 600;
	line-height: 21px;
	margin: 5px auto 0;
	border-radius: 20px;
	background-color: #4A55BE;
}

.contact-online .contact-auth {
	position: relative;
}

.contact-online .contact-auth:after {
	background-color: #01e74c;
	position: absolute;
	border-radius: 8px;
	content: "";
	height: 8px;
	width: 8px;
	bottom: 3px;
	right: 3px;
}

.rating-box {
	border-radius: 7;
	padding: 25px 15px 25px 25px;
	border-left: 7px solid #4A55BE;
	-webkit-box-shadow: 0 20px 40px rgba(202,202,201,.1);
	box-shadow: 0 20px 40px rgba(202,202,201,.1);
}

.rating-box.chat-contact {
	cursor: auto;
}

.rating-box .contact-auth {
	height: 50px;
	min-width: 50px;
	font-size: 20px;
	font-weight: 600;
	line-height: 50px;
	margin-right: 15px;
	border-radius: 7px;
	background-color: #f4f4f4;
}

.rating-box .contact-name {
	width: 58%;
	font-size: 16px;
	font-weight: 400;
}

.rating-box .contact-name strong {
	text-transform: capitalize;
	font-weight: 600;
	font-size: 25px;
}

.contact-review strong {
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 35px;
	display: block;
	line-height: 1;
	color: #4A55BE;
}

.contact-review p {
	margin-bottom: 0;
	font-size: 14px;
	color: #6d6d6d;
	white-space: nowrap;
	margin-top: 8px;
}

.profile-card {
	color: #fff;
	padding: 25px 15px;
	border-radius: 23px;
	margin-bottom: 25px;
	background-color: #4A55BE;
}

.profile-card .contact-auth {
	height: 72px;
	font-size: 20px;
	min-width: 72px;
	font-weight: 600;
	line-height: 72px;
	border-radius: 50px;
	margin-bottom: 12px;
	background-color: #e9e9e9;
}

.pic_upload {
	top: 5px;
	right: 0;
	width: 30px;
	height: 30px;
	font-size: 12px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	border-radius: 30px;
}

.pic_upload:hover {
	border-color: #000!important;
}

.profile-card strong {
	margin-bottom: 2px;
	font-weight: 700;
	font-size: 22px;
	display: block;
}

.profile-card span {
	font-weight: 500;
	font-size: 14px;
}

.list-group {
	border-radius: 23px;
	-webkit-box-shadow: 0 3px 60px rgba(0,0,0,.16);
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
}

.list-group a {
	color: #4A55BE;
	font-size: 16px;
	font-weight: 500;
	padding-top: 15px;
	padding-bottom: 15px;
	border-width: 0 0 1px 0;
}

.list-group a.active {
	border-color: rgba(0,0,0,.125);
	background-color: #4A55BE!important;
	color: #fff!important;
}

.list-group a:hover {
	background-color: #4A55BE;
	color: #fff;
}

.list-group a:last-child {
	border-bottom-width: 0;
}

.list-group a i {
	margin-right: 10px;
	font-size: 16px;
}

.profile-box {
	background-color: #fff;
	border-radius: 23px;
	padding: 30px;
}

.profile-box p {
	font-size: 15px;
	font-weight: 400;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.profile-box h3 {
	font-weight: 600;
	margin-bottom: 5px;
}

.profile-box .form-field{
	min-height: 58px;
}

.btn-edit {
	text-decoration: none;
	font-size: 14px;
}

.btn-edit i {
	width: 22px;
	height: 22px;
	color: #fff;
	font-size: 10px;
	line-height: 22px;
	margin-right: 3px;
	border-radius: 22px;
	background-color: #4A55BE;
}

.job-post-box h2 {
	font-weight: 700;
}

.job-post-box {
	padding: 80px 15px 30px;
}

.job-post-box p {
	font-size: 14px;
}

.nav-tab-list {
	width: 100%;
	max-width: 300px;
	min-height: 500px;
	padding: 80px 40px 30px;
	background-color: #f4f4f4;
}

.nav-tab-list a {
	font-size: 18px;
	font-weight: 400;
	position: relative;
	padding: 0 0 0 35px;
	pointer-events: none;
	color: #4A55BE!important;
	background: 0 0!important;
}

.nav-tab-list a.active,.nav-tab-list a.done {
	pointer-events: all;
}

.nav-tab-list a {
	padding-bottom: 50px;
}

.nav-tab-list a:before {
	left: 0;
	top: 3px;
	content: "";
	width: 18px;
	height: 18px;
	position: absolute;
	border-radius: 16px;
	border: 1px solid #4A55BE;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #f4f4f4;
	-webkit-transition: all .35s linear;
	-o-transition: all .35s linear;
	transition: all .35s linear;
	background-position: center center;
	background-repeat: no-repeat;
}

.nav-tab-list a.active:before {
	background: #4A55BE;
	-webkit-box-shadow: 2px 6px 10px rgba(62,62,62,.2);
	box-shadow: 2px 6px 10px rgba(62,62,62,.2);
}

.nav-tab-list a.done:before {
	background: #4A55BE url(../images/tick.png) no-repeat center/10px;
}

.nav-tab-list a:after {
	top: 28px;
	left: 9px;
	width: 1px;
	content: "";
	bottom: 2px;
	margin-top: -2px;
	position: absolute;
	background-color: #4A55BE;
}

.nav-tab-list a:last-child:after {
	display: none;
}

.need-help {
	width: 100%;
	height: 100%;
	font-size: 20px;
	font-weight: 300;
	min-height: 65px;
	color: #4A55BE!important;
	border: 1px solid #4A55BE!important;
}

.input-group-counter {
	width: 80px;
}

.input-group-pay {
	max-width: 280px;
}

.input-group-pay .form-control {
	line-height: 1.3;
	margin-left: 4px;
	display: flex;
	align-items: center;
}

.form-area h4 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 8px;
}

.form-group>label {
	margin-bottom: 6px;
	font-weight: 400;
	font-size: 14px;
}

.form-switch label {
	width: 130px;
	height: 24px;
	font-size: 14px;
	padding: 0 26px;
	font-weight: 300;
	background: #fff;
	line-height: 23px;
	border-radius: 12px;
	border: 1px solid #4A55BE;
	-webkit-transition: all .35s linear;
	-o-transition: all .35s linear;
	transition: all .35s linear;
	position: relative;
}

.form-switch label:before {
	top: 0;
	left: 6px;
	bottom: 0;
	content: "";
	width: 16px;
	height: 16px;
	margin: auto;
	position: absolute;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4A55BE;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
}

.fs-yes {
	color: #fff;
}

.form-switch input:checked+label {
	background-color: #4A55BE;
}

.form-switch input:checked+label:before {
	left: 107px;
}

.custom-control-outer .custom-control label {
	font-size: 16px;
}

.custom-control-outer .custom-checkbox .custom-control-label::after,.custom-control-outer .custom-checkbox .custom-control-label::before {
	top: 0px;
}
.custom-control-outer > div{
	margin-top: -1px;
	padding-bottom: 2px;
}

.position-sticky {
	top: 0;
}

.skill-pill {
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	align-items: center;
}

.skill-pill label {
	color: #4A55BE;
	font-size: 11px;
	margin-top: 5px;
	font-weight: 700;
	margin-right: 5px;
	border-radius: 15px;
	padding: 5px 6px 4px;
	cursor: pointer;
	gap: 3px;
	border: 1px solid #4A55BE;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	align-items: center;
}

.skill-pill input:checked+label,.skill-pill.selected label {
	background-color: #4A55BE;
	color: #fff;
}

/* .skill-pill.selected img {
	filter: grayscale(5) invert(5) brightness(1.5);
} */

.skill-pill button {
	margin-left: auto;
	padding-left: 5px!important;
}

.skill-pill img {
	display: inline-block;
}

.upload_cv {
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
}

.blank-area.text-center {
	padding: 0 0 20px;
	text-align: left !important;
}	

.react-tel-input {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-end;
	-webkit-flex-direction: row-reverse;
	-moz-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	-o-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.react-tel-input input[type=tel],.react-tel-input input[type=text] {
	padding: 0 0 0 40px!important;
	font-size: 18px!important;
	height: 24px!important;
	width: 100%!important;
}

.react-tel-input .selected-flag {
	padding: 0!important;
}

.react-tel-input .form-control {
	width: 100%;
	height: 65px;
	font-size: 18px;
	padding: 6px 0;
	letter-spacing: -.6px;
	border-radius: 0!important;
}

.react-tel-input .selected-flag {
	font-size: 18px;
	padding: 6px 0;
	letter-spacing: -.6px;
	border-radius: 16px!important;
}

.react-tel-input .country-list {
	font-size: 16px;
}

.react-tel-input .flag-dropdown {
	border-width: 0;
	margin-right: 12px;
	position: relative;
	border: 0!important;
	border-radius: 0!important;
	background-color: transparent!important;
}

.react-tel-input .selected-flag {
	padding: 0!important;
}

.react-tel-input .selected-flag .arrow {
	left: 25px;
	border-top-width: 5px;
	border-left-width: 5px;
	border-right-width: 5px;
}

.react-tel-input .selected-flag .arrow.up {
	border-top-width: 0;
	border-bottom-width: 8px;
}

.fa-calendar {
	font-size: 19px;
}

.css-2b097c-container {
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
}

.css-2b097c-container .css-1pahdxg-control,.css-2b097c-container .css-yk16xz-control {
	border: none;
	min-height: auto;
	box-shadow: none;
}

.css-2b097c-container .css-g1d714-ValueContainer {
	padding: 0;
	color: inherit!important;
	background-color: transparent;
}

.css-2b097c-container .css-1hb7zxy-IndicatorsContainer {
	display: none;
}

.css-2b097c-container .css-1uccc91-singleValue,.css-2b097c-container .css-1wa3eu0-placeholder {
	color: #4A55BE;
	font-size: 18px;
	font-weight: 300;
}

.payment-card {
	width: 100%;
	padding: 3em;
	margin: auto;
	margin-top: 3.5em;
	max-width: 1000px;
	border-radius: 15px;
}

.pay-button:disabled {
	opacity: .6;
	box-shadow: none;
	cursor: default;
}

.pay-button:disabled:hover {
	box-shadow: none;
}

.pay-button:hover {
	box-shadow: 2px 5px 15px 2px rgba(0,0,0,.2);
}

.CardField-input-wrapper {
	font-size: 16px;
}

.StripeElement {
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid #4A55BE;
}

.StripeElement .ElementsApp>* {
	font-size: 16px;
	color: #4A55BE;
}

.calendar {
	width: 100%;
	display: block;
	color: #000;
	text-align: center;
}

.month-label {
	line-height: 1;
	font-size: 28px;
	min-width: 240px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.month-display {
	margin-bottom: 40px;
}

.month-display .arrow {
	background-color: rgba(66,88,118,.33);
	border-radius: 1px;
	line-height: 30px;
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	height: 30px;
	width: 30px;
}

.month-display .arrow:hover {
	background-color: rgba(66,88,118,1);
}

.calendar .day {
	width: 65px;
	height: 65px;
	position: relative;
	cursor: pointer;
	font-size: 20px;
	line-height: 65px;
	border-radius: 5px;
}

.active-events {
	position: absolute;
	padding: 0 5px;
	bottom: 8px;
	z-index: 5;
	right: 0;
	left: 0;
}

.active-events .active {
	width: 4px;
	height: 4px;
	margin: 1px;
	opacity: .62;
	border-radius: 5px;
	background-color: #4A55BE;
}

.selected .active-events .active {
	background-color: #fff;
	opacity: 1;
}

.calendar .today {
	background-color: #eaeaea;
}

.calendar .selected {
	color: #000!important;
	background: #425876;
	text-decoration: none!important;
	background: radial-gradient(circle,#E2E3FB 0,#4A55BE 100%);
	background: -moz-radial-gradient(circle,#E2E3FB 0,#4A55BE 100%);
	background: -webkit-radial-gradient(circle,#E2E3FB 0,#4A55BE 100%);
}

.different-month {
	opacity: 0;
	visibility: hidden;
	user-select: none;
}

.day-names .day {
	font-weight: 700;
}

.calendar .event-card {
	width: 100%;
	min-width: auto;
}

.calendar .btn {
	/* height: 32px; */
	font-size: 14px;
	line-height: 32px;
	/* padding: 2px 10px 0; */
	letter-spacing: .15em;
}

.calender-events {
	overflow-y: auto;
	max-height: 500px;
    overflow-x: hidden;
}

.event_blank {
	background-color: #ffff;
	min-height: 200px;
	margin-top: 8em;
}

.event-card+.event_blank {
	display: none!important;
}

.thank-you h1 {
	/* text-transform: uppercase; */
	font-weight: 700;
	font-size: 48px;
}

.thank-you img {
	max-width: 90%;
	margin: 0 auto 25px;
}

.post-card-header-budget {
	position: relative;
	padding-left: 20px;
	margin-left: 20px;
	font-weight: 700;
	line-height: 1.1;
	padding: 8px 0;
	padding-left: 8px;
	font-size: 2em;
}

.post-card-header-budget:before {
	background-color: #f8f8f8;
	position: absolute;
	bottom: 0;
	margin: auto;
	content: "";
	width: 1px;
	top: 0;
	left: 0;
}

.post-card-header-budget span {
	margin-top: 0;
	font-size: .5em;
}

.post-card-header-btn .btn {
	padding-right: 30px;
	padding-left: 30px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: #000;
	height: 48px;
	line-height: 1.6;
}

.btn-gray {
	background-color: #d3d3d3;
}

.btn-gray:hover {
	background-color: #4A55BE;
	color: #fff;
}

.post-card-body {
	margin-top: 20px;
	padding-top: 35px;
	position: relative;
}

.post-card-body+.post-card-body {
	margin-top: 35px;
}

.post-card-body:before {
	background-color: #eee;
	height: 1px;
	position: absolute;
	left: -50px;
	right: -50px;
	top: 0;
	content: "";
}

.info-card-list {
	box-shadow: 0 0 35px rgba(0,0,0,.2);
	background-color: #fff;
	padding-bottom: 30px;
	border-radius: 8px;
	min-height: auto;
	min-width: auto;
	width: 100%;
}

.info-card-list .event-tags {
	height: auto;
}

.info-card-name {
	min-width: 200px;
}

.info-card-name p i {
	vertical-align: -1px;
}

.info-card-list .ev-tag {
	font-size: 13px;
}

.info-card-list .event-tags {
	/* max-width: 50%; */
	flex-grow: 1;
	padding: 0 10px;
}

.info-card-controls .btn-link {
	text-decoration: none!important;
}

.info-card-controls {
	min-width: 220px;
	max-width: 220px;
}

.info-card-list+.info-card-list {
	margin-top: 25px;
}

.dropdown-item.active,.dropdown-item:active {
	color: #fff!important;
}

.post-ajob .tab-content {
	padding: 0 25px;
	border-radius: 15px;
	background-color: #fff;
}

.need-help-modal .modal-title {
	font-size: 22px;
}

.need-help-modal .modal-body p {
	font-size: 15px;
}

.modal{
	z-index: 999;
}
.modal-backdrop{
	z-index: 998;
}

.modal-body .input-group.flex-nowrap [data-standalone-searchbox]{
	width: 100%;
}

.modal-header .close {
	opacity: 1!important;
}

.selected-circle {
	width: 100px;
	height: 100px;
	padding: 10px;
	font-size: 16px;
	color: #fff;
	font-weight: 700;
	margin-right: 20px;
	text-align: center;
	margin-bottom: 15px;
	background-color: #4A55BE;
}

.selected-circle-me {
	background-color: #b4b4b4;
}

li[data-country-code=ar]~li[data-country-code=ar],li[data-country-code=au]~li[data-country-code=au],li[data-country-code=ca]~li[data-country-code=ca],li[data-country-code=do]~li[data-country-code=do],li[data-country-code=fr]~li[data-country-code=fr],li[data-country-code=kz]~li[data-country-code=kz],li[data-country-code=mx]~li[data-country-code=mx],li[data-country-code=pr]~li[data-country-code=pr],li[data-country-code=us]~li[data-country-code=us] {
	display: none!important;
}

.inner-banner h1 {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 700;
}

.inner-banner p {
	margin-bottom: 0;
	color: #fff;
}

.content-section h2 {
	font-size: 38px;
	font-weight: 700;
	margin-bottom: .5em;
}

.content-section h2 span {
	color: #4A55BE;
}

.content-section p {
	font-size: 17px;
	line-height: 1.5;
	margin-bottom: .9em;
}

.page-inner-content {
	padding-top: 40px;
	/* padding-top: 80px; */
	padding-bottom: 80px;
}

.contact-info i {
	background-color: #4A55BE;
	width: 65px;
	height: 65px;
	color: #fff;
	font-size: 25px;
	border-radius: 65px;
	text-align: center;
	line-height: 65px;
	margin-bottom: 25px;
}

.contact-form {
	padding: 4%;
	width: 100%;
	margin: auto;
	max-width: 960px;
	border-radius: 25px;
	-webkit-box-shadow: 5px 10px 40px rgba(99,99,99,.01);
	box-shadow: 5px 10px 40px rgba(99,99,99,.01);
}

.accord-control {
	width: 25px;
	min-width: 25px;
	height: 25px;
	position: relative;
}

.accord-control i {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 15px;
	height: 18px;
	margin: auto;
}

.accordion>.card>.card-header {
	-webkit-transition: all .35s linear;
	-o-transition: all .35s linear;
	transition: all .35s linear;
	line-height: 1.2;
	padding: 20px 8px;
	font-weight: 700;
	font-size: 18px;
	cursor: pointer;
	border: none;
}

.accordion>.card>.card-header.down {
	color: #4A55BE;
}

.accordion>.card>.card-header .fa-minus,.accordion>.card>.card-header .fa-plus {
	opacity: 0;
}

.accordion>.card>.card-header.down .fa-minus,.accordion>.card>.card-header.up .fa-plus {
	opacity: 1;
}

.accordion>.card {
	border-width: 0 0 1px 0!important;
	border-color: #4A55BE!important;
	border-style: dashed!important;
	border-radius: 0;
}

.accordion>.card>.card-header i.fa-question-circle {
	margin-right: 12px;
	position: relative;
	top: -1px;
}

.accordion .card-body {
	font-size: 18px;
	padding-top: 15px;
	padding-left: 38px;
	font-weight: 400;
	padding-bottom: 30px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.post-box {
	-webkit-box-shadow: 0 10px 80px rgba(62,62,62,.15);
	box-shadow: 0 10px 80px rgba(62,62,62,.15);
	background-color: #fff;
	margin-bottom: 20px;
	border-radius: 9px;
}

.post-image {
	background-position: center center;
	-webkit-background-size: cover;
	border-radius: 9px 9px 0 0;
	background-size: cover;
	position: relative;
	height: 250px;
}

.post-meta {
	background-color: #4A55BE;
	border-radius: 0 9px 0 0;
	padding: 10px 15px;
	text-align: center;
	position: absolute;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	color: #fff;
	width: 68px;
	right: 0;
	top: 0;
}

.post-meta strong {
	display: block;
	font-weight: 500;
	text-indent: -5px;
}

.post-meta strong::first-line {
	font-size: 30px;
	font-weight: 700;
}

.post-info {
	padding: 25px 20px 30px;
}

.post-info h3 {
	min-height: 80px;
	margin-bottom: 15px;
}

.post-tag {
	font-size: 14px;
	color: #4A55BE;
	margin-bottom: 10px;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
}

.hideChat-icon .event-card-chat {
	display: none;
}

.post-info p {
	min-height: 150px;
	overflow: hidden;
	font-size: 17px;
}

.post-info .btn-link {
	font-size: 18px;
	font-weight: 700;
	font-family: "Red Hat Display", sans-serif;
	/* letter-spacing: -0.01em; */
}

.post-info .btn-link i {
	position: relative;
	top: 2px;
}

.post-info .btn-link:hover {
	color: #000!important;
}

.single-post img {
	max-width: 100%;
	display: block;
	margin: 40px 0;
}

blockquote {
	padding: 3%;
	margin: 30px 0;
	font-size: 18px;
	border-radius: 5px;
	background-color: #f9f9fa;
	border-left: 5px solid #4A55BE;
}

.search-item {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	padding: 30px;
}

.search-heading .ur_short_name {
	background-color: #4A55BE;
	color: #fff;
}

.search-heading p {
	font-size: 13px!important;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
}

.search-item p {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 10px;
}

.search-results {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	color: #707070;
	font-size: 18px;
	font-weight: 700;
}

.header-controls .dropdown-menu {
	border: 0;
	padding: 0;
	min-width: 240px;
	border-radius: 8px;
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
}

.dropdown-info {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 12px;
	padding: 15px;
}

.dropdown-info .ur_short_name {
	width: 32px;
	height: 32px;
	font-size: 14px;
	color: #fff!important;
	min-width: 32px !important;
	line-height: 34px;
}

.dropdown-info h3 {
	margin-bottom: 1px;
	font-weight: 600;
	font-size: 16px;
}

.header-controls .dropdown-menu .dropdown-item {
	border-top: 1px solid #e2e4e6;
	padding: 10px 15px;
	font-weight: 500;
	font-size: 14px;
}

.post-card-chat {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
}

.post-card-chat a {
	display: block;
}

.post-card-chat strong {
	display: block;
	margin-top: 4px;
	color: #4A55BE;
}

.freelancer-info p {
	font-size: 16px;
	max-width: 100%;
}

.post-card.no-bg {
	background-image: none;
}

.post-card .list-group {
	border: none;
	padding: 5px 0;
	border-radius: inherit;
}

.post-card .list-group li {
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border-color: #eee;
	padding: 15px 22px;
	font-weight: 600;
	font-size: 16px;
	border-width: 0;
}

.post-card .list-group li+li {
	border-top-width: 1px;
}

.post-card .list-group li span {
	text-align: right;
}

.react-stars {
	outline: 0!important;
}

.selected-flag:focus {
	outline: 1px solid #000;
}

.confirm-modal h2 {
	font-size: 22px;
}

.confirm-modal p {
	font-size: 14px;
}

.jobs-dropdown .dropdown-menu {
	box-shadow: 0 3px 60px rgba(0,0,0,.16);
	border-radius: 8px;
	border: none;
}

.jobs-dropdown .dropdown-item:hover {
	background-color: #eee;
}

.filter-inner .invalid-feedback {
	top: 7px;
	left: 15px;
	right: auto;
	width: auto;
	font-size: 11px;
	position: absolute;
}

.modal-body .input-group .css-2b097c-container {
	flex-grow: 1;
	padding: 6px 0!important;
}

.modal-body .input-group .css-1wa3eu0-placeholder {
	font-size: 15px;
	font-weight: 500;
}

.social-icons a:hover {
	color: #4A55BE!important;
}

.social-icons {
	font-size: 18px;
}

.social-icons a {
	color: #fff;
}

.social-icons a+a {
	margin-left: 12px;
}

.page_loader {
	/* top: 50%; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	/* transform: translateY(-50%) scale(1); */
	/* -webkit-transition: all .35s ease;
	-o-transition: all .35s ease;
	transition: all .35s ease; */
	background: #425876;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	background: -moz-radial-gradient(circle,rgba(66,88,118,.3) 0,rgba(31,44,66,.3) 100%);
	background: -webkit-radial-gradient(circle,rgba(66,88,118,.3) 0,rgba(31,44,66,.3) 100%);
	background: radial-gradient(circle,rgba(66,88,118,.3) 0,rgba(31,44,66,.3) 100%);
	box-shadow: 0 0 50px rgba(255,255,255,.8);
}

.page_loader.loader-off {
	height: 0;
	opacity: 0;
	z-index: -55;
	visibility: hidden;
	/* transform: translateY(-50%); */
}

.page_loader img {
	/* max-width: 120px; */
	max-width: 275px;
	width: 75%;
}

.page_loader.loader-off img {
	opacity: 0;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

.hero-mask img {
	object-fit: cover;
	object-position: center;
}

.custom-control-outer {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 3em;
	-moz-column-gap: 3em;
	column-gap: 3em;
	/* display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between; */
}
/* .custom-control-outer  > div{
	max-width: 48%;
	width: 100%;
} */

.css-1hwfws3 {
	padding: 0!important;
}

.css-1wa3eu0-placeholder {
	margin-left: 0!important;
}

.dropdown-menu-right {
	top: 100%!important;
	right: 0!important;
	left: auto!important;
	transform: none!important;
}

.drp-selected {
	display: none!important;
}

.single-post iframe {
	width: 100%!important;
	height: 550px!important;
	margin: 20px 0;
}

.banner-image {
	object-position: center;
	border-radius: inherit;
	position: absolute;
	object-fit: cover;
	height: 100%;
	z-index: -2;
	width: 100%;
	left: 0;
	top: 0;
}

.sitemap {
	min-height: 100vh;
	padding: 50px 0 45px;
	background-color: #f9f9f9;
}

.sitemap h1 {
	font-weight: 700;
	font-size: 4em;
	margin-bottom: .5em;
}

.sitemap li {
	margin-bottom: 12px;
}

.sitemap li a {
	font-size: 20px;
	font-weight: 600;
}

.sitemap li ul {
	margin: 15px 0px 20px 15px;
}

.member-card-gray{
	background-color: #e6e6e6;
	display: none;
}

.filter-area-supplier{
	margin: 1px 0 50px;
}

.supplier-card img, .gallery-list img{
	border: 1px solid #979797;
	background-color: #fff;
	object-position: center;
	margin-bottom: 18px;
	object-fit: cover;
	border-radius: 8px;
	height: 260px;
}
.supplier-card h4{
	margin-top: 4px;
	font-weight: 700;
	margin-bottom: 12px;
}
.supplier-card p{
	font-size: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}

.supplier-card p span{
	font-weight: 300 !important;
}

.supplier-card div > *{
	display: none;
}
.supplier-card div > p:first-child{
	display: -webkit-box;
}

.share-icons{
	margin-bottom: 1.3em;
	font-size: 18px;
}
.share-icons > span{
	margin-left: 5px !important;
}
.share-icons > *{
	margin-right: 8px;
}
.share-icons a img{
	height: 42px;
}

.share-icons + .btn{
	margin-bottom: 1.3em;
}
.btn-add-fav{
	color: #4A55BE;
}
.btn-add-fav i{
	font-size: 1.4em;
	color: #d8d8d8;
	margin-right: 10px;
}
.btn-add-fav i.active{
	color: #4A55BE;
}
.supplier-content p{
	font-size: 15px;
}
.supplier-content p a{
	color: #4A55BE;
}
.supplier-form{
	padding: 25px;
	border-radius: 5px;
	background-color: #fff;
	border: 1px solid #979797;
}
.supplier-form p{
	font-size: 15px;
	margin-bottom: 10px;
}

.react-share__ShareButton circle{
	fill: #4A55BE !important;
}
.react-share__ShareButton:hover circle{
	fill: #4A55BE !important;
}
.disabled{
	pointer-events: none;
}


/* fav-box */
.fav-box{
	padding: 12px;
}
.fav-box i{
	font-size: 22px;
}
.fav-box .fav-name{
	padding: 0px 10px;
}
.fav-heart i{
	color: #d8d8d8;
}
.fav-heart i.active{
	color: #4A55BE;
}
.fav-heart{
	cursor: pointer;
}
.event-card-heart{
	display: block;
	color: #4A55BE;
	margin-bottom: 12px;
}
.card-btn{
	cursor: pointer !important;
}

.sp-gal{
	width: 100%;
	padding-bottom: 52%;
	position: relative;
}
.sp-gal img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	object-position: center;
}

/* availability-dot */
.availability-dot{
	top: -1px;
	width: 15px;
	height: 15px;
	font-size: 16px;
	cursor: pointer;
	margin-right: 10px;
	position: relative;
	font-family: "Red Hat Display", sans-serif;
	letter-spacing: -0.01em;
	border: 1px solid #4A55BE;
}
.availability-dot.active{
	background-color: #4A55BE;
}
.availability-day{
	color: #4A55BE;
	font-weight: 600;
	min-width: 50px;
}
.availability-middle span{
	font-weight: 600;
	color: #848484;
}
.availability-middle{
	min-width: 265px;
}
.availability-btn{
	color: #707070;
}
.availability-middle input{
	width: 75px;
	font-size: 15px;
	color: #4A55BE;
	text-align: center;
	font-weight: normal;
	border: 2px solid #4A55BE;
	background-color: transparent;
}

.gallery-list .owl-dots{
	text-align: center;
}
.gallery-list .owl-dots span{
	width: 12px;
	height: 12px;
	opacity: 0.5;
	margin: 0 5px;
	display: block;
	border-radius: 12px;
	background-color: #4A55BE;
}
.gallery-list .owl-dots .active span{
	opacity: 1;
}

.page-data .page-dashboard{
	margin: 0;
	padding: 15vh 15px 0px;
}
.page-data .header-access .navbar-nav a{
	color: #4A55BE;
}

/* header + div + main, .body-search + div + main, .header-dashboard + div + main{
	display: none !important;
} */

.post-card-header-info .flex-wrap{
	max-width: 580px;
	width: 100%;
}

.post-card-header-info p:last-child{
	margin-bottom: 0;
}

.post-card-header-info .ur_short_name{
	color: #fff;
}

.info-content-text h2, .info-content-text h3, .info-content-text h4, .info-content-text h5, .info-content-text h6{
	padding-top: 0.6526em;
	margin-bottom: 0.5em;
}

.spin {
	height: 0;
	width: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 12px;
	z-index: 1;
	position: absolute;
	padding: 8px;
	border: 3px solid #ccc;
	border-right-color: #4A55BE;
	border-radius: 22px;
	-webkit-animation: rotate 1s infinite linear;
	animation: rotate 1s infinite linear;
	position: absolute;
  }
  
@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@-webkit-keyframes rotate {
	100% {
	-webkit-transform: rotate(360deg);
	}
}


.fa-spinner{
	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.form-control-select-disabled{
	background: none !important;
	cursor: auto !important;
}

.not-found{
	display: none;
	padding: 10% 0;
}

.not-found .blank-area.text-center{
	text-align: center !important;
}

.header + .not-found,
.body-search + .not-found{
	display: block;
}
.body-search + .not-found{
	padding-left: 240px;
}

.view-org .not-found.landing, .view-free .not-found.landing, .view-supplier .not-found.landing, .view-landing .not-found.dashboard{
	display: none !important;
}


.post-box.post-box-webinar{
	border-radius: 80px 16px 16px;
	box-shadow: 0 0 30px rgba(0,0,0,.16);
}
.post-box-webinar .post-image{
	border-radius: 80px 16px 16px;
    padding-bottom: 100.105%;
	min-height: 250px;
    height: auto;
}
.post-box.post-box-webinar{
	padding: 15px 15px 10px;
}
.post-box.post-box-webinar .post-info{
	padding-top: 20px;
}
.webinar-status{
	margin: 20px 0;
	padding: 15px 0;
	font-weight: 500;
	border-top: 1px solid rgba(0,0,0,.1);
	border-bottom: 1px solid rgba(0,0,0,.1);
}
.post-box-webinar .post-info h3 span{
	font-size: 1em;
}
a.post-box-webinar:hover, a.post-box-webinar:hover .btn{
	text-decoration: none;
}
a.post-box-webinar .btn{
	font-weight: 600;
	padding: 11px 16px;
	border-radius: 25px;
}
.play-btn{
	width: 27px;
	height: 27px;
	/* background: #fff; */
	border-radius: 50%;
	position: relative;
	display: block;
	margin-right: 15px;
	transition: all .3s ease 0s;
}
.play-btn:before{
	content: "";
	position: absolute;
	width: calc(100% + 16px);
	height: calc(100% + 16px);
	animation: 1.5s linear 0s normal none infinite focuse;
	opacity: 1;
	border-radius: 50%;
	border: 1px solid #fff;
	top: -8px;
	left: -8px;
}
/* .play-btn:after{
	content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    z-index: 100;
    right: 0;
    bottom: 0;
    text-align: center;
    transition: border .3s ease 0s;
    border-left: 10px solid #4A55BE;
    border-bottom: 6px solid transparent;
    border-width: 10px 8px 7px 8px;
    border-top: 5px solid transparent;
    margin: 8px auto auto 11px;
} */
@keyframes focuse {
    0% {
        transform: scale(.6);
        opacity:1
    }

    75% {
        transform: scale(1.1);
        opacity:0
    }

    100% {
        transform: scale(1.1);
        opacity:0
    }
}

.gallery-list .owl-carousel .owl-stage{
	display: flex;
}

.contact-form .form-field select.form-control{
	border: none !important;
}

.btn-block{
	min-width: auto !important;
}





.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
	background-color: #4A55BE !important;
	border-color: #4A55BE !important;
}

.dashboard-hscroll-single .event-card{
	max-width: 396px;
	width: 100%;
}

.text-success + .invalid-feedback{
	display: none !important;
}

a.text-primary:focus, a.text-primary:hover {
    color: #4A55BE !important;
}